





































import Vue from 'vue';
import Spinner from '@/components/Spinner.vue';
import { Option } from '@/common/Option';
import { TagKind } from '@/domain/product/model/product/enum';
import { Tag } from '@/domain/product/model/product/response/tag';
import { PRODUCT_TAGS } from '@/domain/product/queries/tag/query';
import { CommonState } from '@frientrip/domain';
import { DISPLAY_CATEGORY_LEAVES_QUERY } from '@/domain/category/display/queries/query';
import { DisplayCategory, StandardCategory } from '@/common/model/Category';
import { STANDARD_CATEGORY } from '@/components/Category/queries/query';

const noBenepiaCategoryLabels = [
  '스토어',
  '해외여행',
  '뷰티',
  '밀키트',
  '베이킹키트',
  '프립커머스',
  '피트니스',
  '모임',
];

export default Vue.extend({
  name: 'ProductOtherInfoForm',
  components: {
    Spinner,
  },
  props: {
    product: {
      type: Object,
    },
    isUpdateForm: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: this.product,
      normalTagLoading: 0,
      priceTagLoading: 0,
      benepiaId: 'benepia',
      // 베네피아 상품 상태를 표시하는 data/v-model
      isBenepiaStatus: this.product.externalProvisionIds.includes('benepia')
        ? true
        : false,
      isBenepiaOnSaleOptions: [
        {
          text: '예',
          value: true,
        },
        {
          text: '아니오',
          value: false,
        },
      ],
      normalTagOptions: [],
      priceTagOptions: [],
      noBenepiaDisplayCategory: [] as DisplayCategory[],
      standardCategory: {
        id: '',
        label: {
          name: '',
        },
      } as StandardCategory,
    };
  },
  computed: {
    isAvailableCategoryForBenepia(): boolean {
      const displayCategories = this.form.categories.map(
        (x: { displayCategoryId: string }) => x.displayCategoryId
      );
      // 전시 카테고리 여부 확인
      const isBenepiaAvailableDisplayCategory: boolean =
        this.noBenepiaDisplayCategory.reduce(
          (result: boolean, category) =>
            result && !displayCategories.includes(category.id),
          true
        );
      // 표준 카테고리 여부 확인
      const standardCategoryLabelsFromPath = [];
      // 카테고리 경로에 포함된 라벨 리스트 산출
      let standardCategoryToRecur: StandardCategory | undefined =
        this.standardCategory;
      while (standardCategoryToRecur) {
        standardCategoryLabelsFromPath.push(standardCategoryToRecur.label.name);
        standardCategoryToRecur = standardCategoryToRecur.parent;
      }
      const isBenepiaAvailableStandardCategory =
        standardCategoryLabelsFromPath.reduce(
          (result: boolean, categoryLabels) => {
            return result && !noBenepiaCategoryLabels.includes(categoryLabels);
          },
          true
        );
      // 클래스를 포함하는 지를 확인.
      const selectAttributeIds = this.form.attributeIds;
      const isBenepiaAvailableAttributeIds =
        selectAttributeIds.length === 1 && selectAttributeIds.includes('2');
      if (
        isBenepiaAvailableDisplayCategory &&
        isBenepiaAvailableStandardCategory &&
        isBenepiaAvailableAttributeIds // 클래스 조건을 추가함.
      ) {
        // 아무것도 안해도 됨
        return true;
      } else {
        // false로 변경해줘야 함
        if (this.$data.isBenepiaStatus) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.$data.isBenepiaStatus = false;
          const index = this.form.externalProvisionIds.findIndex(
            (id: string) => id === this.benepiaId
          );
          if (index >= 0) {
            // 값이 존재하는 경우 id를 제거한다.
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.form.externalProvisionIds.splice(index, 1);
          }
        }
        return false;
      }
    },
    normalTagIds: {
      get(): string[] {
        return this.form.tagIds.filter((tagId: string) =>
          this.normalTagOptions
            .map((option: Option) => option.value)
            .includes(tagId)
        );
      },
      set(newValue: string[]) {
        newValue.forEach(tag => {
          if (!this.form.tagIds.includes(tag)) {
            this.form.tagIds.push(tag);
          }
        });
        this.normalTagOptions.forEach((option: Option) => {
          if (!newValue.includes(option.value)) {
            const index = this.form.tagIds.findIndex(
              (id: string) => id === option.value
            );
            if (index >= 0) {
              this.form.tagIds.splice(index, 1);
            }
          }
        });
      },
    },
    priceTagIds: {
      get(): string[] {
        return this.form.tagIds.filter((tagId: string) =>
          this.priceTagOptions
            .map((option: Option) => option.value)
            .includes(tagId)
        );
      },
      set(newValue: string[]) {
        newValue.forEach(tag => {
          if (!this.form.tagIds.includes(tag)) {
            this.form.tagIds.push(tag);
          }
        });
        this.priceTagOptions.forEach((option: Option) => {
          if (!newValue.includes(option.value)) {
            const index = this.form.tagIds.findIndex(
              (id: string) => id === option.value
            );
            if (index >= 0) {
              this.form.tagIds.splice(index, 1);
            }
          }
        });
      },
    },
  },
  methods: {
    // benepia상태가 변경되었을때 호출되는 메서드.
    changeExternalProvisions() {
      // 변경이벤트가 발생했다면
      // v-model(isBenepiaStatus)의 값은 이미 변경된 값을 갖는다.
      // ui에서 변경이 정산 반영되는 것을 확인했어.
      // 이제 'benepia'의 index의 값을 가져와보자.
      const index = this.form.externalProvisionIds.findIndex(
        (id: string) => id === this.benepiaId
      );

      if (this.$data.isBenepiaStatus) {
        if (index < 0) {
          // 값이 존재하지 않는 경우 id를 추가한다.
          this.form.externalProvisionIds.push(this.benepiaId);
        }
      } else {
        if (index >= 0) {
          // 값이 존재하는 경우 id를 제거한다.
          this.form.externalProvisionIds.splice(index, 1);
        }
      }
    },
  },
  apollo: {
    normalTagOptions: {
      query: PRODUCT_TAGS,
      variables() {
        return {
          kind: TagKind.NORMAL,
        };
      },
      update: data =>
        data.product.tags.map((tag: Tag) => {
          return {
            text:
              tag.status === CommonState.INACTIVE
                ? `${tag.name}(비활성화)`
                : tag.name,
            value: tag.id,
          };
        }),
      loadingKey: 'normalTagLoading',
    },
    noBenepiaDisplayCategory: {
      query: DISPLAY_CATEGORY_LEAVES_QUERY,
      variables() {
        return {
          size: 100,
          filter: {
            likeLabelNameIncludePathList: noBenepiaCategoryLabels,
          },
        };
      },
      update: data =>
        data.category.displayLeaves.edges.map((edge: any) => {
          return edge.node as DisplayCategory;
        }),
    },
    standardCategory: {
      query: STANDARD_CATEGORY,
      variables(): { id: string } {
        return {
          id: this.product.standardCategoryId,
        };
      },
      update: data => {
        return data.category.standard;
      },
    },
    priceTagOptions: {
      query: PRODUCT_TAGS,
      variables() {
        return {
          kind: TagKind.PRICE,
        };
      },
      update: data =>
        data.product.tags.map((tag: Tag) => {
          return {
            text:
              tag.status === CommonState.INACTIVE
                ? `${tag.name}(비활성화)`
                : tag.name,
            value: tag.id,
          };
        }),
      loadingKey: 'priceTagLoading',
    },
  },
});
