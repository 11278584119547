import { DAY_MILLISECOND, HOUR_MILLISECOND } from './getConstants';

interface DayAndHour {
  day: number;
  hour: number;
}

export const getDayAndHourByDuration = (duration: number): DayAndHour => {
  const day = Math.floor(duration / DAY_MILLISECOND) || 0;
  const hour = (duration - day * DAY_MILLISECOND) / HOUR_MILLISECOND || 0;
  return {
    day: day,
    hour: hour,
  };
};
