







































import Vue from 'vue';
import {
  CurriculumHourMinuteInput,
  CurriculumTimeTermInput,
} from '../../model/product/param/productAdditionalInfoParam';

export default Vue.extend({
  name: 'CurriculumTimeTermInput',
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      term: {
        startingTime: {
          hour:
            this.value.startingTime.hour !== 0
              ? this.value.startingTime.hour
              : 0,
          minute:
            this.value.startingTime.minute !== 0
              ? this.value.startingTime.minute
              : 0,
        },
        endingTime: {
          hour:
            this.value.endingTime.hour !== 0 ? this.value.endingTime.hour : 0,
          minute:
            this.value.endingTime.minute !== 0
              ? this.value.endingTime.minute
              : 0,
        },
      },
      isValid: true,
    };
  },
  methods: {
    formatTime(value: number) {
      return value === 0 ? '00' : value.toString().padStart(2, '0');
    },
    updateTime(field: string, value: string): void {
      const keys = field.split('.');
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let target: any = this;

      // 필드 경로를 따라가서 해당 값을 설정
      for (let i = 0; i < keys.length - 1; i++) {
        target = target[keys[i]];
      }

      // 입력값을 숫자로 변환해 설정, 비어있으면 0
      target[keys[keys.length - 1]] = parseInt(value) || 0;
    },
    validateInput() {
      let valid = true;
      valid =
        this.validateTime(this.term.startingTime) &&
        this.validateTime(this.term.endingTime) &&
        this.validateTerm(this.term);
      return valid;
    },
    validateTime(time: CurriculumHourMinuteInput) {
      if (time.hour >= 24 || time.hour < 0) {
        return false;
      }
      if (time.minute >= 60 || time.minute < 0) {
        return false;
      }
      return true;
    },
    validateTerm(term: CurriculumTimeTermInput) {
      const startedAt = term.startingTime.hour * 60 + term.startingTime.minute;
      const endedAt = term.endingTime.hour * 60 + term.endingTime.minute;
      return startedAt < endedAt;
    },
    changeTerm() {
      this.isValid = this.validateInput();

      if (this.isValid) {
        this.$emit('input', this.term);
      }
    },
  },
});
