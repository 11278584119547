






















































import Vue from 'vue';
import RangeInput from '@/components/Forms/RangeInput.vue';
import { ItemState, QuotaState } from '../../model/product/enum';
import { isHostAdmin } from '@/env';

export default Vue.extend({
  components: {
    RangeInput,
  },
  props: {
    quota: {
      type: Number,
      default: 0,
    },
    minimumQuota: {
      type: Number,
      default: 0,
    },
    state: {
      type: String,
      required: true,
    },
    itemStatus: {
      type: String,
    },
    attachedToSchedule: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        quota: this.quota,
        minimumQuota: this.minimumQuota,
      },
      isAdmin: !isHostAdmin(),
      maximumOfQuota: 99999,
      isRegularSale: this.quota === 0,
      recruitLimitOption: false,
      recruitmentLimitOptions: [
        {
          text: '제한',
          value: true,
        },
        {
          text: '미제한',
          value: false,
        },
      ],
    };
  },
  computed: {
    label(): string {
      let label = '';
      switch (this.state) {
        case QuotaState.SHIPPING:
          label = '재고수량';
          break;
        case QuotaState.RECRUITMENT_BY_ITEM:
          label = '모집인원 제한';
          break;
        case QuotaState.OFFLINE:
          label = '예약 설정';
          break;
        case QuotaState.ONLINE:
          label = '한정 판매';
          break;
      }
      return label;
    },
    contentColsByInput(): string {
      if (this.state === QuotaState.RECRUITMENT_BY_ITEM) {
        return '12';
      } else {
        return '6';
      }
    },
    onlyQuotaLimit: {
      get(): boolean {
        return this.form.minimumQuota === 0;
      },
      set(newValue: boolean) {
        if (newValue) {
          if (this.form.minimumQuota > 0) {
            const messageHtml =
              '<div text-align="center"><span>일정에 포함된 이 옵션을 결제한 사람이 없어도,<br />최소인원 미달로 취소되지 않습니다.<br />필요에 따라, 일정에 포함된 다른 옵션에도 <br />동일한 설정을 해 주시기 바랍니다.</span></div>';
            this.$modal.show({
              html: messageHtml,
              type: 'danger',
            });
          }
          this.form.minimumQuota = 0;
        }
      },
    },
    canEdit(): boolean {
      return !this.itemStatus || this.itemStatus === ItemState.EDITING;
    },
  },
  watch: {
    isRegularSale(newValue: boolean) {
      if (newValue) {
        this.form.quota = 0;
        this.form.minimumQuota = 0;
        this.$emit('update:quota', this.form.quota);
      } else {
        if (this.state === QuotaState.RECRUITMENT_BY_ITEM) {
          this.form.minimumQuota = 1;
          this.form.quota = 10;
        } else {
          this.form.quota = 1;
        }
      }
    },
  },
  methods: {
    onChange() {
      this.$emit('update:quota', this.form.quota);
      this.$emit('update:minimumQuota', this.form.minimumQuota);
    },
  },
});
