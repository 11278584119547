import { CommonState } from '@frientrip/domain';

export interface Tag {
  id: string;
  name: string;
  backgroundColor: string;
  textColor: string;
  status: CommonState;
}

export enum TagKind {
  NORMAL = 'NORMAL', // 일반 태그
  PRICE = 'PRICE', // 특가 태그
}
