import gql from 'graphql-tag';

// product 최초 생성
export const CREATE_PRODUCT = gql`
  mutation createProduct($hostId: String!, $param: ProductParamInput!) {
    createProduct(hostId: $hostId, param: $param) {
      id
      title
      status
    }
  }
`;

// product 수정
export const SAVE_PRODUCT = gql`
  mutation saveProduct($id: ID!, $param: ProductParamInput!) {
    saveProduct(id: $id, param: $param) {
      id
      title
      status
    }
  }
`;

// product 삭제
export const DELETE_PRODUCT = gql`
  mutation deleteProduct($id: ID!) {
    deleteProduct(id: $id) {
      success
      message
    }
  }
`;

// 환불 정책 저장
export const SAVE_PRODUCT_REFUND_POLICY = gql`
  mutation saveProductRefundPolicy(
    $id: ID!
    $param: ProductRefundPolicyParamInput!
  ) {
    saveProductRefundPolicy(id: $id, param: $param) {
      refundPolicy {
        id
        name
        extraNotice
      }
      message
    }
  }
`;

// 검수 요청
export const REQUEST_INSPECTION = gql`
  mutation requestInspection($productId: ID!) {
    requestInspection(productId: $productId) {
      id
      completedAt
    }
  }
`;

// 검수 취소
export const CANCEL_INSPECTION = gql`
  mutation cancelInspection($productId: ID!) {
    cancelInspection(productId: $productId) {
      id
      completedAt
    }
  }
`;

// 상품 오픈
export const OPEN_PRODUCT = gql`
  mutation openProduct($id: ID!) {
    openProduct(id: $id) {
      id
      status
    }
  }
`;

// 상품 판매 일시 중지
export const PAUSE_PRODUCT = gql`
  mutation pauseProduct($id: ID!) {
    pauseProduct(id: $id) {
      id
      status
    }
  }
`;

// 상품 판매 종료
export const CLOSE_PRODUCT = gql`
  mutation closeProduct($id: ID!) {
    closeProduct(id: $id) {
      id
      status
    }
  }
`;

// 상품 재오픈
export const REEDIT_PRODUCT = gql`
  mutation reeditProduct($id: ID!) {
    reeditProduct(id: $id) {
      id
      status
    }
  }
`;

// 상품 판매중지
export const SUSPEND_PRODUCT = gql`
  mutation suspendProduct($id: ID!) {
    suspendProduct(id: $id) {
      id
      status
    }
  }
`;

// 상품 판매재개
export const RESUME_PRODUCT = gql`
  mutation resumeProduct($id: ID!) {
    resumeProduct(id: $id) {
      id
      status
    }
  }
`;

// 상품 운영 종료
export const TERMINATE_PRODUCT = gql`
  mutation terminateProduct($id: ID!) {
    terminateProduct(id: $id) {
      id
      status
    }
  }
`;
