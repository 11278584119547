









import Vue from 'vue';
import ProductSearchFilterForm from '../../components/ProductSearchFilterForm.vue';
import ProductSearchList from '../../components/ProductSearchList.vue';

export default Vue.extend({
  components: {
    ProductSearchFilterForm,
    ProductSearchList,
  },
  methods: {
    onRefresh() {
      (this.$refs.list as any).refresh();
    },
  },
});
