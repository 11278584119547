import gql from 'graphql-tag';

export const PRODUCT_TAGS = gql`
  query tags($kind: TagKind!) {
    product {
      tags(kind: $kind) {
        id
        name
        backgroundColor
        textColor
        status
      }
    }
  }
`;
