import { ProductState } from '../model/product/response/product';

export const getProductStatusLabel = (status: ProductState): string => {
  switch (status) {
    case ProductState.SALE:
      return '판매중';
    case ProductState.EDITING:
      return '등록중';
    case ProductState.INSPECTING:
      return '검수중';
    case ProductState.APPLIED:
      return '검수신청';
    case ProductState.REJECTED:
      return '검수반려';
    case ProductState.READY:
      return '판매대기';
    case ProductState.SOLD_OUT:
      return '품절';
    case ProductState.PAUSED:
      return '일시중지';
    case ProductState.CLOSED:
      return '판매종료';
    case ProductState.TERMINATED:
      return '운영종료';
    case ProductState.SUSPENDED:
      return '판매중지';
    case ProductState.DELETED:
      return '삭제';
    default:
      return ''; // TODO: 네이밍 변경 필요;
  }
};
