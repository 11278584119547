























import Vue from 'vue';
import { ScheduleState } from '../../model/product/enum';

export default Vue.extend({
  name: 'ScheduleCard',
  props: {
    schedule: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    start(): string {
      return this.$moment(new Date(this.schedule.term.startedAt)).format(
        'HH:mm'
      );
    },
    recruitment(): string {
      if (this.schedule.minimumQuota === 0 && this.schedule.quota === 0) {
        // 옵션별 모집인원 제한일때
        return '';
      } else {
        return `${this.schedule.minimumQuota}명 ~ ${this.schedule.quota}명`; // 일정별 모집인원 제한일때
      }
    },
    statusLabel(): string {
      switch (this.schedule.status) {
        case ScheduleState.SOLD_OUT:
          return '품절';
        case ScheduleState.PAUSED:
          return '판매중지';
        case ScheduleState.CLOSED:
          return '신청마감';
        default:
          return '';
      }
    },
    themeColor(): string {
      switch (this.schedule.status) {
        case ScheduleState.EDITING:
        case ScheduleState.OPENED:
          return 'primary-50';
        case ScheduleState.CANCELED:
        case ScheduleState.FINISHED:
        case ScheduleState.CLOSED:
          return 'gray-100';
        case ScheduleState.SOLD_OUT:
          return 'bgray-700';
        case ScheduleState.PAUSED:
          return 'warning-100';
        default:
          return 'primary-50';
      }
    },
    textColor(): string {
      switch (this.schedule.status) {
        case ScheduleState.EDITING:
        case ScheduleState.OPENED:
          return 'primary';
        case ScheduleState.CANCELED:
        case ScheduleState.FINISHED:
        case ScheduleState.CLOSED:
          return 'gray-400';
        case ScheduleState.SOLD_OUT:
          return 'gray-600';
        case ScheduleState.PAUSED:
          return 'warning';
        default:
          return 'primary';
      }
    },
    border(): string {
      return this.schedule.edited ? `border border-frip-${this.textColor}` : '';
    },
    bgColor(): string {
      return `bg-frip-${this.themeColor}`;
    },
  },
  methods: {
    updateSchedule() {
      this.$emit('updateSchedule', this.schedule);
    },
  },
});
