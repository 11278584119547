import { MutationService } from '@/common/service/MutationService';

import {
  CANCEL_INSPECTION,
  CLOSE_PRODUCT,
  OPEN_PRODUCT,
  PAUSE_PRODUCT,
  REEDIT_PRODUCT,
  REQUEST_INSPECTION,
  RESUME_PRODUCT,
  SUSPEND_PRODUCT,
  TERMINATE_PRODUCT,
} from '../queries/product/mutation';

export class ProductStatusChangeService extends MutationService {
  /**
   * 등록중 -> 검수중
   * @param id
   * @returns id
   */
  public async requestInsepection(id: string): Promise<void> {
    const result = await this.apollo.mutate({
      mutation: REQUEST_INSPECTION,
      variables: {
        productId: id,
      },
    });
    console.log(result);
  }
  /**
   *  검수중 -> 검수중
   * @param id
   * @returns id
   */
  public async cancelInsepction(id: string): Promise<void> {
    const result = await this.apollo.mutate({
      mutation: CANCEL_INSPECTION,
      variables: {
        productId: id,
      },
    });
    console.log(result);
  }
  /**
   * 판매대기 -> 판매중
   * 일시중지 -> 판매중
   * @param id
   * @returns id
   */
  public async openProduct(id: string): Promise<string> {
    const result = await this.apollo.mutate({
      mutation: OPEN_PRODUCT,
      variables: {
        id: id,
      },
    });
    console.log(result);
    return result.data?.openProduct.id;
  }
  /**
   * 판매중 -> 일시중지
   * @param id
   * @returns id
   */
  public async pauseProduct(id: string): Promise<string> {
    const result = await this.apollo.mutate({
      mutation: PAUSE_PRODUCT,
      variables: {
        id: id,
      },
    });
    return result.data?.pauseProduct.id;
  }

  /**
   * 판매중 -> 판매중지
   * 일시중지 -> 판매중지
   * 매진 -> 판매중지
   * @param id
   * @returns id
   */
  public async suspendProduct(id: string): Promise<string> {
    const result = await this.apollo.mutate({
      mutation: SUSPEND_PRODUCT,
      variables: {
        id: id,
      },
    });
    return result.data?.suspendProduct.id;
  }

  /**
   * 판매중지 -> 판매중
   * @returns id
   */
  public async resumeProduct(id: string): Promise<string> {
    const result = await this.apollo.mutate({
      mutation: RESUME_PRODUCT,
      variables: {
        id: id,
      },
    });
    return result.data?.resumeProduct.id;
  }

  /**
   * 일시 중지 -> 판매 종료
   * @param id
   * @returns id
   */
  public async closeProduct(id: string): Promise<string> {
    const result = await this.apollo.mutate({
      mutation: CLOSE_PRODUCT,
      variables: {
        id: id,
      },
    });
    return result.data?.closeProduct.id;
  }
  /**
   * 판매 종료 -> 등록중
   * @param id
   * @returns id
   */
  public async reeditProduct(id: string): Promise<string> {
    const result = await this.apollo.mutate({
      mutation: REEDIT_PRODUCT,
      variables: {
        id: id,
      },
    });
    return result.data?.reeditProduct.id;
  }
  /**
   * 판매 종료 -> 운영 종료
   * @param id
   * @returns id
   */
  public async terminateProduct(id: string): Promise<string> {
    const result = await this.apollo.mutate({
      mutation: TERMINATE_PRODUCT,
      variables: {
        id: id,
      },
    });
    return result.data?.terminateProduct.id;
  }
}
