import gql from 'graphql-tag';

export const ITEM_FRAGMENT = gql`
  fragment itemField on Item {
    id
    options {
      id
      name
      title
    }
    saleTerm {
      startedAt
      endedAt
    }
    minimumQuota
    maximumPurchasableCount
    quota
    representative
    daysOfExpiration
    description
    name
    ticketCount
    scheduledBySale
    status
    salesType
    absentFee {
      bias
      charge
      type
    }
    commission {
      bias
      charge
      type
    }
    price {
      sale
      retail
    }
    digitalContent {
      id
      uri
    }
  }
`;
