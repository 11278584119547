




















































































































































































































import Vue from 'vue';
import ItemQuotaInput from './ItemQuotaInput.vue';
import PriceInput from '@/components/Forms/PriceInput.vue';
import PercentInput from '@/components/Forms/PercentInput.vue';
import { Select, Option } from 'element-ui';
import { ProductItemValidateService } from '../../service/ProductItemValidateService';
import {
  ItemState,
  QuotaState,
  SalesType,
  TicketType,
} from '../../model/product/enum';
import FormTitle from '@/components/Labels/FormTitle.vue';
import { deepCopy } from '@/common/util/deepCopy';
import {
  commissionTypeOptions,
  salesTypeOptions,
} from '../../constants/commisionOptions';
import { isHostAdmin } from '@/env';
import { CommissionType } from '@frientrip/domain';

const productItemValidateService = new ProductItemValidateService();

export default Vue.extend({
  name: 'OptionDetail',
  components: {
    PriceInput,
    PercentInput,
    ItemQuotaInput,
    FormTitle,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    item: {
      type: Object,
    },
    state: {
      type: String,
      required: true,
    },
    inventoryTargetType: {
      type: String,
    },
    havePlans: {
      type: Boolean,
      required: true,
    },
    bookingConfirmationEnabled: {
      type: Boolean,
      default: false,
    },
    isFrip: {
      type: Boolean,
    },
    isDigitalFrip: {
      type: Boolean,
    },
    isSale: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: deepCopy(this.item), // TODO: 수정
      isAdmin: !isHostAdmin(),
      ticketOption: this.item.ticketCount > 1,
      maxTicketCount: 29,
      termLimit: false,
      saleLimit: this.item.maximumPurchasableCount > 0,
      descriptions: [
        '옵션의 신청기간, 모집인원 설정 제한 및 다회권 여부를 설정하거나, 모든 옵션의 정보를 일괄 변경할 수 있습니다.',
        `옵션의 사용기간은 '옵션/일정 제한'의 일정유형='일정 미등록'일 때만 노출됩니다.`,
        `옵션의 사용기간은 '옵션/일정 제한'의 신청기간 제한='옵션별 신청기간 제한'일 때만 노출됩니다.`,
        `옵션의 모집인원 제한은 '옵션/일정 제한'의 모집인원 제한='옵션별 모집인원 제한'일 때만 노출됩니다.`,
      ],
      applyOptions: [
        {
          text: '모든 옵션에 적용하기',
          value: 'all',
        },
      ],
      ticketOptions: [
        {
          text: '1회권',
          value: TicketType.ONCE,
        },
        {
          text: '다회권',
          value: TicketType.MULTIPLE,
        },
      ],
      salesTypeOptions: salesTypeOptions,
      commissionTypeOptions: commissionTypeOptions,
    };
  },
  computed: {
    isCommissionValid(): boolean {
      const charge = Number(this.form.commission.charge);
      if (this.form.salesType === SalesType.PURCHASE) {
        // 100% 수취이면 수수료 검사를 하지 않는다.
        return true;
      } else if (this.form.commission.type === CommissionType.RATIO) {
        return charge > 5.5;
      } else if (this.form.commission.type === CommissionType.FIXED) {
        return (charge * 100) / this.form.price.sale > 5.5;
      }
      return true;
    },
    minimumQuotaLimit: {
      get(): boolean {
        return this.form.minimumQuota === 0;
      },
      set(value: boolean) {
        if (value) {
          this.form.minimumQuota = 0;
        }
      },
    },
    canClose(): boolean {
      return this.isSale && this.item.status === ItemState.OPENED;
    },
    canTerminate(): boolean {
      return this.item.status === ItemState.CLOSED;
    },
    canOpen(): boolean {
      return this.item.status === ItemState.CLOSED;
    },
  },
  watch: {
    ticketOption(newValue: boolean) {
      if (newValue) {
        this.form.ticketCount = 4;
      } else {
        this.form.ticketCount = 1;
      }
    },
    saleLimit(newValue: boolean) {
      if (!newValue) {
        this.form.maximumPurchasableCount = 0;
      } else if (this.form.maximumPurchasableCount === 0) {
        this.form.maximumPurchasableCount = 1;
      }
    },
    'form.scheduledBySale': function (newValue: boolean) {
      if (!newValue) {
        this.form.saleTerm = null;
      }
    },
    'form.commission.type': function (newValue: CommissionType) {
      if (newValue === CommissionType.FIXED) {
        this.form.commission.bias = 0;
        this.form.commission.charge = 0;
      }
    },
  },
  methods: {
    changeSalesType() {
      // console.log('changeSalesType');
      if (this.form.salesType === SalesType.PURCHASE) {
        this.form.commission.charge = 100;
      } else {
        this.form.commission.charge = 19.8;
      }
    },
    showWarningMessage() {
      const priceValidationResult = productItemValidateService.validatePrice(
        this.form
      );
      const commissionValidationResult =
        productItemValidateService.validateCommission(this.form);
      if (!priceValidationResult.isValid && priceValidationResult.alert) {
        this.$modal.show(
          {
            ...priceValidationResult.alert,
            showCancelButton: true,
          },
          () => {
            this.$emit('change', this.form);
          }
        );
      } else if (
        !commissionValidationResult.isValid &&
        priceValidationResult.alert
      ) {
        this.$modal.show(
          {
            ...commissionValidationResult.alert,
            showCancelButton: true,
          },
          () => {
            this.$emit('change', this.form);
          }
        );
      } else {
        this.$emit('change', this.form);
      }
    },
    /**
     * 정가는 판매가보다 작을 수 없으므로 호드민에서 판매가와 가격을 맞춰준다.(호드민에서는 정가를 설정할 수 없음)
     */
    setItemRetail() {
      if (this.form.price.retail < this.form.price.sale) {
        this.form.price.retail = this.form.price.sale;
      }
    },
    changeItem() {
      if (!this.isAdmin) {
        this.setItemRetail();
      }
      const { isValid, alert } = productItemValidateService.validateItem(
        this.form,
        this.havePlans,
        this.bookingConfirmationEnabled &&
          this.state === QuotaState.RECRUITMENT_BY_ITEM
      );
      if (!isValid && alert) {
        this.$modal.show(alert);
      } else {
        this.showWarningMessage();
      }
    },
    closeItem() {
      if (this.form.representative) {
        this.$modal.show({
          title: '대표 옵션은 판매를 중지할 수 없습니다.',
          message: '대표 옵션 변경 후 중지해주세요.',
        });
      } else {
        this.$modal.show(
          {
            title: '정말로 옵션 판매를 중지하시겠습니까?',
            message: '옵션 판매를 중지합니다.',
            showCancelButton: true,
            type: 'danger',
          },
          () => {
            this.$emit('closeItem', this.form.id);
          }
        );
      }
    },
    terminateItem() {
      if (this.form.representative) {
        this.$modal.show({
          title: '대표 옵션은 판매를 종료할 수 없습니다.',
          message: '대표 옵션 변경 후 종료해주세요.',
        });
      } else {
        this.$modal.show(
          {
            title: '정말로 옵션 판매를 종료하시겠습니까?',
            message: '다시 판매를 시작할 수 없습니다.',
            showCancelButton: true,
            type: 'danger',
          },
          () => {
            this.$emit('terminateItem', this.form.id);
          }
        );
      }
    },
    openItem() {
      this.$modal.show(
        {
          title: '옵션 판매를 시작하시겠습니까?',
          message: '옵션 판매를 시작합니다.',
          showCancelButton: true,
          type: 'primary',
        },
        () => {
          this.$emit('openItem', this.form.id);
          // this.$emit('close');
        }
      );
    },
    closeItemDetail() {
      this.$emit('close');
    },
  },
});
