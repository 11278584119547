

























































































import Vue from 'vue';
import Spinner from '@/components/Spinner.vue';
import LocationAddForm from './LocationAddForm.vue';
import { Table, TableColumn, Select, Option } from 'element-ui';
import { isHostAdmin } from '@/env';
import { DELETE_LOCATION } from '../../queries/location/mutation';
import { PRODUCT_LOCATION_PRESETS } from '../../queries/location/query';
import { Location } from '../../model/FripInfo';
import { LocationAppParam } from '../../model/product/param/locationParam';

interface LocationQueryParam {
  hostId: string;
}

export default Vue.extend({
  name: 'LocationList',
  components: {
    Spinner,
    LocationAddForm,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    hostId: {
      type: String,
    },
    kind: {
      type: String,
      default: 'venue',
    },
  },
  data() {
    return {
      isAdmin: !isHostAdmin(),
      locations: [],
      location: null as LocationAppParam | null,
      emptyText: '등록된 장소가 없습니다. 장소를 추가해주세요.',
      loading: 0,
      locationUpdateModal: false,
      visible: false,
    };
  },
  methods: {
    addLocation(row: Location) {
      const LocationParam = {
        locationId: row.id,
        address: row.address,
        geoPoint: {
          latitude: row.geoPoint.latitude,
          longitude: row.geoPoint.longitude,
        },
        label: row.name,
        overseas: row.overseas,
      };
      const locationAddResult = {
        kind: this.kind,
        location: LocationParam,
      };

      this.$emit('add', locationAddResult);
    },

    updateLocation(row: Location) {
      this.location = {
        id: row.id,
        name: row.name,
        overseas: row.overseas,
        address: row.address,
        geoPoint: row.geoPoint,
        parking: row.parking,
        conveniences: row.conveniences.map(convenience => convenience.id),
        useByDefault: row.useByDefault,
      };
      this.showLocationUpdateModal();
    },
    deleteLocation(id: string) {
      this.$modal.show(
        {
          title: '정말로 삭제하시겠습니까?',
          message: '장소를 삭제합니다.',
          showCancelButton: true,
          confirmText: '네',
          cancelText: '아니오',
          type: 'danger',
        },
        async () => {
          try {
            await this.$apollo.mutate({
              mutation: DELETE_LOCATION,
              variables: {
                id: id,
              },
            });
            this.$notify({
              title: '삭제 성공',
              message: '장소가 성공적으로 삭제되었습니다.',
              type: 'success',
            });
            this.$emit('delete');
          } catch (error) {
            this.$notify({
              title: '삭제 실패',
              message: '장소 삭제에 실패하였습니다.',
              type: 'warning',
            });
          }
        }
      );
    },
    showLocationUpdateModal() {
      this.locationUpdateModal = true;
    },
    closeLocationUpdateModal() {
      this.locationUpdateModal = false;
    },
  },
  apollo: {
    locations: {
      query: PRODUCT_LOCATION_PRESETS,
      variables(): LocationQueryParam {
        return {
          hostId: this.hostId,
        };
      },
      update: data => data.product.locationPresets,
    },
  },
});
