
































































































































































import Vue from 'vue';
import ProductCard from './ProductCard.vue';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
} from 'element-ui';
import Spinner from '@/components/Spinner.vue';
import Instant from '@/components/Labels/Instant.vue';
import { ProductStatusChangeService } from '../service/ProductStatusChangeService';
import { getProductStatusLabel } from '../util/getProductStatusLabel';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import {
  Product,
  ProductConnection,
  ProductEdge,
  ProductState,
} from '../model/product/response/product';
import {
  ProductFilter,
  ProductFilterQuery,
} from '../model/product/param/productSearchFilterParam';
import { PRODUCTS } from '../queries/product/query';
import { getDomainName, isHostAdmin } from '@/env';
import { apolloClient } from '@/apolloClient';
import {
  FripKind,
  ProductKind,
  ProductTermSearchType,
} from '../model/product/enum';
import { FripInfo } from '@/domain/product/model/FripInfo';

const productStatusChangeService = new ProductStatusChangeService(apolloClient);

interface SearchParam {
  page?: number;
  size?: number;
  filter?: ProductFilter;
}

export default Vue.extend({
  name: 'ProductSearchList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    Instant,
    Spinner,
    ProductCard,
  },

  data() {
    return {
      isHostAdmin: isHostAdmin(),
      loading: 0,
      products: [],
      totalCount: 0,
      size: isHostAdmin() ? 9 : 10,
      fripUrl: getDomainName(),
    };
  },
  computed: {
    numberOfPages(): number {
      return Math.ceil(this.totalCount / this.size);
    },
    filter(): ProductFilter {
      const query = this.$route.query as ProductFilterQuery;
      const hostId = isHostAdmin()
        ? this.$store.state.userToken.hostId || 0
        : query.hostId;
      const defaultFripKind = isHostAdmin() ? FripKind.COMMON : null;
      return {
        titleLike: query.titleLike ? query.titleLike : null,
        ids: query.ids ? query.ids : null,
        hostId: hostId ? hostId : null,
        managerId: query.managerId ? query.managerId : null,
        kind: query.kind ? (query.kind as ProductKind) : null,
        statusIn: query.statusIn
          ? (query.statusIn as ProductState[])
          : this.defaultStatusIn,
        attributeIds: query.attributeIds ? query.attributeIds : null,
        exposedChannelIds: query.exposedChannelIds
          ? query.exposedChannelIds
          : null,
        termWithType:
          query.termType && query.startedAt && query.endedAt
            ? {
                type: query.termType as ProductTermSearchType,
                term: {
                  startedAt: Number(query.startedAt),
                  endedAt: Number(query.endedAt),
                },
              }
            : null,
        standardCategoryIds: query.standardCategoryIds
          ? query.standardCategoryIds
          : null,
        itemStatusIn: query.itemStatusIn ? query.itemStatusIn : null,
        fripKind: query.fripKind
          ? (query.fripKind as FripKind)
          : defaultFripKind,
      };
    },
    hostId(): string {
      return this.$store.state.userToken.hostId;
    },
    hostProducts(): Product[] {
      return this.products.filter(
        (product: Product) => product.host.id === this.hostId
      );
    },
    defaultStatusIn(): ProductState[] | null {
      return isHostAdmin()
        ? [
            ProductState.EDITING,
            ProductState.READY,
            ProductState.SALE,
            ProductState.SOLD_OUT,
            ProductState.PAUSED,
            ProductState.APPLIED,
            ProductState.INSPECTING,
            ProductState.REJECTED,
            ProductState.SUSPENDED,
          ]
        : null;
    },
  },
  methods: {
    linkGen(pageNum: number): any {
      return {
        query: {
          ...this.$route.query,
          page: pageNum >= 1 ? pageNum : undefined,
          size: this.size,
        },
      };
    },
    convertedStatus(status: ProductState): string {
      return getProductStatusLabel(status);
    },
    convertFripKind(frip: FripInfo): string {
      if (!!frip && frip.kind == FripKind.MEETUP) {
        return '⚡';
      } else return '';
    },
    changeProductStatusButtonText(status: ProductState): string {
      if (status === ProductState.READY) return '판매 시작';
      if (status === ProductState.SALE) return '판매 중지';
      if (status === ProductState.PAUSED) return '판매 시작';
      return '';
    },
    showErrorNotification(title: string, error: any) {
      this.$notify({
        title: title,
        message: error.message || '',
        type: 'warning',
      });
    },
    refresh() {
      this.$apollo.queries.products.refresh();
    },
    async changeProductStatus(id: string, title: string, status: ProductState) {
      if (status === ProductState.READY || status === ProductState.PAUSED)
        await this.openProduct(id, title);
      else if (status === ProductState.SALE) await this.pauseProduct(id, title);
    },
    async openProduct(id: string, title: string) {
      this.$modal.show(
        {
          title: `${title}`,
          message: '상품 판매를 시작합니다.',
          type: 'primary',
          showCancelButton: true,
          confirmText: '네',
          cancelText: '아니오',
        },
        async () => {
          try {
            await productStatusChangeService.openProduct(id);
            await this.$apollo.queries.products.refetch();
            this.$notify({
              title: '상품 판매 시작',
              message: '상품이 판매중 상태로 변경되었습니다.',
              type: 'success',
            });
          } catch (error) {
            this.showErrorNotification('상품 판매 시작 실패', error);
          }
        }
      );
    },
    async pauseProduct(id: string, title: string) {
      this.$modal.show(
        {
          title: `${title}`,
          message: '상품 판매를 일시 중지합니다.',
          type: 'warning',
          showCancelButton: true,
          confirmText: '네',
          cancelText: '아니오',
        },
        async () => {
          try {
            await productStatusChangeService.pauseProduct(id);
            await this.$apollo.queries.products.refetch();
            this.$notify({
              title: '상품 판매 일시 중지',
              message: '상품이 일시 중지 상태로 변경되었습니다.',
              type: 'success',
            });
          } catch (error) {
            this.showErrorNotification('상품 판매 일시 중지 실패', error);
          }
        }
      );
    },
    copyProduct(productId: string) {
      console.log('productId: ', productId);
      this.$modal.show(
        {
          title: '상품을 복제하시겠습니까?',
          message:
            '일정있는 상품의 경우 3개월 이내의 일정만 복사가 가능합니다.',
          type: 'primary',
          showCancelButton: true,
          confirmText: '네',
          cancelText: '아니오',
        },
        () => {
          this.$router.push({
            path: '/product/create',
            query: { sourceProductId: productId },
          });
        }
      );
    },
  },
  apollo: {
    products: {
      query: PRODUCTS,
      variables(): SearchParam {
        return {
          page: Number(this.$route.query.page) || 1,
          size: this.size,
          filter: this.filter,
        };
      },
      deep: true,
      error(e: ApolloError) {
        console.error(e);
        this.$notify({
          title: '상품 불러오기 실패',
          message: e.message,
          type: 'warning',
        });
      },
      result(
        result: ApolloQueryResult<{
          product: { products: ProductConnection };
        }>
      ) {
        this.totalCount = result.data.product.products.totalCount;
      },
      update: data =>
        data.product.products.edges.map((edge: ProductEdge) => edge.node),
    },
  },
});
