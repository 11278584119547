import { gql } from 'graphql-tag';

export const SCHEDULES_BY_TERM = gql`
  query productSchedulesByYearMonth(
    $productId: ID!
    $schedulingTerm: FixedTermInput!
  ) {
    product {
      schedulesByTerm(productId: $productId, schedulingTerm: $schedulingTerm) {
        id
        title
        term {
          startedAt
          endedAt
        }
        saleTerm {
          endedAt
        }
        quota
        minimumQuota
        reasonOfCancellation
        status
      }
    }
  }
`;
