











import Vue from 'vue';
export default Vue.extend({
  props: {
    label: {
      type: String,
    },
    labelClasses: {
      type: String,
      default: 'form-control-label',
    },
  },
});
