



















import Vue from 'vue';
import { Select, Option } from 'element-ui';
import { Location } from '@/domain/product/model/FripInfo';

export default Vue.extend({
  name: 'LocationSelect',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    kind: {
      type: String,
      default: 'venue',
    },
    locationList: {
      type: [],
    },
    locationSelect: {
      type: Object,
      default: () => [{}],
    },
  },
  data() {
    return {
      selectedLocation:
        this.kind === this.locationSelect.kind ? this.locationSelect.label : '',
      locations: this.locationList,
    };
  },
  watch: {
    locationList() {
      this.locations = this.locationList;
    },
    locationSelect() {
      if (this.kind === this.locationSelect.kind) {
        this.selectedLocation = this.locationSelect.label;
      }
    },
  },
  methods: {
    addLocation(row: Location) {
      const LocationParam = {
        locationId: row.id,
        address: row.address,
        geoPoint: {
          latitude: row.geoPoint.latitude,
          longitude: row.geoPoint.longitude,
        },
        label: row.name,
        overseas: row.overseas,
      };
      const locationAddResult = {
        kind: this.kind,
        location: LocationParam,
      };
      this.$emit('add', locationAddResult);
    },
  },
});
