import { ProductKind } from '../model/product/enum';

export const getProductKindLabel = (kind: ProductKind): string => {
  switch (kind) {
    case ProductKind.OFFLINE:
      return '오프라인';
    case ProductKind.ONLINE:
      return '온라인';
    case ProductKind.EVENT:
      return '이벤트';
    case ProductKind.GOODS:
      return '배송';
    case ProductKind.DIGITAL:
      return '온라인 문서';
    default:
      return '';
  }
};
