import { ProductState } from '../model/product/enum';

export const convertStringToProductState = (state: string): ProductState => {
  switch (state) {
    case 'EDITING':
      return ProductState.EDITING;
    case 'READY':
      return ProductState.READY;
    case 'SALE':
      return ProductState.SALE;
    case 'SOLD_OUT':
      return ProductState.SOLD_OUT;
    case 'PAUSED':
      return ProductState.PAUSED;
    case 'APPLIED':
      return ProductState.APPLIED;
    case 'INSPECTING':
      return ProductState.INSPECTING;
    case 'REJECTED':
      return ProductState.REJECTED;
    case 'CLOSED':
      return ProductState.CLOSED;

    default:
      return ProductState.EDITING;
  }
};
