import { MutationService } from '@/common/service/MutationService';
import {
  ADD_CHANNEL_MEMBERS,
  REMOVE_CHANNEL_MEMBERS,
} from '@/domain/product/queries/talkplus/mutation';

export class TalkPlusService extends MutationService {
  public async addChannelMembers(
    channelId: string,
    ids: string[]
  ): Promise<boolean> {
    const response = await this.apollo.mutate({
      mutation: ADD_CHANNEL_MEMBERS,
      variables: {
        channelId: channelId,
        ids: ids,
      },
    });
    return response.data.addChannelMembers;
  }

  public async removeChannelMembers(
    channelId: string,
    ids: string[]
  ): Promise<boolean> {
    const response = await this.apollo.mutate({
      mutation: REMOVE_CHANNEL_MEMBERS,
      variables: {
        channelId: channelId,
        ids: ids,
      },
    });
    return response.data.removeChannelMembers;
  }
}
