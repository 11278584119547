import { TagKind } from '../model/product/response/tag';
import { TagParamInput } from '../model/product/param/productTagParam';
import { SAVE_TAGS } from '../queries/tag/mutation';
import { MutationService } from '@/common/service/MutationService';

export class ProductTagService extends MutationService {
  public async saveTags(kind: TagKind, params: TagParamInput[]): Promise<void> {
    const tagParms = this.convertParams(params);
    await this.apollo.mutate({
      mutation: SAVE_TAGS,
      variables: {
        kind: kind,
        params: tagParms,
      },
    });
  }
  private convertParams(params: TagParamInput[]): TagParamInput[] {
    return params.map(param => {
      return {
        id: param.id,
        name: param.name,
        backgroundColor: param.backgroundColor,
        textColor: param.textColor,
        activated: param.activated,
      };
    });
  }
}
