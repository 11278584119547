







































import Vue from 'vue';

export default Vue.extend({
  name: 'InquiryPreview',
  props: {
    inquiryTemplate: {
      type: Object,
    },
  },
  data() {
    return {
      inputKind: {
        SINGLE_CHOICE: {
          type: 'success',
          text: '단일선택',
        },
        MULTI_CHOICE: {
          type: 'primary',
          text: '다중선택',
        },
        TEXT: {
          type: 'primary',
          text: '단문',
        },
      },
    };
  },
  methods: {
    deleteInquiry() {
      this.$emit('delete', this.inquiryTemplate.id);
    },
  },
});
