
















import Vue from 'vue';
import { ScheduleTimeParam } from '../../model/product/param/productSaleInfoParam';
import ScheduleTimeInput from '../Schedule/ScheduleTimeInput.vue';

export default Vue.extend({
  name: 'PlanTimeInput',
  components: {
    ScheduleTimeInput,
  },
  props: {
    times: {
      type: Array,
    },
    noPlan: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      forms:
        this.times && this.times.length > 0
          ? (this.times as ScheduleTimeParam[])
          : ([
              {
                paramId: Math.random().toString(),
                starting: {
                  hour: 0,
                  minute: 0,
                },
                duration: 0,
              },
            ] as ScheduleTimeParam[]),
    };
  },
  computed: {
    convertedTimes(): ScheduleTimeParam[] {
      return this.forms.map(form => {
        return {
          paramId: form.paramId,
          starting: form.starting,
          duration: form.duration,
        };
      });
    },
  },
  methods: {
    addTime() {
      this.forms.push({
        paramId: Math.random().toString(),
        starting: {
          hour: 0,
          minute: 0,
        },
        duration: 0,
      });
    },
    changeTime(time: ScheduleTimeParam) {
      const index = this.forms.findIndex(
        (form: ScheduleTimeParam) => form.paramId === time.paramId
      );
      this.forms.splice(index, 1, time);
      this.$emit('change', this.convertedTimes);
    },
    removeTime(index: number) {
      this.forms.splice(index, 1);
    },
  },
});
