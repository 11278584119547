





































































































import Vue from 'vue';
import { Table, TableColumn } from 'element-ui';
import { PRODUCT_MERCHANDISERS } from '../../queries/merchandiser/query';
import { MerchandiserService } from '../../service/MerchandiserService';
import { apolloClient } from '@/apolloClient';
import { MessageType } from 'element-ui/types/message';
import { Merchandiser } from '../../model/merchandiser';
import { Connection } from '@frientrip/domain';

const merchandiserSerivce = new MerchandiserService(apolloClient);

interface MerchandiserQueryParam {
  page?: number;
  size?: number;
  filter?: MerchandiserFilter | null;
}

interface MerchandiserFilter {
  nameLike?: string | null;
}
export default Vue.extend({
  name: 'MerchandiserList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      merchandiserResult: {} as Connection<Merchandiser>,
      size: 10,
      name: '',
      filter: {
        nameLike: null,
      } as MerchandiserFilter,
    };
  },
  computed: {
    merchandisers(): Merchandiser[] {
      return this.merchandiserResult.edges?.map(edge => edge.node) || [];
    },
    totalCount(): number {
      return this.merchandiserResult.totalCount || this.size;
    },
    numberOfPages(): number {
      return Math.ceil(this.totalCount / this.size);
    },
  },
  methods: {
    setFilter() {
      this.filter.nameLike = this.name;
      // this.$apollo.queries.merchandiserResult.refetch();
    },
    showNotify(title: string, message: string, type: MessageType) {
      this.$notify({ title: title, message: message, type: type });
    },
    async deleteMerchandiser(id: string) {
      try {
        await merchandiserSerivce.deleteMerchandiser(id);
        this.$modal.show(
          {
            title: '정말로 삭제하시겠습니까?',
            message: 'MD를 삭제합니다.',
            type: 'danger',
            showCancelButton: true,
          },
          async () => {
            this.showNotify(
              '삭제 성공',
              '성공적으로 삭제되었습니다.',
              'success'
            );
            await this.$apollo.queries.merchandiserResult.refetch();
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    linkGen(pageNum: number): string {
      return pageNum === 1 ? '?' : `?page=${pageNum}`;
    },
  },
  apollo: {
    merchandiserResult: {
      query: PRODUCT_MERCHANDISERS,
      variables(): MerchandiserQueryParam {
        return {
          page: Number(this.$route.query.page) || 1,
          size: this.size,
          filter: {
            nameLike: this.filter.nameLike,
          },
        };
      },
      update: data => data.product.merchandisers,
    },
  },
});
