




































import Vue from 'vue';
import gql from 'graphql-tag';
import { Edge } from '@frientrip/domain';
import { ApolloQueryResult } from 'apollo-client';

interface UsersSearchParam {
  filter?: UserFilter;
}

interface UserSearchParam {
  id: string;
}

interface UserFilter {
  id?: string | null;
  nicknameLike?: string | null;
}

interface User {
  id: string;
  nickname: string;
}

export default Vue.extend({
  name: 'UserSearchInput',
  props: {
    userId: {
      type: String,
    },
  },
  data() {
    return {
      id: this.userId || '0',
      nickname: '',
      users: [] as User[],
      visible: true,
      selectedUser: {
        id: '',
        nickname: '',
      },
    };
  },
  computed: {
    userSearchList(): User[] {
      if (this.visible) {
        return this.users;
      } else {
        return [];
      }
    },
  },
  methods: {
    searchUser(nickname: string) {
      this.visible = true;
      this.nickname = nickname;
    },
    selectUser(user: User) {
      this.selectedUser = {
        id: user.id,
        nickname: user.nickname,
      };
      this.nickname = this.selectedUser.nickname;
      this.$emit('select', this.selectedUser.id);
      this.visible = false;
    },
  },
  apollo: {
    users: {
      query: gql`
        query users($filter: UserFilter) {
          users(filter: $filter) {
            edges {
              node {
                id
                nickname
                email
              }
            }
          }
        }
      `,
      variables(): UsersSearchParam {
        return {
          filter: {
            nicknameLike: this.nickname,
          },
        };
      },
      update: data => data.users.edges.map((edge: Edge<User>) => edge.node),
      skip(): boolean {
        return this.nickname === '' || !this.visible;
      },
      debounce: 200,
    },
    selectedUser: {
      query: gql`
        query user($id: ID!) {
          user(id: $id) {
            id
            nickname
            email
          }
        }
      `,
      variables(): UserSearchParam {
        return {
          id: this.userId,
        };
      },
      result(result: ApolloQueryResult<{ user: User }>) {
        this.nickname = result.data.user.nickname;
        this.visible = false;
      },
      update: data => data.user,
      skip(): boolean {
        if (this.userId) return false;
        else return true;
      },
    },
  },
});
