import { DayOfWeek } from '../model/product/enum';

export const convertWeek = (day: DayOfWeek): string => {
  switch (day) {
    case DayOfWeek.MONDAY:
      return '월';
    case DayOfWeek.TUESDAY:
      return '화';
    case DayOfWeek.WEDNESDAY:
      return '수';
    case DayOfWeek.THURSDAY:
      return '목';
    case DayOfWeek.FRIDAY:
      return '금';
    case DayOfWeek.SATURDAY:
      return '토';
    case DayOfWeek.SUNDAY:
      return '일';
    default:
      return '';
  }
};

export const getDayValue = (day: DayOfWeek): number => {
  switch (day) {
    case DayOfWeek.MONDAY:
      return 1;
    case DayOfWeek.TUESDAY:
      return 2;
    case DayOfWeek.WEDNESDAY:
      return 3;
    case DayOfWeek.THURSDAY:
      return 4;
    case DayOfWeek.FRIDAY:
      return 5;
    case DayOfWeek.SATURDAY:
      return 6;
    case DayOfWeek.SUNDAY:
      return 0;
  }
};
