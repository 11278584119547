import gql from 'graphql-tag';

export const PRODUCT_LOCATION_PRESETS = gql`
  query locationPresets($hostId: String!) {
    product {
      locationPresets(hostId: $hostId) {
        id
        name
        address
        conveniences {
          id
          name
        }
        geoPoint {
          latitude
          longitude
        }
        overseas
        parking {
          available
          note
        }
        useByDefault
      }
    }
  }
`;

export const CONVENIENCES = gql`
  query conveniences {
    product {
      conveniences {
        id
        name
      }
    }
  }
`;
