import gql from 'graphql-tag';

export const CURRICULUM_FRAGMENT = gql`
  fragment curriculumField on Curriculum {
    kind
    sections {
      id
      title
      etc
      status
      items {
        id
        term {
          startingTime {
            hour
            minute
          }
          endingTime {
            hour
            minute
          }
        }
        duration
        textTime
        content
      }
    }
    style
  }
`;
