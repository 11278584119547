







































































































































































































































import Vue from 'vue';
import CardSelect from '@/components/Forms/CardSelect.vue';
import Description from '@/components/Labels/Description.vue';
import ProductCommissionInput from '../../ProductCommissionInput.vue';
import SaleDateInput from '../../SaleEndedAtInput.vue';
import OptionInput from '../../Option/OptionInput.vue';
import OptionInfoExample from '../../Option/OptionInfoExample.vue';
import ItemList from '../../Item/ItemList.vue';
import ScheduleCalendar from '../../Schedule/ScheduleCalendar.vue';
import Spinner from '@/components/Spinner.vue';
import { Option, Select } from 'element-ui';
import {
  goodsTypeOptions,
  inventoryTargetTypeOptions,
  planTypeOptions,
  purchaseLimitOptions,
  purchaseLimitTargetTypeOptions,
  saleStartOptions,
} from '../../../constants/productCreateOptions';
import {
  ItemParam,
  ProductCommission,
  ProductOptionParam,
} from '@/domain/product/model/product/param/productSaleInfoParam';
import { ProductItemService } from '../../../service/ProductItemService';
import {
  CategoryPrefix,
  InventoryTargetType,
  ProductKind,
  QuotaState,
  ScheduleState,
} from '../../../model/product/enum';
import {
  commissionTypeOptions,
  salesTypeOptions,
} from '@/domain/product/constants/commisionOptions';
import { isHostAdmin } from '@/env';
import { Host } from '@/domain/product/model/product/response/product';
import { ProductState, SalesType } from '@/domain/product/model/product/enum';
import { Schedule } from '@/domain/product/model/product/response/schedule';

interface OptionInputParam {
  id?: string;
  title: string;
  names: string;
}

const productItemService = new ProductItemService();

export default Vue.extend({
  name: 'ProductSaleInfoForm',
  components: {
    CardSelect,
    Description,
    SaleDateInput,
    OptionInput,
    OptionInfoExample,
    ProductCommissionInput,
    Spinner,
    ItemList,
    ScheduleCalendar,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    product: {
      type: Object,
    },
    schedules: {
      type: Array,
    },
    schedulingTerm: {
      type: Object,
    },
    host: {
      type: Object,
    },
    hostId: {
      type: String,
    },
    isUpdateForm: {
      type: Boolean,
    },
    scheduleLoading: {
      type: Number,
    },
    canEdit: {
      type: Boolean,
    },
    firstOpenedAt: {
      type: Number,
    },
  },
  data() {
    return {
      form: this.product,
      isAdmin: !isHostAdmin(),
      saleStartOption:
        this.isUpdateForm &&
        this.product.saleTerm.startedAt > new Date().getTime()
          ? 'manual'
          : 'now',
      grossPurchaseLimitationOption:
        this.isUpdateForm && this.product.grossPurchaseLimitation.count > 0
          ? 'limit'
          : 'noLimit',
      maximumPurchasableLimitOption:
        this.isUpdateForm && this.product.maximumPurchasableCount > 0
          ? 'limit'
          : 'noLimit',
      representativeCommission: {
        salesType: SalesType.AGENCY,
        default: {
          bias: this.host.commission.default.bias,
          charge: this.host.commission.default.charge,
          type: this.host.commission.default.type,
        },
        absent: {
          bias: this.host.commission.absent.bias,
          charge: this.host.commission.absent.charge,
          type: this.host.commission.absent.type,
        },
      },
      selectedTemplate: '',
      selectedInquiry: {},
      saleStartOptions: saleStartOptions,
      planTypeOptions: planTypeOptions,
      goodsTypeOptions: goodsTypeOptions,
      purchaseLimitTargetTypeOptions: purchaseLimitTargetTypeOptions,
      purchaseLimitOptions: purchaseLimitOptions,
      InventoryTargetTypeOptions: inventoryTargetTypeOptions,
      items: [] as ItemParam[],
      limitOptions: [
        {
          value: 'limit',
          text: '제한',
        },
        {
          value: 'noLimit',
          text: '미제한',
        },
      ],
      salesTypeOptions: salesTypeOptions,
      commissionTypeOptions: commissionTypeOptions,
    };
  },
  computed: {
    havePlans(): boolean {
      return this.form.frip.attachedToSchedule;
    },
    productOptions(): OptionInputParam[] {
      return this.form.options.map((option: ProductOptionParam) => {
        return {
          id: option.id,
          title: option.title,
          names: option.names.join(','),
        };
      });
    },
    categoryPrefix(): CategoryPrefix {
      const prefix = this.form.standardCategoryId.substring(0, 4);
      if (prefix === '0001') {
        return CategoryPrefix.OFFLINE;
      } else if (prefix === '0002') {
        return CategoryPrefix.ONLINE;
      } else {
        return CategoryPrefix.SHIPPING;
      }
    },
    isLive(): boolean {
      const prefix = this.form.standardCategoryId.substring(0, 8);
      return prefix === '00021388'; // TODO: 개선필요
    },
    isSale(): boolean {
      const saleStatus = [
        ProductState.SALE,
        ProductState.SOLD_OUT,
        ProductState.PAUSED,
      ];
      return this.form.status ? saleStatus.includes(this.form.status) : false;
    },
    isDigitalFrip(): boolean {
      return this.form.kind === ProductKind.DIGITAL;
    },
    quotaState(): QuotaState {
      /**
       * 1. 일정 X & 배송 / 이벤트 -> 재고수량
       * 2. 일정 X & 온라인(1차 카테고리 Live 제외) -> 수량한정판매
       * 3. 일정 X & 오프라인(온라인 & 1차 카테고리 Live 포함) -> 최대예약수량
       * 4. 일정 O & 옵션별 모집인원 제한 -> 옵션별 모집인원
       * 5. 일정 O & 일정별 모집인원 제한 -> 일정별 모집인원
       */
      if (this.havePlans) {
        if (this.form.inventoryTargetType === InventoryTargetType.BY_ITEM) {
          return QuotaState.RECRUITMENT_BY_ITEM;
        } else {
          return QuotaState.RECRUITMENT_BY_SCHEDULE;
        }
      } else {
        if (this.categoryPrefix === CategoryPrefix.OFFLINE || this.isLive) {
          return QuotaState.OFFLINE;
        } else if (this.categoryPrefix === CategoryPrefix.ONLINE) {
          return QuotaState.ONLINE;
        } else {
          return QuotaState.SHIPPING;
        }
      }
    },
    isFrip(): boolean {
      return this.form.kind !== ProductKind.GOODS;
    },
  },
  watch: {
    scheduleLoading() {
      if (this.schedules) {
        // TODO: 여기서 스케쥴을 합쳐줌 개선 필요
        this.form.frip.schedules = this.schedules;
        this.saveForm();
      }
    },
    grossPurchaseLimitationOption(newValue: string) {
      if (newValue === 'noLimit') {
        this.form.grossPurchaseLimitation.count = 0;
      }
    },
    maximumPurchasableLimitOption(newValue: string) {
      if (newValue === 'noLimit') {
        this.form.maximumPurchasableCount = 0;
      }
    },
    host: {
      handler(newHost: Host) {
        this.representativeCommission.default = newHost.commission.default;
        this.representativeCommission.absent = newHost.commission.absent;
      },
    },
  },
  created() {
    const representativeItem = this.form.items.filter(
      (item: ItemParam) => item.representative
    );

    if (representativeItem.length > 0) {
      // 대표 아이템이 있을 경우
      this.representativeCommission.default = representativeItem[0].commission;
      this.representativeCommission.absent = representativeItem[0].absentFee;
      this.representativeCommission.salesType = representativeItem[0].salesType;
    }
  },
  methods: {
    changeStartedAt() {
      if (this.saleStartOption === 'now') {
        this.form.saleTerm.startedAt = new Date().getTime();
      }
      this.saveForm();
    },
    changeOptions(options: ProductOptionParam) {
      this.form.options = options;
    },
    // 아이템을 만든다.
    // 1. 옵션 목륵으로 저장 버튼 클릭시 동작 위치.
    applyOptionsToItems() {
      if (this.isSale) {
        this.$modal.show({
          title: '판매중인 상품의 경우 옵션 변경이 불가능합니다.',
          message: '개별 옵션 추가만 가능합니다.',
        });
      } else if (this.form.items.length > 0) {
        this.$modal.show(
          {
            title: '등록한 옵션을 덮어씌웁니다.',
            message: '옵션을 생성하시겠습니까?',
            showCancelButton: true,
          },
          () => {
            this.generateItems();
          }
        );
      } else {
        this.generateItems();
      }
    },
    deleteOptionIds() {
      const newOptions = this.form.options.map((option: OptionInputParam) => {
        return {
          names: option.names,
          title: option.title,
        };
      });
      this.form.options.splice(0);
      this.form.options.push(...newOptions);
    },
    generateItems() {
      // console.log('this.generateItems() -----------------------------');
      const { isValid, alert } = productItemService.validateOptions(
        this.form.options
      );
      if (isValid) {
        this.deleteOptionIds();
        // console.log('---------------------------------------------');
        // console.log(this.representativeCommission);
        // console.log('---------------------------------------------');
        this.form.items = productItemService.generateItemsByOptions(
          this.form.options,
          this.quotaState,
          this.representativeCommission
        );
      } else if (alert) {
        this.$modal.show(alert);
      }
    },
    // 하위에서 전달해준 정보를 적용하는 부분.
    setItemCommission(productCommission: ProductCommission) {
      console.log(productCommission.commission.charge);
      console.log(this.representativeCommission.default.charge);
      if (this.isAdmin) {
        console.log('-------------------------');
        console.log('이전' + this.representativeCommission.salesType);
        console.log('변경될' + productCommission.salesType);
        if (productCommission.salesType === SalesType.PURCHASE) {
          productCommission.commission.charge = 100;
        } else {
          productCommission.commission.charge
            ? productCommission.commission.charge
            : (productCommission.commission.charge = 19.8);
        }

        this.representativeCommission.salesType = productCommission.salesType;
        this.representativeCommission.default.type =
          productCommission.commission.type;
        this.representativeCommission.default.charge =
          productCommission.commission.charge;
        this.representativeCommission.default.bias =
          productCommission.commission.bias;

        this.representativeCommission.absent.type =
          productCommission.absentFee.type;
        this.representativeCommission.absent.charge =
          productCommission.absentFee.charge;
        this.representativeCommission.absent.bias =
          productCommission.absentFee.bias;

        this.form.items.forEach((item: ItemParam) => {
          // console.log(
          //   'commission.charge::' + productCommission.commission.charge
          // );
          item.salesType = productCommission.salesType;
          item.commission.type = productCommission.commission.type;
          item.commission.charge = productCommission.commission.charge;
          item.commission.bias = productCommission.commission.bias;

          item.absentFee.type = productCommission.absentFee.type;
          item.absentFee.charge = productCommission.absentFee.charge;
          item.absentFee.bias = productCommission.absentFee.bias;
        });
      }
    },
    setItemQuota(itemParamIds: string[]) {
      console.log('setItemQuota: ', itemParamIds);
      if (itemParamIds.length === 0) {
        this.form.items.forEach((item: ItemParam) => {
          item.quota = 1;
          item.minimumQuota = 1;
        });
      } else {
        this.form.items.forEach((item: ItemParam) => {
          if (itemParamIds.includes(item.paramId)) {
            item.quota = 1;
            item.minimumQuota = 1;
          }
        });
      }
      this.$notify({
        title: '옵션 모집인원 변경',
        message: '옵션의 모집인원을 1명으로 변경하였습니다.',
        type: 'success',
      });
    },
    selectFripType(attachedToSchedule: boolean) {
      if (attachedToSchedule) {
        // 날짜 조율형 -> 날짜 지정형으로 변경
        this.form.frip.daysOfExpiration = 0;
        this.form.frip.bookingConfirmationEnabled = false;
        this.form.inventoryTargetType = InventoryTargetType.BY_SCHEDULE;
      } else {
        // 날짜 지정형 -> 날짜 조율형으로 변경
        this.$modal.show(
          {
            message:
              '기존에 입력하신 옵션들과 유의사항이 초기화됩니다. 유형을 변경하시겠습니까?',
            showCancelButton: true,
          },
          () => {
            // TODO: 일정이 삭제된다는 얼럿 추가 필요
            this.form.frip.daysOfExpiration = 90;
            this.form.inventoryTargetType = InventoryTargetType.BY_ITEM;
            this.form.frip.bookingConfirmationEnabled = true;
            this.form.items = [];
            // --- 추가 및 테스트 ----
            // 일정 목록 삭제 []로 보내면 삭제 되지 않는다.
            // 일정 상태를 0으로 변경한다.
            // empty check하므로 변경되지 않음
            this.form.frip.schedules = [];
            this.form.frip.plans = [];
          },
          () => {
            this.rollbackSelectFripType(attachedToSchedule);
          }
        );
      }
    },
    selectInventoryTargetType(inventoryTargetType: string) {
      const hasScheduleOnSales = this.form.frip.schedules.find((x: Schedule) =>
        [
          ScheduleState.OPENED,
          ScheduleState.PAUSED,
          ScheduleState.SOLD_OUT,
        ].includes(x.status)
      );

      if (hasScheduleOnSales) {
        this.$modal.show(
          {
            message:
              '판매 가능한 일정이 있어 모집인원 유형을 변경할 수 없습니다. 판매중, 판매중지, 매진인 일정을 취소하거나, 삭제한 후 다시 시도해 주세요.',
            showCancelButton: true,
            confirmText: '일정 확인',
            confirmButtonType: 'warning',
          },
          () => {
            this.$router.push(`/product/list/${this.form.id}/schedules`);
          },
          () => {
            this.rollbackSelectInventoryTargetType(inventoryTargetType);
          }
        );
        return;
      }

      const hasEditingSchedule = this.form.frip.schedules.find(
        (x: Schedule) => x.status == ScheduleState.EDITING || !x.status
      );

      if (hasEditingSchedule) {
        this.$modal.show(
          {
            message:
              '모집인원 제한 유형 변경시, 등록중인 일정이 초기화됩니다. \n 계속 진행하시겠습니까?',
            showCancelButton: true,
          },
          () => {
            this.changeInventoryTargetType(inventoryTargetType);
            this.form.frip.schedules = this.form.frip.schedules.filter(
              (x: Schedule) => x.status && x.status != ScheduleState.EDITING
            );
            this.form.frip.plans = [];
          },
          () => {
            this.rollbackSelectInventoryTargetType(inventoryTargetType);
          }
        );
      }
    },
    rollbackSelectFripType(attachedToSchedule: boolean) {
      this.form.frip.attachedToSchedule = !attachedToSchedule;
      this.form.frip.bookingConfirmationEnabled = attachedToSchedule;
    },
    rollbackSelectInventoryTargetType(inventoryTargetType: string) {
      this.form.inventoryTargetType =
        inventoryTargetType == InventoryTargetType.BY_SCHEDULE
          ? InventoryTargetType.BY_ITEM
          : InventoryTargetType.BY_SCHEDULE;
    },
    changeInventoryTargetType(inventoryTargetType: string) {
      if (inventoryTargetType === InventoryTargetType.BY_SCHEDULE) {
        this.form.items.forEach((item: ItemParam) => {
          item.quota = 0;
          item.minimumQuota = 0;
        });
      } else {
        this.form.items.forEach((item: ItemParam) => {
          item.quota = 10;
          item.minimumQuota = 1;
        });
      }
    },
    refetchProduct() {
      this.$emit('refetchProduct');
    },
    refetchSchedules() {
      this.$emit('refetchSchedules');
    },
    saveForm() {
      this.$emit('save', this.form);
    },
  },
});
