

























































































































































































import Vue from 'vue';
import DaySelect from '../DaySelect.vue';
import RangeInput from '../../../../components/Forms/RangeInput.vue';
import PlanTimeInput from '../Plan/PlanTimeInput.vue';
import ItemSelectList from '../Item/ItemSelectList.vue';
import { Select, Option } from 'element-ui';
import {
  ItemParam,
  ScheduleTimeParam,
} from '../../model/product/param/productSaleInfoParam';
import {
  DAY_MILLISECOND,
  HOUR_MILLISECOND,
} from '@/common/util/day/getConstants';
import { deepCopy } from '@/common/util/deepCopy';
import { ScheduleTemplate } from '../../model/product/param/productSaleInfoParam';
import {
  DayOfWeek,
  InventoryTargetType,
  RepeatSchedule,
} from '../../model/product/enum';
import { OptionType } from '@/common/model/Option';
import { SCHEDULING_TERM_MONTH } from '../../constants/scheduleConstant';
import { HourMinute } from '../../model/product/hourMinute';
import { getDayValue } from '../../util/convertWeek';
import { isHostAdmin } from '@/env';
import { ModalOption } from '@/frip-alert-modal';

interface Duration {
  day: number;
  hour: number;
}

interface ScheduleTemplateFormData {
  scheduleTemplate: ScheduleTemplate;
  itemList: ItemParam[];
  duration: Duration;
  itemSelectModal: boolean;
  today: string;
  repeatScheduleOptions: OptionType[];
  operatingDaysOptions: OptionType[];
  overNightOptions: OptionType[];
  saleTermEndedAt: number;
  isAdmin: boolean;
}

export default Vue.extend({
  name: 'ScheduleTemplateForm',
  components: {
    DaySelect,
    RangeInput,
    PlanTimeInput,
    ItemSelectList,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    items: {
      type: Array,
    },
    inventoryTargetType: {
      type: String,
    },
    isUpdateForm: {
      type: Boolean,
      default: false,
    },
    date: {
      type: Number,
    },
    bookingConfirmationEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data(): ScheduleTemplateFormData {
    return {
      scheduleTemplate: {
        repeatSchedule: RepeatSchedule.ONCE,
        term: {
          startedAt: this.date,
          endedAt: this.date, // TODO: 수정
        },
        name: '',
        preparatoryPeriod: 0,
        times: [],
        appliedAllItems: true,
        targetItemParamIds: [],
        weeks: [],
        minimumQuota:
          this.inventoryTargetType === InventoryTargetType.BY_SCHEDULE ? 1 : 0,
        quota:
          this.inventoryTargetType === InventoryTargetType.BY_SCHEDULE ? 10 : 0,
      },
      today: this.date ? this.$moment(this.date).format('YYYY-MM-DD') : '',
      itemList: deepCopy(this.items) as ItemParam[],
      duration: {
        day: 3,
        hour: 0,
      },
      itemSelectModal: false,
      repeatScheduleOptions: [
        { label: '반복 없음', value: RepeatSchedule.ONCE },
        { label: '매일', value: RepeatSchedule.DAY },
        { label: '매주', value: RepeatSchedule.WEEK },
      ],
      operatingDaysOptions: [{ label: '사용자 선택', value: 'manual' }],
      overNightOptions: [
        { text: '예', value: true },
        { text: '아니오', value: false },
      ],
      saleTermEndedAt: this.$moment()
        .clone()
        .add(SCHEDULING_TERM_MONTH, 'month')
        .endOf('date') // 현재로부터 3개월 이후 23시 59분 59초까지
        .valueOf(),
      isAdmin: !isHostAdmin(),
    };
  },
  computed: {
    period(): number {
      return (
        this.duration.day * DAY_MILLISECOND +
        this.duration.hour * HOUR_MILLISECOND
      );
    },
    todayTime(): number {
      return new Date(this.today).getTime();
    },
    selectedItems(): ItemParam[] {
      return this.itemList.filter((item: ItemParam) =>
        this.scheduleTemplate.targetItemParamIds.includes(item.paramId)
      );
    },
    firstStartedAt(): HourMinute {
      const scheduleStartedAt = this.scheduleTemplate.times.map(
        time => time.starting.hour * 60 + time.starting.minute
      );

      const firstStartedAt = scheduleStartedAt.length
        ? Math.min(...scheduleStartedAt)
        : 0;

      return {
        // TODO: 개선필요
        hour: Math.floor(firstStartedAt / 60),
        minute: firstStartedAt % 60,
      };
    },
    firstSaleEndedAt(): number {
      const firstStartedAt = new Date(this.scheduleTemplate.term.startedAt);
      firstStartedAt.setHours(
        this.firstStartedAt.hour,
        this.firstStartedAt.minute
      );
      return firstStartedAt.getTime() - this.scheduleTemplate.preparatoryPeriod;
    },
    firstSaleEndedAtText(): string {
      return this.$moment(this.firstSaleEndedAt).format(
        'YYYY년 MM월 DD일 HH시'
      );
    },
    isValidSaleTerm(): boolean {
      return this.firstSaleEndedAt > new Date().getTime();
    },
    onlyQuotaLimit: {
      get(): boolean {
        return this.scheduleTemplate.minimumQuota === 0;
      },
      set(newValue: boolean) {
        if (newValue) {
          this.scheduleTemplate.minimumQuota = 0;
        }
      },
    },
  },
  watch: {
    today: {
      // 개별 일정인 경우 term의 startedAt기준으로 생성되고 startedAt의 초기값은 date이므로 today가 변경되면 term도 수정해줘야함
      handler(newValue: string) {
        this.scheduleTemplate.term.startedAt = new Date(newValue).getTime();
        this.scheduleTemplate.term.endedAt = new Date(newValue).getTime();
      },
    },
    period: {
      immediate: true,
      handler(newValue: number) {
        this.scheduleTemplate.preparatoryPeriod = newValue;
      },
    },
    'scheduleTemplate.repeatSchedule': {
      // 매일, 매주에서 반복 없음으로 변경되면 today도 수정되어야함
      handler(newValue: RepeatSchedule) {
        if (newValue === RepeatSchedule.ONCE) {
          this.today = this.$moment(
            this.scheduleTemplate.term.startedAt
          ).format('YYYY-MM-DD');
          this.scheduleTemplate.term.startedAt = this.todayTime;
          this.scheduleTemplate.term.endedAt = this.todayTime;
        } else {
          // 반복일정의 경우 default 3개월
          this.scheduleTemplate.term.startedAt = this.todayTime;
          this.scheduleTemplate.term.endedAt = this.saleTermEndedAt;
        }
      },
    },
  },
  created() {
    this.itemList.forEach(item => {
      item.checked = false;
    });
  },
  methods: {
    savePlan() {
      // TODO: 개선 필요
      this.scheduleTemplate.preparatoryPeriod = this.period;
      if (this.scheduleTemplate.repeatSchedule === RepeatSchedule.DAY) {
        this.scheduleTemplate.weeks = [
          DayOfWeek.SUNDAY,
          DayOfWeek.MONDAY,
          DayOfWeek.TUESDAY,
          DayOfWeek.WEDNESDAY,
          DayOfWeek.THURSDAY,
          DayOfWeek.FRIDAY,
          DayOfWeek.SATURDAY,
        ];
      }
      if (!this.scheduleTemplate.appliedAllItems) {
        if (this.selectedItems.length === 0) {
          this.$modal.show({
            html: `일정은 하나 이상의 옵션을 선택해야 합니다.`,
            type: 'danger',
          });
          return;
        }
        this.scheduleTemplate.targetItemParamIds = this.selectedItems.map(
          item => item.paramId
        );
      } else {
        this.scheduleTemplate.targetItemParamIds = [];
      }

      const isValid = this.validateForm();

      /**
       * 최소인원 모집 제한
       * 1. 신청 마감일과 프립 진행 시작일의 차이가 1일 미만일때
       * 2. 개별 예약 확정(수동 예약 확정)일때
       */
      const hasMinimumQuotaLimit =
        (this.scheduleTemplate.preparatoryPeriod < DAY_MILLISECOND &&
          this.inventoryTargetType === InventoryTargetType.BY_SCHEDULE) ||
        this.bookingConfirmationEnabled;

      if (
        isValid &&
        hasMinimumQuotaLimit &&
        this.scheduleTemplate.minimumQuota > 1
      ) {
        const invalidMessage = this.bookingConfirmationEnabled
          ? `개별 예약 확정을 하는 프립은 </br>최소 인원을 1명으로만 설정하실 수 있습니다.</br> 확인을 선택하시면 최소 인원이 1명으로 변경됩니다.`
          : `
          최소 모집 인원이 다 모이지 않을 경우 일정이 자동 취소됩니다.</br> 이를 방지하기 위해 최소 인원은 1명으로만 설정하실 수 있습니다. </br>확인을 선택하시면 최소 인원이 1명으로 변경됩니다.
        `;
        this.$modal.show(
          {
            html: invalidMessage,
            showCancelButton: true,
            type: 'primary',
          },
          () => {
            if (this.inventoryTargetType === InventoryTargetType.BY_SCHEDULE) {
              this.scheduleTemplate.minimumQuota = 1;
            } else {
              const itemParamIds = this.scheduleTemplate.targetItemParamIds;
              this.$emit('setItemQuota', itemParamIds);
            }
            this.$emit('addSchedule', this.scheduleTemplate);
          }
        );
      } else if (isValid) {
        this.$emit('addSchedule', this.scheduleTemplate);
      }
    },
    getFirstStartedAt(): number {
      let firstStartedAt = new Date(this.scheduleTemplate.term.startedAt);
      if (this.scheduleTemplate.repeatSchedule === RepeatSchedule.WEEK) {
        const weeks = this.scheduleTemplate.weeks.map(week =>
          getDayValue(week)
        );
        /**
         * 반복일정 중 첫번째로 시작하는 날을 찾는다.
         */
        while (weeks.length > 0 && !weeks.includes(firstStartedAt.getDay())) {
          // TODO: 개선필요
          firstStartedAt.setDate(firstStartedAt.getDate() + 1);
        }
      }
      firstStartedAt.setHours(this.scheduleTemplate.times[0].starting.hour);
      firstStartedAt.setMinutes(this.scheduleTemplate.times[0].starting.minute);
      return firstStartedAt.getTime();
    },
    isTodayFrip(firstStartedAt: number): boolean {
      const today = new Date();
      const fripStartedAt = new Date(firstStartedAt);

      if (
        today.getFullYear() === fripStartedAt.getFullYear() &&
        today.getMonth() === fripStartedAt.getMonth() &&
        today.getDate() === fripStartedAt.getDate()
      ) {
        return true;
      }
      return false;
    },
    validateForm(): boolean {
      // TODO: 개선 필요
      let isValid = true;

      // 가장 빨리 운영하는 시간을 찾는다.
      let firstStartedAt = this.getFirstStartedAt();

      if (this.isTodayFrip(firstStartedAt)) {
        this.$modal.show({
          html: '당일 일정은 추가할 수 없습니다.</br>운영 기간을 수정해주세요.',
        });
        isValid = false;
      } else if (firstStartedAt < new Date().getTime()) {
        this.$modal.show({
          message: '현재보다 이전의 일정은 추가할 수 없습니다.',
          type: 'warning',
        });
        isValid = false;
      } else if (this.scheduleTemplate.times.length === 0) {
        this.$modal.show({
          title: '운영 기간',
          message: '운영시간을 선택해주세요.',
          type: 'warning',
        });
        isValid = false;
      } else if (!this.isValidSaleTerm) {
        this.$modal.show({
          title: '일정 추가 불가',
          message: '판매 기간이 현재보다 이전이면 추가할 수 없습니다.',
          type: 'warning',
        });
        isValid = false;
      } else if (this.scheduleTemplate.preparatoryPeriod === 0) {
        this.$modal.show({
          title: '신청 기간',
          message: '신청 기간은 최소 1시간 이상으로 신청해주세요',
          type: 'warning',
        });
        isValid = false;
      } else if (
        !this.scheduleTemplate.appliedAllItems &&
        this.scheduleTemplate.targetItemParamIds.length === 0
      ) {
        this.$modal.show({
          title: '옵션 선택',
          message: '일정에 옵션을 추가해주세요.',
          type: 'warning',
        });
        isValid = false;
      } else if (this.scheduleTemplate.term.endedAt > this.saleTermEndedAt) {
        this.$modal.show({
          title: '일정 추가 불가',
          message: '3개월 이내의 일정만 추가 가능합니다.',
          type: 'warning',
        });
        isValid = false;
      } else {
        this.scheduleTemplate.times.forEach(time => {
          if (time.duration <= 0) {
            this.$modal.show({
              title: '운영 기간',
              message: '운영 종료 시간은 운영 시작 시간보다 빠를 수 없습니다.',
              type: 'warning',
            });
            isValid = false;
          }
        });
      }

      return isValid;
    },
    changeTimes(times: ScheduleTimeParam[]) {
      this.scheduleTemplate.times = times;
    },
    showItemSelectModal() {
      this.itemSelectModal = true;
    },
    closeItemSelectModal() {
      this.itemSelectModal = false;
    },
    applyItemsToPlan(items: ItemParam[]) {
      this.closeItemSelectModal();
    },
    closeScheduleTemplateForm() {
      this.$emit('close');
    },
  },
});
