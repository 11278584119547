














































































































































































import Spinner from '@/components/Spinner.vue';
// import ScheduleCard from '../../components/Schedule/SchduleCard.vue';
import { Table, TableColumn } from 'element-ui';
import Instant from '@/components/Labels/Instant.vue';
import ScheduleListDeletePopup from '../../components/Schedule/ScheduleListDeletePopup.vue';
import { SCHEDULES_BY_TERM } from '../../queries/schedule/query';
import { Calendar, calendarTools, YearMonth } from '@/common/util/CalendarUtil';
import { Schedule } from '../../model/product/response/schedule';
import { FixedTerm } from '@/common/model/FixedTerm';
import {
  ScheduleCancellationReason,
  ScheduleState,
} from '../../model/product/enum';
import { getScheduleStatusLabel } from '../../util/getScheduleStatusLabel';
import { getScheduleCancellationLabel } from '../../util/getScheduleCancellationLabel';
import { StatusLabel } from '@/common/model/statusLabel';
import { isHostAdmin } from '@/env';
import { ContainerMixin } from '@/common/mixin/containerMixin';
import { BOOKING_COUNTS_LIST_QUERY } from '@/domain/booking/graphql/query';
import { ApolloError } from 'apollo-client';
import { BookingCount } from '@/domain/booking/model/Ticket';
import ScheduleListPausePopup from '@/domain/product/components/Schedule/ScheduleListPausePopup.vue';

export default ContainerMixin.extend({
  name: 'ProductScheduleList',
  components: {
    Spinner,
    // ScheduleCard,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Instant,
    ScheduleListDeletePopup,
    ScheduleListPausePopup,
  },
  data(): {
    isAdmin: boolean;
    productId: string;
    loading: number;
    schedules: Record<string, Schedule[]>;
    scheduleList: Schedule[];
    bookingCountList: BookingCount[];
    selectedSchedules: Schedule[];
    size: number;
  } {
    return {
      isAdmin: !isHostAdmin(),
      productId: this.$route.params.id || '',
      loading: 0,
      schedules: {},
      scheduleList: [],
      bookingCountList: [],
      selectedSchedules: [],
      size: 10,
    };
  },
  computed: {
    schedulingTerm(): FixedTerm {
      const monthStartedAt = this.$moment()
        .set('year', this.yearMonth.year)
        .set('month', this.yearMonth.month - 1)
        .startOf('month')
        .startOf('day');

      const monthEndedAt = this.$moment()
        .set('year', this.yearMonth.year)
        .set('month', this.yearMonth.month - 1)
        .endOf('month')
        .endOf('day');

      return {
        startedAt: monthStartedAt.valueOf(),
        endedAt: monthEndedAt.valueOf(),
      };
    },
    calendar(): Calendar {
      return calendarTools.buildCalendar(this.yearMonth);
    },
    yearMonth(): YearMonth {
      return {
        year:
          Number(this.$route.query.year) ||
          calendarTools.getCurrentYearMonth().year,
        month:
          Number(this.$route.query.month) ||
          calendarTools.getCurrentYearMonth().month,
      };
    },
  },
  methods: {
    handleSelectionChange(items: Schedule[]): void {
      this.selectedSchedules = items;
    },
    nextMonth() {
      const nextYearMonth = calendarTools.getNextMonth(this.yearMonth);
      this.$router.push({
        query: {
          year: nextYearMonth.year.toString(),
          month: nextYearMonth.month.toString(),
        },
      });
    },
    prevMonth() {
      const previousYearMonth = calendarTools.getPrevMonth(this.yearMonth);
      this.$router.push({
        query: {
          year: previousYearMonth.year.toString(),
          month: previousYearMonth.month.toString(),
        },
      });
    },
    convertSchedules(schedules: Schedule[]): Record<string, Schedule[]> {
      const result = schedules.reduce((group, item) => {
        const key = this.$moment(new Date(item.term.startedAt)).format(
          'YYYY-MM-DD'
        );
        if (!group[key]) {
          group[key] = [];
        }
        group[key].push(item);
        return group;
      }, {} as Record<string, Schedule[]>);

      return result;
    },
    statusLabel(schedule: Schedule): StatusLabel {
      const label = getScheduleStatusLabel(schedule.status);

      const bookingCount = this.bookingCountList.find(
        value => value.scheduleId === schedule.id
      );
      const count = bookingCount ? bookingCount.count : 0;

      if (schedule.status === ScheduleState.PAUSED && count > 0) {
        label.extraText = '(예약내역 있음)';
      }

      return label;
    },
    cancellationLabel(reason: ScheduleCancellationReason): StatusLabel {
      return getScheduleCancellationLabel(reason);
    },
    onClickDelete() {
      if (this.selectedSchedules.length > 0) {
        const bookedSchedules = this.selectedSchedules.filter(schedule => {
          const bookingCount = this.bookingCountList.find(
            value => value.scheduleId == schedule.id
          );
          return (
            (bookingCount ? bookingCount.count : 0) > 0 ||
            ![ScheduleState.PAUSED, ScheduleState.EDITING].includes(
              schedule.status
            )
          );
        });

        if (bookedSchedules.length > 0) {
          const ids =
            bookedSchedules.length > 5
              ? bookedSchedules
                  .map(bookedSchedule => bookedSchedule.id)
                  .slice(0, 5)
                  .join() + '...'
              : bookedSchedules.map(bookedSchedule => bookedSchedule.id).join();

          this.showMessage(
            '일정 삭제 실패',
            '삭제불가능한 일정이 있습니다.\n(일정ID : ' + ids + ')',
            'warning'
          );
        } else {
          const popup = this.$refs.ScheduleDeletePopup as InstanceType<
            typeof ScheduleListDeletePopup
          >;
          popup?.open(this.selectedSchedules.map(schedule => schedule.id));
        }
      } else {
        this.showMessage('일정 삭제 실패', '일정을 선택해주세요.', 'warning');
      }
    },
    onClickPause() {
      if (this.selectedSchedules.length > 0) {
        const pausedSchedules = this.selectedSchedules.filter(
          schedule => schedule.status === ScheduleState.PAUSED
        );
        if (pausedSchedules.length > 0) {
          const ids =
            pausedSchedules.length > 5
              ? pausedSchedules
                  .map(pausedSchedule => pausedSchedule.id)
                  .slice(0, 5)
                  .join() + '...'
              : pausedSchedules.map(pausedSchedule => pausedSchedule.id).join();

          this.showMessage(
            '판매 중지 실패',
            '이미 판매 중지된 일정이 있습니다.(일정ID : ' + ids + ')',
            'warning'
          );
        } else {
          const popup = this.$refs.SchedulePausePopup as InstanceType<
            typeof ScheduleListPausePopup
          >;
          popup?.open(this.selectedSchedules.map(schedule => schedule.id));
        }
      } else {
        this.showMessage('판매 중지 실패', '일정을 선택해주세요.', 'warning');
      }
    },
    refetch() {
      this.$apollo.queries.schedules.refetch();
      this.$apollo.queries.bookingCountList.refetch();
    },
  },
  apollo: {
    schedules: {
      query: SCHEDULES_BY_TERM,
      variables(): {
        productId: string;
        schedulingTerm: FixedTerm;
      } {
        return {
          productId: this.productId,
          schedulingTerm: this.schedulingTerm,
        };
      },
      update(data): Record<string, Schedule[]> {
        const schedules = data.product.schedulesByTerm;
        return this.convertSchedules(schedules);
      },
      result(data) {
        this.scheduleList = data.data.product.schedulesByTerm;
      },
    },
    bookingCountList: {
      query: BOOKING_COUNTS_LIST_QUERY,
      variables(): {
        scheduleIds: string[];
      } {
        const scheduleIds = this.scheduleList.map(schedule => schedule.id);
        return {
          scheduleIds: scheduleIds,
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      update: data => data.booking.bookingCountsByScheduleIds,
    },
  },
});
