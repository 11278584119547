import { ProductAction, ProductState } from '../model/product/enum';

export const getEnableActions = (status?: ProductState): ProductAction[] => {
  switch (status) {
    case ProductState.EDITING:
      return [ProductAction.SAVE, ProductAction.INSPECT, ProductAction.DELETE];
    case ProductState.INSPECTING:
      return [ProductAction.SAVE, ProductAction.RETRACT, ProductAction.DELETE]; // 검수 상태에도 수정 가능 // 검수 상태에도 삭제 가능
    case ProductState.REJECTED:
      return [
        ProductAction.REEDIT,
        ProductAction.SAVE,
        ProductAction.INSPECT,
        ProductAction.DELETE,
      ]; // 검수 반려 상태에도 삭제 가능
    case ProductState.READY:
      return [ProductAction.OPEN];
    case ProductState.SALE:
      return [
        ProductAction.SAVE,
        ProductAction.SOLD_OUT,
        ProductAction.PAUSE,
        ProductAction.CLOSE,
        ProductAction.SUSPEND,
        // ProductAction.INSPECT,
        // ProductAction.RETRACT,
      ];
    case ProductState.SOLD_OUT:
      return [
        ProductAction.SAVE,
        ProductAction.OPEN,
        ProductAction.CLOSE,
        ProductAction.SUSPEND,
        // ProductAction.INSPECT,
        // ProductAction.RETRACT,
      ];
    case ProductState.PAUSED:
      return [
        ProductAction.SAVE,
        ProductAction.OPEN,
        ProductAction.CLOSE,
        ProductAction.SUSPEND,
        // ProductAction.INSPECT,
        // ProductAction.RETRACT,
      ];
    case ProductState.SUSPENDED:
      return [ProductAction.SAVE, ProductAction.RESUME, ProductAction.CLOSE];
    case ProductState.CLOSED:
      return [ProductAction.REEDIT, ProductAction.TERMINATE];
    case ProductState.TERMINATED:
      return [];
    default:
      return [ProductAction.SAVE, ProductAction.INSPECT];
  }
};
