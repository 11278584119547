import { MutationService } from '@/common/service/MutationService';
import { MerchandiserParam } from '../model/merchandiser';
import {
  CREATE_MERCHANDISER,
  DELETE_MERCHANDISER,
  UPDATE_MERCHANDISER,
} from '../queries/merchandiser/mutation';

export class MerchandiserService extends MutationService {
  public async createMerchandiser(param: MerchandiserParam): Promise<void> {
    await this.apollo.mutate({
      mutation: CREATE_MERCHANDISER,
      variables: {
        param: param,
      },
    });
  }
  public async updateMerchandiser(
    id: string,
    param: MerchandiserParam
  ): Promise<void> {
    await this.apollo.mutate({
      mutation: UPDATE_MERCHANDISER,
      variables: {
        id: id,
        param: param,
      },
    });
  }
  public async deleteMerchandiser(id: string): Promise<void> {
    await this.apollo.mutate({
      mutation: DELETE_MERCHANDISER,
      variables: {
        id: id,
      },
    });
  }
}
