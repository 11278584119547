






































import Vue from 'vue';
import { LocationKind } from '../../model/product/enum';

export default Vue.extend({
  props: {
    hodmin: {
      type: Boolean,
    },
    location: {
      type: Object,
    },
    kind: {
      type: String,
      default: 'VENUE',
    },
  },
  data() {
    return {
      tdStyle: 'width: 30%',
    };
  },
  computed: {
    showDraggable(): boolean {
      return this.kind === LocationKind.VENUE;
    },
  },
  methods: {
    removeRow(): void {
      this.$emit('removeRow', this.location.locationId);
    },
  },
});
