














































import Vue from 'vue';
import gql from 'graphql-tag';
import FripTable from '@/components/FripComponents/FripTable.vue';
import DisplayCategorySearch from './DisplayCategorySearch.vue';
import { DisplayCategory } from '@/domain/category/display/model/DisplayCategory';
import { DISPLAYS } from './queries/query';
import { Edge } from '@frientrip/domain';
import { ApolloQueryResult } from 'apollo-client';
import { isHostAdmin } from '@/env';

interface CategoryRow {
  id: string;
  name?: string;
  paths: string[];
  checked: boolean;
  representative: boolean;
}

interface DisplayCategoryParam {
  displayCategoryId: string;
  representative: boolean;
}

interface CategoryQueryResult<T> {
  category: T;
}

interface DisplayCategoryResult {
  displays: { edges: Edge<DisplayCategory>[] };
}

interface StandardCategoryResult {
  standard: { relatedDisplays: DisplayCategory[] };
}

const STANDARD_CATEGORY = gql`
  query standardCategory($id: ID!) {
    category {
      standard(id: $id) {
        id
        label {
          id
          name
        }
        relatedDisplays {
          id
          label {
            id
            name
          }
          parent {
            id
            label {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const LEAF_STANDARD_CATEGORY_ID_LENGTH = 20;
const MAX_DISPLAY_CATEGORY_LENGTH = 10;

export default Vue.extend({
  name: 'DisplayCategorySelect',
  components: {
    FripTable,
    DisplayCategorySearch,
  },
  props: {
    standardCategoryId: {
      type: String,
    },
    value: {
      type: Array,
    },
    isUpdate: {
      type: Boolean,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedCategoryParams: this.value as DisplayCategoryParam[],
      selectedCategories: [] as CategoryRow[],
      selectedCategoriesColumn: this.readOnly
        ? [
            { text: '대표', value: 'representative' },
            { text: '1차', value: '1' },
            { text: '2차', value: '2' },
            { text: '3차', value: '3' },
            { text: '4차', value: '4' },
          ]
        : [
            { text: '대표', value: 'representative' },
            { text: '1차', value: '1' },
            { text: '2차', value: '2' },
            { text: '3차', value: '3' },
            { text: '4차', value: '4' },
            { text: '삭제', value: 'delete' },
          ],
    };
  },
  computed: {
    categoryParams(): DisplayCategoryParam[] {
      return this.selectedCategories.map(category => {
        return {
          displayCategoryId: category.id,
          representative: category.representative,
        };
      });
    },
    selectedCategoryIds(): string[] {
      return this.categoryParams.map(category => category.displayCategoryId);
    },
    checkedSelectedCategoryIds(): string[] {
      return this.selectedCategories
        .filter(category => category.checked)
        .map(category => category.id);
    },
  },

  watch: {
    standardCategoryId: {
      handler: async function (standardCategoryId: string) {
        const isLeafCategory =
          standardCategoryId.length === LEAF_STANDARD_CATEGORY_ID_LENGTH;

        if (isLeafCategory) {
          this.selectedCategories =
            await this.fetchDisplayCategoriesByStandardCategoryIds(
              standardCategoryId
            );

          this.saveDisplayCategory();
        }
      },
    },
  },
  async created() {
    if (this.isUpdate && !isHostAdmin()) {
      const displayCategoryIds = this.selectedCategoryParams.map(
        categoryParam => categoryParam.displayCategoryId
      );
      this.selectedCategories = await this.fetchDisplayCategoriesByIds(
        displayCategoryIds
      );

      this.saveDisplayCategory();
    }
  },
  methods: {
    saveDisplayCategory() {
      this.$emit('select', this.categoryParams);
    },
    addSearchResult(category: CategoryRow) {
      if (!this.selectedCategoryIds.includes(category.id)) {
        if (this.selectedCategoryIds.length >= MAX_DISPLAY_CATEGORY_LENGTH) {
          this.$modal.show({
            title: '',
            message: `최대 ${MAX_DISPLAY_CATEGORY_LENGTH}개까지 설정할 수 있습니다.`,
            showCancelButton: true,
          });
          return;
        }

        this.selectedCategories.push({
          ...category,
          checked: false,
          representative: false,
        });
      }
      this.saveDisplayCategory();
    },
    setRepresentativeCategory(id: string) {
      this.selectedCategories.forEach(category => {
        if (category.id === id) {
          category.representative = true;
        } else {
          category.representative = false;
        }
      });
      this.saveDisplayCategory();
    },
    deleteDisplayCategory(id: string) {
      this.$modal.show(
        {
          title: '전시 카테고리를 삭제하시겠습니까?',
          message: '전시 카테고리를 삭제합니다.',
          showCancelButton: true,
        },
        () => {
          const index = this.selectedCategories.findIndex(
            category => category.id === id
          );
          this.selectedCategories.splice(index, 1);
          this.saveDisplayCategory();
        }
      );
    },
    async fetchDisplayCategoriesByIds(ids: string[]) {
      if (ids.length === 0) return [];
      const result: ApolloQueryResult<
        CategoryQueryResult<DisplayCategoryResult>
      > = await this.$apollo.query({
        query: DISPLAYS,
        variables: {
          filter: {
            ids,
          },
        },
      });

      const categories = result.data.category.displays.edges.map(
        (edge: Edge<DisplayCategory>): CategoryRow => {
          let paths = [];
          let category: DisplayCategory | null = edge?.node || null;

          while (category) {
            paths.unshift(category.label.name);
            category = category.parent || null;
          }
          const index = this.selectedCategoryParams.findIndex(
            category => category.displayCategoryId === edge.node.id
          );
          const isRepresentative =
            index >= 0
              ? this.selectedCategoryParams[index].representative
              : false;
          return {
            id: edge.node.id,
            paths: paths,
            checked: false,
            representative: isRepresentative,
          };
        }
      );

      return categories;
    },
    async fetchDisplayCategoriesByStandardCategoryIds(
      standardCategoryId: string
    ) {
      const result: ApolloQueryResult<
        CategoryQueryResult<StandardCategoryResult>
      > = await this.$apollo.query({
        query: STANDARD_CATEGORY,
        variables: {
          id: standardCategoryId,
        },
      });
      const categories = result.data.category.standard.relatedDisplays.map(
        (displayCategory: DisplayCategory): CategoryRow => {
          let paths = [];
          let category: DisplayCategory | null = displayCategory || null;

          while (category) {
            paths.unshift(category.label.name);
            category = category.parent || null;
          }
          const selected: DisplayCategoryParam[] =
            this.selectedCategoryParams.filter(
              category => category.displayCategoryId === displayCategory.id
            );

          return {
            id: displayCategory.id,
            paths: paths,
            checked: selected.length > 0,
            representative:
              selected.length > 0 ? selected[0].representative : false,
          };
        }
      );
      return categories;
    },
  },
});
