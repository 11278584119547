import { Host } from '@/common/Host';
import { CommonState } from '@frientrip/domain';

// 추가 정보 템플릿
export interface InquiryTemplate {
  host: Host;
  id: string;
  inquries: InquiryTemplateItem[];
  name: string;
  status: CommonState;
}

export interface InquiryTemplateItem {
  id?: string | number;
  inputKind: ItemInquiryInputKind;
  inputPattern: ItemInquiryInputPattern;
  items: InquiryTemplateChoosingItem[];
  required: boolean;
  status?: CommonState;
  title: string;
}

export interface InquiryTemplateChoosingItem {
  id?: string | number;
  label: string;
}

export enum ItemInquiryInputKind {
  MULTI_CHOICE = 'MULTI_CHOICE', // 다중 선택
  SINGLE_CHOICE = 'SINGLE_CHOICE', // 단일 선택
  TEXT = 'TEXT', // 문자열 입력
}

export enum ItemInquiryInputPattern {
  PER_ITEM = 'PER_ITEM', // 구매 아이템별
  PER_ITEM_COUNT = 'PER_ITEM_COUNT', // 구매 아이템 수량별
  PER_PRODUCT = 'PER_PRODUCT', // 구매 상품별
}
