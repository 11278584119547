import { MutationService } from '@/common/service/MutationService';
import { OPEN_SCHEDULE, PAUSE_SCHEDULE } from '../queries/schedule/mutation';

export class ProductScheduleStatusChangeService extends MutationService {
  public async pauseSchedule(id: string): Promise<void> {
    await this.apollo.mutate({
      mutation: PAUSE_SCHEDULE,
      variables: {
        id: id,
      },
    });
  }
  public async openSchedule(id: string): Promise<void> {
    await this.apollo.mutate({
      mutation: OPEN_SCHEDULE,
      variables: {
        id: id,
      },
    });
  }
}
