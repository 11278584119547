





















































import Vue from 'vue';

export default Vue.extend({
  name: 'RangeInput',
  props: {
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    question: {
      type: String,
    },
    minPlaceholder: {
      type: String,
      default: '최소',
    },
    maxPlaceholder: {
      type: String,
      default: '최대',
    },
    labelColsLg: {
      type: String,
      default: '2',
    },
    contentColsLg: {
      type: String,
      default: '6',
    },
    invalidMessage: {
      type: String,
      default: '최소는 최대보다 작아야합니다.',
    },
    block: {
      type: Boolean,
      default: false,
    },
    minimum: {
      type: [Number, String],
      required: true,
    },
    maximum: {
      type: [Number, String],
      required: true,
    },
    appendText: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledMinimum: {
      type: Boolean,
      default: false,
    },
    disabledMaximum: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        minimum: this.minimum,
        maximum: this.maximum,
      },
    };
  },

  computed: {
    labelCols(): string {
      if (this.label) {
        return this.labelColsLg;
      } else {
        return '';
      }
    },
    paddingClass(): string {
      if (this.block) {
        return 'py-1 px-3';
      } else {
        return 'py-1';
      }
    },
    invalid(): boolean {
      return this.form.minimum > this.form.maximum;
    },
  },
  watch: {
    minimum: {
      immediate: true,
      handler(newValue) {
        this.form.minimum = newValue;
      },
    },
    maximum: {
      immediate: true,
      handler(newValue) {
        this.form.maximum = newValue;
      },
    },
  },
  methods: {
    onInput() {
      this.$emit('update:minimum', this.form.minimum);
      this.$emit('update:maximum', this.form.maximum);
    },
  },
});
