import gql from 'graphql-tag';
import { RESULT_FRAGMENT } from '@/common/fragments/resultFragment';

const MERCHANDISER_FRAGMENT = gql`
  fragment merchandiserField on Merchandiser {
    id
    name
    departmentName
    staffNo
    user {
      id
      nickname
    }
  }
`;
export const CREATE_MERCHANDISER = gql`
  mutation createMerchandiser($param: MerchandiserParamInput!) {
    createMerchandiser(param: $param) {
      ...merchandiserField
    }
  }
  ${MERCHANDISER_FRAGMENT}
`;

export const UPDATE_MERCHANDISER = gql`
  mutation updateMerchandiser($id: ID!, $param: MerchandiserParamInput!) {
    updateMerchandiser(id: $id, param: $param) {
      ...merchandiserField
    }
  }
  ${MERCHANDISER_FRAGMENT}
`;

export const DELETE_MERCHANDISER = gql`
  mutation deleteMerchandiser($id: ID!) {
    deleteMerchandiser(id: $id) {
      ...resultField
    }
  }
  ${RESULT_FRAGMENT}
`;
