















import Vue from 'vue';

interface Option {
  name: string;
  value: string;
}

export default Vue.extend({
  model: {
    prop: 'selectedOptions',
    event: 'input',
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    selectedOptions: {
      type: Array,
    },
  },
  methods: {
    clickOption(option: Option): void {
      const index = this.selectedOptions.findIndex(
        selectedOption => selectedOption === option.value
      );
      if (index >= 0) {
        this.selectedOptions.splice(index, 1);
      } else {
        this.selectedOptions.push(option.value);
      }
      this.$emit('input', this.selectedOptions);
    },
    optionStyle(option: Option): string {
      const index = this.selectedOptions.findIndex(
        selectedOption => selectedOption === option.value
      );
      if (index >= 0) {
        return 'selected';
      } else {
        return '';
      }
    },
  },
});
