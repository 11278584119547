















import Vue from 'vue';
import { ProductAction } from '@/domain/product/model/product/enum';
import { isHostAdmin } from '@/env';
import { ProductState } from '@/domain/host/models/host';

interface ProductActionButton {
  action: ProductAction;
  text: string;
  buttonType: string;
  outline: boolean;
  isAdmin: boolean;
  isHostAdmin: boolean;
}

export default Vue.extend({
  name: 'ProductFooterActionButton',
  props: {
    actions: {
      type: Array,
      required: true,
    },
    saveLoading: {
      type: Boolean,
    },
    status: {
      type: String,
    },
  },
  data(): { isAdmin: boolean; productActions: ProductActionButton[] } {
    return {
      isAdmin: !isHostAdmin(),
      productActions: [
        {
          action: ProductAction.OPEN,
          text: '상품 오픈',
          buttonType: 'primary',
          outline: true,
          isAdmin: false,
          isHostAdmin: true,
        },
        {
          action: ProductAction.DELETE,
          text: '상품 삭제',
          buttonType: 'danger',
          outline: true,
          isAdmin: false,
          isHostAdmin: true,
        },
        {
          action: ProductAction.PAUSE,
          text: '일시중지',
          buttonType: 'primary',
          outline: true,
          isAdmin: false,
          isHostAdmin: true,
        },
        {
          action: ProductAction.REEDIT,
          text: '재수정',
          buttonType: 'primary',
          outline: true,
          isAdmin: true,
          isHostAdmin: false,
        },
        {
          action: ProductAction.TERMINATE,
          text: '운영종료',
          buttonType: 'danger',
          outline: false,
          isAdmin: true,
          isHostAdmin: false,
        },
        {
          action: ProductAction.SUSPEND,
          text: '판매중지',
          buttonType: 'warning',
          outline: true,
          isAdmin: true,
          isHostAdmin: false,
        },
        {
          action: ProductAction.RESUME,
          text: '판매재개',
          buttonType: 'primary',
          outline: true,
          isAdmin: true,
          isHostAdmin: false,
        },
        {
          action: ProductAction.CLOSE,
          text: '판매종료',
          buttonType: 'danger',
          outline: true,
          isAdmin: true,
          isHostAdmin: false,
        },
        {
          action: ProductAction.SAVE,
          text: '저장',
          buttonType: 'default',
          outline: true,
          isAdmin: false,
          isHostAdmin: true,
        },
      ],
    };
  },
  computed: {
    enableActions(): ProductActionButton[] {
      const enableActions = this.productActions.filter(action =>
        this.actions.includes(action.action)
      );
      if (this.isAdmin) {
        return enableActions;
      } else if (this.status === ProductState.INSPECTING && isHostAdmin()) {
        // 검수중이고, 호스트이면 빈값.
        return [];
      } else if (this.status === ProductState.REJECTED && isHostAdmin()) {
        // 검수반려이고 호스트면 호스트 표시할 것에서 삭제를 제거.
        return enableActions
          .filter(action => action.isHostAdmin)
          .filter(action => action.action != ProductAction.DELETE);
      } else if (this.status === ProductState.EDITING && !isHostAdmin()) {
        return enableActions.filter(action => !action.isAdmin); // Admin이 아닐때 표시할 것을 표시. (기존 방식)
      } else {
        return enableActions.filter(action => action.isHostAdmin); //hostAdmin에서 보여야 하는 것이 true일때 표시 (추가함)
      }
    },
  },
  methods: {
    changeProductStatus(action: ProductActionButton) {
      this.$emit('click', action);
    },
  },
});
