import gql from 'graphql-tag';
import { TERM_FRAGMENT } from '@/common/fragments/termFragment';
import { FRIP_LOCATION_FRAGMENT } from '../fragments/locationFragment';
import { ITEM_FRAGMENT } from '../fragments/itemFragment';
import { CURRICULUM_FRAGMENT } from '../fragments/curriculumFragment';
import { PLAN_FRAGMENT } from '../fragments/planFragment';
import { REFUND_POLICY_FRAGMENT } from '../fragments/refundPolicyFragment';
import { INSPECTION_FRAGMENT } from '../fragments/inspectionFragment';

export const PRODUCTS = gql`
  query products($filter: ProductFilterInput, $page: Int, $size: Int) {
    product {
      products(filter: $filter, page: $page, size: $size) {
        edges {
          node {
            id
            title
            frip {
              kind
            }
            host {
              id
              name
            }
            manager {
              id
              name
            }
            standardCategories {
              id
              label {
                id
                name
              }
            }
            exposures {
              id
              description
              name
              status
            }
            headerContents {
              id
              style
              content {
                id
                thumbnail(width: 100, height: 100)
              }
            }
            lastInspection {
              id
              status
            }
            saleTerm {
              duration
              startedAt
              endedAt
            }
            lastInspection {
              id
              status
            }
            status
          }
        }
        totalCount
      }
    }
  }
`;

export const PRODUCT = gql`
  query product($id: ID!) {
    product {
      product(id: $id) {
        id
        title
        catchphrase
        kind
        status
        saleTerm {
          ...termField
        }
        firstOpenedAt
        underageRestricted
        unverifiedUserRestricted
        cancelingRestricted
        host {
          id
          name
          super
          commission {
            default {
              bias
              charge
              type
            }
            absent {
              bias
              charge
              type
            }
          }
        }
        manager {
          id
          name
        }
        attributes {
          id
          name
        }
        standardCategories {
          id
          label {
            id
            name
          }
        }
        categories {
          displayCategory {
            id
            label {
              id
              name
            }
          }
          representative
        }
        headerContents {
          id
          content {
            id
            uri
          }
        }
        contents {
          id
          content
        }
        options {
          id
          title
          names
        }
        items {
          ...itemField
        }
        externalProvisions {
          id
        }
        grossPurchaseLimitation {
          count
        }
        lastInspection {
          ...insepctionField
        }
        maximumPurchasableCount
        tags(includeInactive: true) {
          id
          kind
        }
        inquiryTemplate {
          id
        }
        inventoryTargetOptionLevel
        inventoryTargetType
        frip {
          kind
          interactionType
          companions
          attachedToSchedule
          bookingConfirmationEnabled
          difficulty
          isIndoor
          isOutdoor
          locationsOfVenue {
            ...fripLocationField
          }
          locationOfGathering {
            ...fripLocationField
          }
          plans {
            ...planField
          }
          curriculum {
            ...curriculumField
          }
          exclusions
          inclusions
          stuffsToPrepare
          daysOfExpiration
          recommendedAge
        }
        keywords
        refundPolicy {
          ...refundPolicyField
        }
        exposures {
          id
        }
        notice
      }
    }
  }
  ${TERM_FRAGMENT}
  ${ITEM_FRAGMENT}
  ${FRIP_LOCATION_FRAGMENT}
  ${CURRICULUM_FRAGMENT}
  ${PLAN_FRAGMENT}
  ${REFUND_POLICY_FRAGMENT}
  ${INSPECTION_FRAGMENT}
`;

export const PRODUCT_KINDS = gql`
  query kinds {
    product {
      kinds {
        label
        value
        description
        standardCategory {
          id
        }
      }
    }
  }
`;
export const PRODUCT_ATTRIBUTES = gql`
  query attributes($kind: ProductKind!) {
    product {
      attributes(kind: $kind) {
        id
        name
        description
        status
        kind
      }
    }
  }
`;

export const PRODUCT_EXPOSED_CHANNELS = gql`
  query exposedChannels {
    product {
      exposedChannels {
        id
        name
      }
    }
  }
`;

export const SCHEDULES_BY_TERM = gql`
  query productSchedulesByTerm(
    $productId: ID!
    $schedulingTerm: FixedTermInput!
  ) {
    product {
      schedulesByTerm(productId: $productId, schedulingTerm: $schedulingTerm) {
        id
        appliedAllItems
        items {
          id
          name
        }
        plan {
          id
        }
        term {
          startedAt
          endedAt
          duration
        }
        saleTerm {
          startedAt
          endedAt
        }
        minimumQuota
        quota
        maximumPurchasableCount
        title
        status
      }
    }
  }
`;

export const PRODUCT_HOST = gql`
  query productHost($id: ID!) {
    host(id: $id) {
      id
      name
      commission {
        default {
          bias
          charge
          type
        }
        absent {
          bias
          charge
          type
        }
      }
    }
  }
`;
