




























































































































































































































































































































































import ProductBasicInfoForm from '../../components/Product/Form/ProductBasicInfoForm.vue';
import ProductSaleInfoForm from '../../components/Product/Form/ProductSaleInfoForm.vue';
import ProductAdditionalInfoForm from '../../components/Product/Form/ProductAdditionalInfoForm.vue';
import ProductDescriptionForm from '../../components/Product/Form/ProductDescriptionForm.vue';
import ProductTranscationInfoForm from '../../components/Product/Form/ProductTransactionInfoForm.vue';
import ProductOtherInfoForm from '../../components/Product/Form/ProductOtherInfoForm.vue';
import ProductFooter from '../../components/Product/Footer/ProductFooter.vue';
import ProductFooterActionButton from '../../components/Product/Footer/ProductFooterActionButton.vue';
import Notice from '../../components/Guide/Notice.vue';
import DefaultInfo from '../../components/Guide/DefaultInfo.vue';
import SellingInfo from '../../components/Guide/SellingInfo.vue';
import ScheduleInfo from '../../components/Guide/ScheduleInfo.vue';
import PaymentInfo from '../../components/Guide/PaymentInfo.vue';
import AdditionalInfo from '../../components/Guide/AdditionalInfo.vue';
import Description from '../../components/Guide/Description.vue';
import InspectionPopup from '@/domain/inspection/components/InspectionPopup.vue';
import Spinner from '@/components/Spinner.vue';
import LabelText from '@/components/Labels/LabelText.vue';
import { ProductSaveService } from '../../service/ProductSaveService';
import { ProductConvertService } from '../../service/ProductConvertService';
import { ProductParamValidateService } from '../../service/ProductParamValidateService';
import { ProductCopyService } from '../../service/ProductCopyService';
import { ProductStatusChangeService } from '../../service/ProductStatusChangeService';
import { apolloClient } from '@/apolloClient';
import { ProductParam } from '../../model/product/param/productParam';
import {
  PRODUCT,
  PRODUCT_HOST,
  SCHEDULES_BY_TERM,
} from '../../queries/product/query';
import { ApolloQueryResult } from 'apollo-client';
import {
  Host,
  Inspection,
  Product,
} from '../../model/product/response/product';
import { getInspectionStatusLabel } from '../../util/getInspectionStatusLabel';
import { getProductStatusLabel } from '../../util/getProductStatusLabel';
import { getEnableActions } from '../../util/getEnableActions';
import { ProductFormConvertService } from '../../service/ProductFormConvertService';
import { FixedTerm } from '@/common/model/FixedTerm';
import { ScheduleParam } from '../../model/product/param/productSaleInfoParam';
import {
  FripKind,
  ProductAction,
  ProductKind,
  ProductState,
  SalesType,
} from '../../model/product/enum';
import {
  InspectionPart,
  InspectionState,
} from '@/domain/inspection/model/enum';
import { getConstants, isHostAdmin } from '@/env';
import { RefundPolicyParam } from '../../model/product/param/productTransactionParam';
import { Schedule } from '../../model/product/response/schedule';
import { ContainerMixin } from '@/common/mixin/containerMixin';
import { CommissionType } from '@/domain/host/models/hostAccount';
import { ModalOption } from '@/frip-alert-modal';
import { PRODUCT_TAB_ENUM } from '@/common/ApiErrorParser';
import { TalkPlusService } from '@/domain/product/service/TalkPlusService';

const talkPlusService: TalkPlusService = new TalkPlusService(apolloClient);

const productFormConvertService = new ProductFormConvertService();

const productSaveService = new ProductSaveService(
  apolloClient,
  productFormConvertService
);

const productConvertService = new ProductConvertService();

const productParamValidateService = new ProductParamValidateService();

const productCopyService = new ProductCopyService();

const productStatusChangeService = new ProductStatusChangeService(apolloClient);

interface productQueryParam {
  id: string;
}

interface scheduleQueryParam {
  productId: string;
  schedulingTerm?: FixedTerm;
}

interface ProductSaveFormData {
  id: string;
  isAdmin: boolean;
  host: Host;
  manager: {
    id: string;
    name: string;
  };
  form: ProductParam;
  isBasicInfoValid: boolean;
  schedules: ScheduleParam[];
  refundPolicyParam: RefundPolicyParam;
  inspection: Inspection;
  firstOpenedAt: number;
  loading: number;
  productLoading: number;
  scheduleLoading: number;
  saveLoading: boolean;
  requestInspectionLoading: boolean;
  cancelInspectionLoading: boolean;
  tabIndex: number;
  level: number;
  inspectionLevel: number;
  inspectionErrorTabs: typeof PRODUCT_TAB_ENUM[];
  inviteUserId: string;
  removeUserId: string;
}

export default ContainerMixin.extend({
  name: 'ProductCreateContainer',
  components: {
    ProductBasicInfoForm,
    ProductSaleInfoForm,
    ProductAdditionalInfoForm,
    ProductDescriptionForm,
    ProductTranscationInfoForm,
    ProductOtherInfoForm,
    ProductFooter,
    ProductFooterActionButton,
    Spinner,
    InspectionPopup,
    LabelText,
    Notice,
    DefaultInfo,
    SellingInfo,
    ScheduleInfo,
    PaymentInfo,
    AdditionalInfo,
    Description,
  },
  data(): ProductSaveFormData {
    return {
      id: this.$route.params.id || '',
      isAdmin: !isHostAdmin(),
      host: {
        id: this.$store.state.userToken.hostId || '',
        name: '',
        commission: {
          salesType: SalesType.AGENCY,
          default: {
            type: CommissionType.RATIO,
            charge: 19.8,
            bias: 0,
          },
          absent: {
            type: CommissionType.RATIO,
            charge: 19.8,
            bias: 0,
          },
        },
      },
      manager: {
        id: '',
        name: '',
      },
      form: productSaveService.getProductInitData(),
      isBasicInfoValid: !!this.$route.params.id, // 수정화면인 경우에는 탭 이동 막지않음
      schedules: [],
      refundPolicyParam: {
        refundPolicyId: '',
        extraNotice: '',
        description: '',
      },
      inspection: {
        id: '',
        items: [],
      },
      firstOpenedAt: 0,
      loading: 0,
      productLoading: 0,
      scheduleLoading: 0,
      saveLoading: false,
      requestInspectionLoading: false,
      cancelInspectionLoading: false,
      tabIndex: this.$route.query.tab ? Number(this.$route.query.tab) : 0,
      level: isHostAdmin() ? 4 : 5, // 탭의 마지막 인덱스, 호드민과 어드민은 다른데 임시로 어드민으로 설정
      inspectionLevel: isHostAdmin() ? 4 : 5, // 검수 요청을 할 수 있는 탭의 인덱스, 변경될 수 있음
      inspectionErrorTabs: [],
      inviteUserId: '',
      removeUserId: '',
    };
  },
  computed: {
    isMeetUp(): boolean {
      return this.form.frip?.kind === FripKind.MEETUP;
    },
    isUpdate(): boolean {
      return this.id !== '' && this.$route.path !== '/product/create';
    },
    canEdit(): boolean {
      const editableStatus = [ProductState.EDITING, ProductState.REJECTED];
      return this.form.status
        ? editableStatus.includes(this.form.status)
        : true;
    },
    isCopy(): boolean {
      return this.$route.query.sourceProductId
        ? this.$route.query.sourceProductId !== ''
        : false;
    },
    showInspectionButton(): boolean {
      if (this.form.status) {
        return (
          this.inspection &&
          this.inspection.status === InspectionState.APPLIED &&
          this.isAdmin
        );
      } else {
        return false;
      }
    },
    formTitle(): string {
      return this.isUpdate ? '' : '프립 등록';
    },
    renderForm(): boolean {
      if (!this.isUpdate && !this.isCopy) return true;
      else if (this.productLoading === 0) {
        if (this.isUpdate && this.form.id) {
          return true;
        } else {
          return this.isCopy && this.form.kind !== ProductKind.NONE;
        }
      } else {
        return false;
      }
    },
    productStatus(): string {
      return this.form.status ? getProductStatusLabel(this.form.status) : '';
    },
    productStatusChangeButtonLabel(): string {
      if (
        this.form.status === ProductState.READY ||
        this.form.status === ProductState.PAUSED
      )
        return '판매 시작';
      else if (this.form.status === ProductState.CLOSED) return '판매 재시작';
      else if (this.form.status === ProductState.SALE) return '판매 일시 중지';
      return '';
    },
    enableActions(): ProductAction[] {
      return getEnableActions(this.form.status);
    },
    productCloseOrTerminateButtonLabel(): string {
      if (this.form.status === ProductState.PAUSED) {
        return '일시 중지';
      } else if (this.form.status === ProductState.CLOSED) {
        return '운영 종료';
      } else if (this.form.status === ProductState.SUSPENDED) {
        return '판매 중지';
      } else {
        return '';
      }
    },
    showRequestInspection(): boolean {
      return (
        this.tabIndex === this.inspectionLevel &&
        this.enableActions.includes(ProductAction.INSPECT)
      );
    },
    showCancelInspection(): boolean {
      return (
        this.tabIndex === this.inspectionLevel &&
        this.form.status === ProductState.INSPECTING
      );
    },
    inspectionStateLabel(): string {
      const inspectStatus = [
        ProductState.APPLIED,
        ProductState.INSPECTING,
        ProductState.REJECTED,
      ];
      if (this.form.status && !inspectStatus.includes(this.form.status)) {
        return '-';
      }

      // 검수 상태 라벨
      return this.inspection.status && this.id
        ? getInspectionStatusLabel(this.inspection.status)
        : '검수 미신청';
    },
    inspectionStateLabelColor(): string {
      if (this.inspection.status) {
        return this.inspection.status === InspectionState.REJECTED
          ? 'text-frip-danger'
          : 'text-frip-primary';
      }
      return 'text-muted';
    },
    isFrip(): boolean {
      return this.form.kind !== ProductKind.GOODS;
    },
    isHaveFreeOption(): boolean {
      return this.form.items.filter(item => item.price.sale === 0).length > 0;
    },
    TabEnum(): typeof PRODUCT_TAB_ENUM {
      return PRODUCT_TAB_ENUM;
    },
    requestInspectionButtonTooltip(): string {
      return this.isUpdate ? '' : '좌측 저장버튼 클릭 후 요청해주세요';
    },
    isSale(): boolean {
      const saleStatus = [
        ProductState.SALE,
        ProductState.SOLD_OUT,
        ProductState.PAUSED,
      ];
      return this.form.status ? saleStatus.includes(this.form.status) : false;
    },
  },
  methods: {
    isHostAdmin,
    selectHost(hostId: string) {
      this.host.id = hostId;
    },
    saveForm(form: ProductParam) {
      Object.assign(this.form, form);
    },
    async changeProductStatus(action: ProductAction) {
      console.log('current ProductAction :: ' + action);
      switch (action) {
        case ProductAction.OPEN:
          await this.openProduct();
          break;
        case ProductAction.SAVE:
          await this.saveProduct();
          break;
        case ProductAction.PAUSE:
          await this.pauseProduct();
          break;
        case ProductAction.DELETE:
          await this.deleteProduct();
          break;
        case ProductAction.CLOSE:
          await this.closeProduct();
          break;
        case ProductAction.REEDIT:
          await this.reeditProduct();
          break;
        case ProductAction.TERMINATE:
          await this.terminateProduct();
          break;
        case ProductAction.SUSPEND:
          await this.suspendProduct();
          break;
        case ProductAction.RESUME:
          await this.resumeProduct();
          break;
      }
    },
    async refetchProduct() {
      console.log('=== refetchProduct() start ===');
      try {
        await this.$apollo.queries.form.refetch();
        await this.$apollo.queries.schedules.refetch();
      } catch (e) {
        console.log(e);
      }
      console.log('=== refetchProduct() end ===');
    },
    async saveProduct() {
      const { isValid, alert } =
        productParamValidateService.validateParamForCreate(
          this.form,
          this.host.id
        );

      if (!isValid) {
        this.showAlert(alert);
        return;
      }
      this.saveLoading = true;

      try {
        if (this.isUpdate) {
          await this.updateProduct();
        } else {
          await this.createProduct();
        }
        this.showSuccessMessage('상품 저장 성공', '상품이 저장되었습니다.');
        this.saveLoading = false;
        await this.refetchProduct();
      } catch (error) {
        this.saveLoading = false;
        this.showErrorAlert(error, '상품 저장 실패');
      }
    },
    async createProduct() {
      this.id = await productSaveService.createProduct(this.host.id, this.form);
      await this.saveProductRefundPolicy();
      // 히스토리 스택을 쌓지 않음
      this.$router.replace(`list/${this.id}?tab=${this.tabIndex}`);
    },
    async updateProduct() {
      this.id = await productSaveService.saveProduct(this.id, this.form);
      await this.saveProductRefundPolicy();
    },
    async saveProductRefundPolicy() {
      if (this.refundPolicyParam.refundPolicyId !== '') {
        if (this.form.cancelingRestricted) {
          // TODO: 개선 필요
          await productSaveService.saveProductRefundPolicy(
            this.id,
            this.refundPolicyParam.refundPolicyId,
            this.refundPolicyParam.extraNotice
          );
        } else {
          await productSaveService.saveProductRefundPolicy(
            this.id,
            this.refundPolicyParam.refundPolicyId
          );
        }
      }
    },

    async onClickRequestInspection() {
      if (this.isHaveFreeOption) {
        this.$modal.show(
          {
            title: '판매가가 0원인 옵션이 존재합니다.',
            message: '검수 요청을 하시겠습니까?',
            showCancelButton: true,
            confirmText: '네',
            cancelText: '아니오',
          },
          async () => await this.requestInspection()
        );
      } else {
        await this.requestInspection();
      }
    },
    async requestInspection() {
      if (!this.id) {
        this.$modal.show({
          title: '검수 요청 실패',
          message: '검수 요청전에 먼저 저장을 해야 합니다.',
          type: 'warning',
        });
        return;
      }
      this.requestInspectionLoading = true;
      this.inspectionErrorTabs = [];

      try {
        await this.updateProduct();
        await productStatusChangeService.requestInsepection(this.id);
        this.showSuccessMessage('검수 요청', '검수 요청 하였습니다.');
        await this.refetchProduct();
        await this.$router.replace(`/product/list`);
      } catch (error) {
        this.inspectionErrorTabs =
          this.showInspectionError(error, '상품 검수요청 체크') || [];
      } finally {
        this.requestInspectionLoading = false;
      }
    },
    async cancelInspection() {
      try {
        this.$modal.show(
          {
            title: '검수 취소',
            message: '검수를 취소하시겠습니까?',
            showCancelButton: true,
            type: 'danger',
          },
          async () => {
            this.cancelInspectionLoading = true;
            await productStatusChangeService.cancelInsepction(this.id);
            this.showSuccessMessage('검수 취소', '검수 취소 요청 하였습니다.');
            await this.refetchProduct();
            this.cancelInspectionLoading = false;
          }
        );
      } catch (error) {
        this.showErrorAlert(error, '검수 취소 실패');
      }
    },
    async openProduct() {
      try {
        await productStatusChangeService.openProduct(this.id);
        await this.refetchProduct();
        this.showSuccessMessage(
          '상품 판매 시작',
          '상품이 판매중 상태로 변경되었습니다.'
        );
      } catch (error) {
        this.showErrorAlert(error, '상품 판매 실패');
      }
    },
    async pauseProduct() {
      try {
        this.$modal.show(
          {
            title: '상품 판매를 일시 중지합니다.',
            message: '정말로 일시 중지하시겠습니까?',
            showCancelButton: true,
            type: 'primary',
          },
          async () => {
            await productStatusChangeService.pauseProduct(this.id);
            await this.refetchProduct();
            this.showSuccessMessage(
              '상품 판매 일시 중지',
              '상품이 판매 일시 중지 상태로 변경되었습니다.'
            );
          }
        );
      } catch (error) {
        this.showErrorAlert(error, '상품 판매 일시 중지 실패');
      }
    },
    async suspendProduct() {
      try {
        this.$modal.show(
          {
            title: '상품 판매를 중지합니다.',
            html: '판매 중지한 상품은 호스트가 스스로 판매를 재개할 수 없습니다.',
            showCancelButton: true,
            type: 'primary',
          },
          async () => {
            await productStatusChangeService.suspendProduct(this.id);
            await this.refetchProduct();
            this.showSuccessMessage(
              '상품 판매중지',
              '상품이 판매 중지 상태로 변경되었습니다.'
            );
          }
        );
      } catch (error) {
        this.showErrorAlert(error, '상품 판매 중지 실패');
      }
    },
    async resumeProduct() {
      try {
        this.$modal.show(
          {
            title: '상품 판매를 재개합니다.',
            html: '상품이 판매중 상태로 전환됩니다.',
            showCancelButton: true,
            type: 'primary',
          },
          async () => {
            await productStatusChangeService.resumeProduct(this.id);
            await this.refetchProduct();
            this.showSuccessMessage(
              '상품 판매재개',
              '상품이 판매중 상태로 변경되었습니다.'
            );
          }
        );
      } catch (error) {
        this.showErrorAlert(error, '상품 판매 재개 실패');
      }
    },
    async closeProduct() {
      try {
        this.$modal.show(
          {
            title: '상품 판매를 종료합니다.',
            html: '판매 종료한 상품은 호스트가 수정하거나 스스로 판매를 재개할 수 없습니다. 정말 판매 종료로 변경하시겠습니까?',
            showCancelButton: true,
            type: 'primary',
          },
          async () => {
            await productStatusChangeService.closeProduct(this.id);
            await this.refetchProduct();
            this.showSuccessMessage(
              '상품 판매 종료',
              '상품이 판매 종료되었습니다.'
            );
          }
        );
      } catch (error) {
        this.showErrorAlert(error, '상품 판매 종료 실패');
      }
    },
    async terminateProduct() {
      try {
        this.$modal.show(
          {
            title: '상품 운영 종료',
            html: `상품 운영이 종료됩니다. </br>
            <p style="color: #eb385e; font-weight: bold">운영 종료 상태가 되면 수정 및 복구가 불가능합니다.</p>
              <p style="font-weight: bold">정말 해당 상품의 운영을 종료하시겠습니까?</p>`,
            type: 'danger',
            showCancelButton: true,
            confirmText: '운영 종료',
          },
          async () => {
            await productStatusChangeService.terminateProduct(this.id);
            await this.$router.replace(`/product/list`);
            this.showSuccessMessage(
              '상품 운영 종료',
              '상품이 운영 종료되었습니다.'
            );
          }
        );
      } catch (error) {
        this.showErrorAlert(error, '상품 운영 종료 실패');
      }
    },
    async reeditProduct() {
      this.$modal.show(
        {
          title: '상품을 재수정 합니다.',
          html: '상품을 재수정 하시겠습니까? </br> 재수정을 누르면 등록중 상태가 됩니다.',
          showCancelButton: true,
        },
        async () => {
          try {
            await productStatusChangeService.reeditProduct(this.id);
            await this.refetchProduct();
            this.showSuccessMessage(
              '상품 재수정 성공',
              '상품이 등록중 상태로 변경 되었습니다.'
            );
          } catch (error) {
            this.showErrorAlert(error, '상품 재수정 실패');
          }
        }
      );
    },
    async deleteProduct() {
      this.$modal.show(
        {
          title: '상품을 삭제하시겠습니까?',
          html: '<span>프립 목록에서 바로 삭제되며, 삭제한 프립은 복구되지 않습니다.<br /> <b style="color:red">삭제하시겠습니까?</b></span>',
          showCancelButton: true,
          type: 'warning',
          confirmText: '네',
          cancelText: '아니오',
        },
        async () => {
          try {
            await productSaveService.deleteProduct(this.id);
            // TODO: 상품 삭제 성공시 성공 메시지
            this.showSuccessMessage('상품 삭제 성공', '상품이 삭제되었습니다.');
            await this.$router.replace(`/product/list`);
          } catch (error) {
            this.showErrorAlert(error, '상품 삭제 실패');
          }
        }
      );
    },
    async refetchSchedules() {
      console.log('refetch schedules');
      await this.$apollo.queries.schedules.refetch();
    },
    async addChannelMembers() {
      try {
        const response = await talkPlusService.addChannelMembers(
          `P${this.form.id}`,
          [this.inviteUserId]
        );
        if (response) {
          this.$modal.show({
            title: '초대 완료!',
            type: 'success',
            message: `'${this.inviteUserId}' 유저를 채팅방에 초대했습니다.`,
          });
        } else {
          this.$modal.show({
            title: '오류',
            type: 'warning',
            message: '실패했습니다!',
          });
        }
      } catch (err) {
        this.$modal.show({
          title: '오류',
          type: 'warning',
          message: '실패했습니다!',
        });
      }
    },
    async removeChannelMembers() {
      try {
        const response = await talkPlusService.removeChannelMembers(
          `P${this.form.id}`,
          [this.removeUserId]
        );
        if (response) {
          this.$modal.show({
            title: '내보내기 완료!',
            type: 'success',
            message: `'${this.removeUserId}' 유저를 채팅방에서 내보냈습니다.`,
          });
        } else {
          this.$modal.show({
            title: '오류',
            type: 'warning',
            message: '실패했습니다!',
          });
        }
      } catch (err) {
        this.$modal.show({
          title: '오류',
          type: 'warning',
          message: '실패했습니다!',
        });
      }
    },
    onClickInspection() {
      const popup = this.$refs.InspectionPopup as InstanceType<
        typeof InspectionPopup
      >;
      popup?.open(String(this.inspection?.id), InspectionPart.MD);
    },
    showBasicInfoInvalidAlert(alert: ModalOption) {
      this.$modal.show(
        {
          ...alert,
          type: 'primary',
        },
        () => {
          this.tabIndex = 0;
        }
      );
    },
    toTop() {
      window.scrollTo({ top: 0, behavior: 'auto' });
    },
    movePrevious() {
      this.tabIndex--;
      this.toTop();
    },
    moveNext() {
      if (this.tabIndex === 0) {
        // 기본 정보가 유효한지 체크한다.
        const { isValid, alert } =
          productParamValidateService.validateBasicInfo(
            this.form,
            this.host.id
          );
        if (isValid) {
          console.log('next page');
          this.isBasicInfoValid = true;
          this.$nextTick(() => {
            this.tabIndex++;
            this.toTop();
          });
        } else if (alert) {
          this.showBasicInfoInvalidAlert(alert);
        }
      } else {
        this.tabIndex++;
        this.toTop();
      }
    },
    changeTab() {
      if (this.tabIndex !== 0 && !this.isBasicInfoValid) {
        const { isValid, alert } =
          productParamValidateService.validateBasicInfo(
            this.form,
            this.host.id
          );
        if (isValid) {
          this.isBasicInfoValid = true;
        } else if (alert) {
          this.showBasicInfoInvalidAlert(alert);
        }
      }
    },
    onClickMoveToChatRoom() {
      let url = `https://www.talkplus.io/apps/${
        getConstants().talkPlusAppId
      }/channels/P${this.form.id}`;
      window.open(url, '_blank');
    },
  },
  apollo: {
    form: {
      query: PRODUCT,
      loadingKey: 'productLoading',
      variables(): productQueryParam {
        return {
          id: this.isCopy
            ? (this.$route.query.sourceProductId as string)
            : this.id.toString(),
        };
      },
      result(result: ApolloQueryResult<{ product: { product: Product } }>) {
        const productAPIResponse = result.data.product.product;

        this.host = productAPIResponse.host;

        this.manager = productAPIResponse.manager
          ? productAPIResponse.manager
          : { id: '', name: '' };

        this.refundPolicyParam = {
          refundPolicyId: productAPIResponse.refundPolicy.id,
          extraNotice: productAPIResponse.refundPolicy.extraNotice,
          description: productAPIResponse.refundPolicy.extraNotice
            ? productAPIResponse.refundPolicy.extraNotice
            : productAPIResponse.refundPolicy.description,
        };

        this.firstOpenedAt =
          productAPIResponse.firstOpenedAt && !this.isCopy
            ? productAPIResponse.firstOpenedAt
            : 0;

        if (productAPIResponse.lastInspection && !this.isCopy) {
          this.inspection = productAPIResponse.lastInspection;
        }
      },
      update(data: { product: { product: Product } }): ProductParam {
        if (
          !this.isAdmin &&
          this.$store.state.userToken.hostId !== data.product.product.host.id
        ) {
          this.$router.replace('/product/list');
        }
        const productParam = productConvertService.convertAPIResponseToParam(
          data.product.product
        );

        return this.isCopy
          ? productCopyService.copyProduct(productParam)
          : productParam;
      },
      skip(): boolean {
        return !this.isCopy && !this.isUpdate;
      },
    },
    schedules: {
      query: SCHEDULES_BY_TERM,
      loadingKey: 'scheduleLoading',
      variables(): scheduleQueryParam {
        return {
          productId: this.isCopy
            ? (this.$route.query.sourceProductId as string)
            : this.id.toString(),
          schedulingTerm: this.form.frip?.schedulingTerm,
        };
      },
      update(data: {
        product: { schedulesByTerm: Schedule[] };
      }): ScheduleParam[] {
        const scheduleParams = productConvertService.convertSchedules(
          data.product.schedulesByTerm
        );
        return this.isCopy
          ? scheduleParams.map(productCopyService.copySchedule)
          : scheduleParams;
      },
      skip(): boolean {
        return (
          (!this.isUpdate && !this.isCopy) || !this.form.frip?.schedulingTerm
        );
      },
    },
    host: {
      query: PRODUCT_HOST,
      variables(): { id: string } {
        return {
          id: this.host.id,
        };
      },
      skip(): boolean {
        return this.isUpdate || this.host.id === '';
      },
    },
  },
  /*
  beforeRouteLeave(_to, _from, next) {
    this.$modal.show(
      {
        title: '정말 나가시나요?',
        html: `그냥 나가시면 작성된 내용이 사라질 수 있어요.<br/>
          나중에 이어서 하실려면 [프립목록]에서<br/>
          작성중인 프립을 선택해 주세요.`,
        type: 'primary',
        showCancelButton: true,
        confirmText: '계속 작성하기',
        cancelText: '나가기',
      },
      () => {
        return;
      },
      () => {
        next();
      }
    );
  },
  */
  watch: {
    '$route.path': {
      immediate: true,
      handler(newValue: string) {
        if (newValue === '/product/create') {
          this.id = '';
        }
      },
    },
  },
});
