




























































import Vue from 'vue';
import { TagKind } from '../../model/product/response/tag';
import Tag from './Tag.vue';

export default Vue.extend({
  name: 'TagForm',
  components: {
    Tag,
  },
  props: {
    kind: {
      type: String,
      required: true,
    },
    tag: {
      type: Object,
    },
  },
  data() {
    return {
      form: {
        kind: this.kind,
        name: this.tag ? this.tag.name : '',
        backgroundColor: this.tag ? this.tag.backgroundColor : '#0075EF',
        textColor: this.tag ? this.tag.textColor : '#FFFFFF',
        activated: this.tag ? this.tag.activated : false,
      },
      kindOptions: [
        {
          text: '일반',
          value: TagKind.NORMAL,
        },
        {
          text: '특가',
          value: TagKind.PRICE,
        },
      ],
    };
  },
  computed: {
    submitButtonMessage(): string {
      return this.tag ? '태그 수정' : '태그 추가';
    },
    isCreateForm(): boolean {
      return !this.tag;
    },
  },
  methods: {
    addTag() {
      const tag = {
        id: this.tag?.id,
        kind: this.kind,
        name: this.form.name,
        backgroundColor: this.form.backgroundColor,
        textColor: this.form.textColor,
        activated: this.form.activated,
      };
      this.$emit('addTag', tag);
    },
    reset() {
      this.form.kind = 'NORMAL';
      this.form.name = '';
      this.form.backgroundColor = '#0075EF';
      this.form.textColor = '#FFFFFF';
      this.form.activated = false;
    },
  },
});
