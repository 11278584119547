



























































import Vue from 'vue';
import { Option as ColumnOption } from '@/common/Option';

export default Vue.extend({
  name: 'editable-table',
  props: {
    columns: {
      type: Array,
    },
    data: {
      type: Array,
      required: true,
    },
    type: {
      type: String, // striped | hover
      default: '',
    },
    theadClasses: {
      type: String,
      default: '',
    },
    tbodyClasses: {
      type: String,
      default: '',
    },
    check: {
      type: Boolean,
      default: false,
    },
    responsive: {
      type: Boolean,
      default: true,
    },
    noDataText: {
      type: String,
      default: '검색 결과가 없습니다.',
    },
  },
  data(): { rows: any[]; allChecked: boolean } {
    return {
      rows: this.data,
      allChecked: true,
    };
  },
  computed: {
    tableClass(): string {
      return this.type && `table-${this.type}`;
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(newData) {
        this.rows = newData;
      },
    },
  },
  methods: {
    checkRow() {
      this.$emit('check');
    },
    checkAllItems() {
      this.rows.forEach(item => {
        item.checked = this.allChecked;
      });
    },
    updateOptionName(column: ColumnOption) {
      this.$emit('update-column', column);
    },
  },
});
