









































































































































import Vue from 'vue';
import DaySelect from '../DaySelect.vue';
import RangeInput from '../../../../components/Forms/RangeInput.vue';
import PlanTimeInput from './PlanTimeInput.vue';
import ItemSelectList from '../Item/ItemSelectList.vue';
import { Select, Option } from 'element-ui';
import { ProductScheduleService } from '../../service/ProductScheduleService';
import {
  ItemParam,
  PlanParam,
  ScheduleTimeParam,
} from '../../model/product/param/productSaleInfoParam';
import {
  DAY_MILLISECOND,
  HOUR_MILLISECOND,
} from '@/common/util/day/getConstants';
import { getDayAndHourByDuration } from '@/common/util/day/getDayAndHourByDuration';
import { deepCopy } from '@/common/util/deepCopy';
import { isHostAdmin } from '@/env';

const productScheduleService = new ProductScheduleService();

export default Vue.extend({
  name: 'TimeZoneForm',
  components: {
    // InlineRadioInput,
    DaySelect,
    RangeInput,
    PlanTimeInput,
    ItemSelectList,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    plan: {
      type: Object,
    },
    items: {
      type: Array,
    },
    inventoryTargetType: {
      type: String,
    },
    isUpdateForm: {
      type: Boolean,
      default: false,
    },
    bookingConfirmationEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: this.isUpdateForm
        ? deepCopy(this.plan)
        : productScheduleService.getPlanInitData(), // 변경을 눌러야만 적용되어야 하므로 deepCopy함
      itemList: deepCopy(this.items) as ItemParam[],
      operatingDays: 'manual',
      duration: getDayAndHourByDuration(this.plan.preparatoryPeriod),
      itemSelectModal: false,
      repeatScheduleOptions: [
        { label: '매일', value: 'day' },
        { label: '매주', value: 'week' },
      ],
      operatingDaysOptions: [{ label: '사용자 선택', value: 'manual' }],
      overNightOptions: [
        { text: '예', value: true },
        { text: '아니오', value: false },
      ],
      operationTime: [],
      optionList: [],
      isAdmin: !isHostAdmin(),
    };
  },
  computed: {
    period(): number {
      return (
        this.duration.day * DAY_MILLISECOND +
        this.duration.hour * HOUR_MILLISECOND
      );
    },
    selectedItems(): ItemParam[] {
      return this.itemList.filter((item: ItemParam) =>
        this.form.targetItemParamIds.includes(item.paramId)
      );
    },
    planFormType(): string {
      return this.isUpdateForm ? '수정' : '등록';
    },
    repeatSchedule: {
      get(): string {
        return this.form.weeks.length === 7 ? 'day' : 'week';
      },
      set(newValue: string) {
        if (newValue === 'day') {
          this.form.weeks = [];
        }
      },
    },
    onlyQuotaLimit: {
      get(): boolean {
        return this.form.minimumQuota === 0;
      },
      set(newValue: boolean) {
        if (newValue) {
          this.form.minimumQuota = 0;
        }
      },
    },
  },
  created() {
    this.itemList.forEach(item => {
      item.checked = false;
      if (this.form.targetItemParamIds.includes(item.paramId)) {
        item.checked = true;
      }
    });
  },
  methods: {
    savePlan() {
      // TODO: 만약 신청기간이 1일 미만이라면 얼럿을 띄워줘야함
      if (this.validateForm()) {
        this.form.preparatoryPeriod = this.period;

        if (!this.form.appliedAllItems) {
          if (this.selectedItems.length === 0) {
            this.$modal.show({
              html: `일정은 하나 이상의 옵션을 선택해야 합니다.`,
              type: 'danger',
            });
            return;
          }
          this.form.targetItemParamIds = this.selectedItems.map(
            item => item.paramId
          );
        } else {
          this.form.targetItemParamIds = [];
        }

        this.$emit('updatePlan', this.form);
      }
    },
    deletePlan() {
      this.$modal.show(
        {
          title: '정말로 반복일정을 삭제하시겠습니까?',
          message: '반복일정이 삭제됩니다.',
          showCancelButton: true,
          type: 'danger',
        },
        () => {
          this.$emit('deletePlan', this.form.paramId);
        }
      );
    },
    validateForm(): boolean {
      let isValid = true;
      const planStartedAt = this.form.term.startedAt;
      if (this.form.term.startedAt > this.form.term.endedAt) {
        this.$modal.show({
          message: '일정 종료일을 일정 시작일보다 미래로 설정해 주세요.',
          type: 'danger',
        });
        isValid = false;
      } else if (planStartedAt - this.period < new Date().getTime()) {
        // 만약 판매 시작일이 오늘보다 작다면 에러
        this.$modal.show({
          message: '일정 시작일은 현재보다 미래로 설정해 주세요.',
          type: 'danger',
        });
        isValid = false;
      } else if (
        (this.period <= DAY_MILLISECOND || this.bookingConfirmationEnabled) &&
        this.form.minimumQuota >= 2
      ) {
        this.$modal.show({
          html: `
          최소 모집 인원이 다 모이지 않을 경우 일정이 자동 취소됩니다.</br> 이를 방지하기 위해 최소 인원은 1명으로만 설정하실 수 있습니다. </br>최소 인원을 1명으로 변경해주세요.
        `,
          type: 'danger',
        });
        isValid = false;
      }

      return isValid;
    },
    changeTimes(times: ScheduleTimeParam[]) {
      console.log('change');
      this.form.times = times;
    },
    showItemSelectModal() {
      this.itemSelectModal = true;
    },
    closeItemSelectModal() {
      this.itemSelectModal = false;
    },
    applyItemsToPlan(items: ItemParam[]) {
      // this.selectedItems = items;
      this.closeItemSelectModal();
    },
    closePlanForm() {
      this.$emit('close');
    },
  },
});
