import gql from 'graphql-tag';

export const WRITE_PRODUCT_MANAGING_NOTE = gql`
  mutation writeProductManagingNote(
    $param: ProductManagingNoteParamInput!
    $productId: ID!
  ) {
    writeProductManagingNote(param: $param, productId: $productId) {
      id
      note
    }
  }
`;
