






























import Vue, { VueConstructor } from 'vue';
import gql from 'graphql-tag';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import { Edge } from '@frientrip/domain';

interface Merchandiser {
  id: string;
  name: string;
}

interface MerchandiserNameSearchParam {
  page?: number;
  size?: number;
  filter: MerchandiserFilter;
}

interface MerchandiserFilter {
  nameLike?: string;
}

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLFormElement } }>
).extend({
  name: 'MerchandiserSearchInput',
  components: {
    VueBootstrapTypeahead,
  },
  model: {
    event: 'select',
  },
  props: {
    value: {
      type: String,
    },
    invalidMessage: {
      type: String,
    },
  },
  data(): {
    name: string;
    merchandisers: Merchandiser[];
    merchandiser: Merchandiser;
    selectedMerchandiserId: string;
    selectedMerchandiserName: string;
  } {
    return {
      name: '',
      merchandisers: [],
      merchandiser: { id: '', name: '' },
      selectedMerchandiserId: '',
      selectedMerchandiserName: '',
    };
  },
  methods: {
    getMerchandiserName(name: string): void {
      this.name = name;
    },
    selectMerchandiser(merchandiser: Merchandiser) {
      console.log(merchandiser);
      this.selectedMerchandiserId = merchandiser.id;
      this.selectedMerchandiserName = merchandiser.name;
      this.$emit('select', this.selectedMerchandiserId);
    },
  },
  apollo: {
    merchandisers: {
      query: gql`
        query merchandisers(
          $page: Int
          $size: Int
          $filter: MerchandiserFilterInput
        ) {
          product {
            merchandisers(page: $page, size: $size, filter: $filter) {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      variables(): MerchandiserNameSearchParam {
        return {
          page: 1,
          size: 10,
          filter: {
            nameLike: this.name,
          },
        };
      },
      update: data =>
        data.product.merchandisers.edges.map(
          (edge: Edge<Merchandiser>) => edge.node
        ),
      skip(): boolean {
        return this.name === '';
      },
      debounce: 200,
    },
  },
  watch: {
    value(newValue: string) {
      this.selectedMerchandiserId = newValue;
      if (newValue === '') {
        this.$refs.merchandiserSearch.inputValue = newValue;
        this.selectedMerchandiserName = newValue;
      }
    },
  },
});
