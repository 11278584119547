import { ValidationResult } from '@/common/model/ValidationResult';
import { ValidationService } from '@/common/service/ValidationService';
import { CommissionType } from '@frientrip/domain';
import { SalesType } from '../model/product/enum';
import {
  CommissionParam,
  ItemOptionParam,
  ItemParam,
  Price,
} from '../model/product/param/productSaleInfoParam';

export class ProductItemValidateService extends ValidationService {
  public validateItem(
    form: ItemParam,
    attachedToSchedule: boolean,
    hasMinimumQuotaLimit: boolean
  ): ValidationResult {
    let isValid = true;
    const invalidFields = [];

    if (form.scheduledBySale && !attachedToSchedule) {
      let saleTermValidation = '';
      if (!form.saleTerm) {
        saleTermValidation = '한정판매 기간을 입력해주세요.';
        isValid = false;
      } else if (form.saleTerm.startedAt && form.saleTerm.endedAt) {
        if (form.saleTerm.startedAt > form.saleTerm.endedAt) {
          saleTermValidation =
            '한정판매 종료일은 판매종료일보다 이후일 수 없습니다.';
          isValid = false;
        }
      } else if (!form.saleTerm.endedAt || !form.saleTerm.startedAt) {
        saleTermValidation = '한정판매 기간을 입력해주세요.';
        isValid = false;
      }

      if (!isValid) {
        invalidFields.push(saleTermValidation);
      }
    }
    if (form.price.retail < form.price.sale) {
      // alert.message = '정가는 판매가보다 작을 수 없습니다.';
      invalidFields.push('정가는 판매가보다 작을 수 없습니다.');
      isValid = false;
    }
    if (hasMinimumQuotaLimit && form.minimumQuota > 1) {
      invalidFields.push(
        '개별 예약 확정 프립은 최소 모집 인원을 1명으로 설정해주세요.'
      );
      isValid = false;
    }
    const alert = {
      title: '옵션 정보를 확인해주세요.',
      html: this.makeUlHtml(invalidFields),
    };

    return {
      isValid: isValid,
      alert: alert,
    };
  }
  public validatePrice(form: ItemParam): ValidationResult {
    let isValid = true;
    const alert = {
      title: '',
      message: '',
    };
    if (form.price.sale === 0) {
      isValid = false;
      alert.title = '상품의 판매가가 0원입니다.';
      alert.message = '확인을 누르시면 무료옵션으로 적용됩니다.';
    }
    return {
      isValid: isValid,
      alert: alert,
    };
  }
  private isValidCommissionCharge(
    commission: CommissionParam,
    price: Price
  ): boolean {
    const charge = Number(commission.charge);
    if (commission.type === CommissionType.RATIO) {
      return charge > 5.5;
    } else if (commission.type === CommissionType.FIXED) {
      return (charge * 100) / price.sale > 5.5;
    } else {
      return true;
    }
  }
  public validateCommission(form: ItemParam): ValidationResult {
    let isValid = true;
    const alert = {
      title: '',
      message: '',
    };
    if (!this.isValidCommissionCharge(form.commission, form.price)) {
      isValid = false;
      alert.title = '수수료가 PG가 결제 수수료 인 5.5% 이하입니다.';
      alert.message = '해당 수수료가 맞으시면 확인을 선택하세요.';
    }

    if (form.salesType === SalesType.PURCHASE) {
      isValid = true;
    }

    return {
      isValid: isValid,
      alert: alert,
    };
  }
}
