































































































































































































































import Vue from 'vue';
import InlineTagInput from '@/components/Forms/InlineTagInput.vue';
import CurriculumSectionInput from '../../Curriculum/CurriculumSectionInput.vue';
import { CurriculumSectionParam } from '@/domain/product/model/product/param/productAdditionalInfoParam';
import {
  CurriculumKind,
  CurriculumStyle,
  ProductKind,
} from '@/domain/product/model/product/enum';
import { isHostAdmin } from '@/env';
import InquiryRegisterForm from '../../InquiryTemplate/InquiryRegisterForm.vue';
import { PRODUCT_INQUIRY_TEMPLATE } from '../../../queries/inquiryTemplate/query';
import { InquiryTemplateQueryParam } from '../../../model/product/param/inquiryTemplateParam';
import InquiryPreview from '../../InquiryTemplate/InquiryPreview.vue';
import { CompanionType } from '@/domain/product/model/product/response/frip';

export default Vue.extend({
  name: 'ProductAdditionalInfoForm',
  components: {
    CurriculumSectionInput,
    InlineTagInput,
    InquiryRegisterForm,
    InquiryPreview,
  },
  props: {
    product: {
      type: Object,
    },
    host: {
      type: Object,
    },
  },
  data() {
    return {
      form: this.product,
      isAdmin: !isHostAdmin(),
      recommendedAgeOption:
        this.product.frip.recommendedAge > 0 ? 'recommended' : 'noRecommended',
      minorAllowOption: 'allow',
      tagCaption: `콤마(,)로 구분하며 최대 20개의 사항을 한번에 등록할 수 있습니다. \n 내용 입력 후 [등록] 버튼을 클릭해야 등록됩니다.`,
      curriculumOptions: [
        {
          text: '없음',
          value: CurriculumStyle.NONE,
        },
        {
          text: '시간표로 작성',
          value: CurriculumStyle.DURATION,
        },
        {
          text: '구간표로 작성',
          value: CurriculumStyle.TIMETABLE,
        },
        {
          text: '기타',
          value: CurriculumStyle.LEGACY,
        },
      ],
      recommendedAgeOptions: [
        {
          text: '권장 연령 없음',
          value: 'noRecommended',
        },
        {
          text: '권장 연령 있음',
          value: 'recommended',
        },
      ],
      underageRestrictOptions: [
        {
          text: '구매가능',
          value: false,
        },
        {
          text: '구매 불가',
          value: true,
        },
      ],
      unverifiedUserRestrictOptions: [
        {
          text: '인증 필수',
          value: true,
        },
        {
          text: '인증 불필요',
          value: false,
        },
      ],
      companionTypeOptions: [
        {
          text: '혼자서',
          value: CompanionType.ALONE,
        },
        {
          text: '친구와',
          value: CompanionType.WITH_FRIENDS,
        },
        {
          text: '연인과',
          value: CompanionType.WITH_LOVER,
        },
        {
          text: '아이와',
          value: CompanionType.WITH_CHILDREN,
        },

        {
          text: '반려견과',
          value: CompanionType.WITH_DOG,
        },
      ],
      templateAddModal: false,
      showTemplateDetailModal: false,
      selectedInquiry: {},
      idToBeUpdated: '',
    };
  },
  computed: {
    curriculumKind(): CurriculumKind {
      // if (
      //   this.form.kind !== 'EVENT' &&
      //   (this.form.attributeIds.includes('2') ||
      //     this.form.attributeIds.includes('6'))
      // ) {
      //   return CurriculumKind.CURRICULUM;
      // } else {
      //   return CurriculumKind.SCHEDULE;
      // }
      return CurriculumKind.SCHEDULE;
    },
    displayTitle(): string {
      // if (this.curriculumKind === CurriculumKind.CURRICULUM) {
      //   return '커리큘럼';
      // } else if (this.curriculumKind === CurriculumKind.SCHEDULE) {
      //   return '스케줄';
      // } else {
      //   return '';
      // }
      return '스케줄';
    },
    optionalLabel(): string {
      return '(선택)';
    },
    isFrip(): boolean {
      return this.form.kind !== ProductKind.GOODS;
    },
    inqueryIdToBeUpdated(): null | string {
      return this.idToBeUpdated === '' ? null : this.idToBeUpdated;
    },
  },
  watch: {
    'form.frip.curriculum.style': function (
      newCurriculumStyle: CurriculumStyle
    ) {
      if (newCurriculumStyle !== CurriculumStyle.NONE) {
        this.form.frip.curriculum.sections = [
          {
            key: 'curriculum' + Math.random(), // index를 key로 하면 splice를 해도 마지막 요소만 제거되는 현상이 있어서 랜덤 key를 생성해주었음
            title: '',
            kind: this.curriculumKind,
            items: [
              {
                duration: 0,
                term: {
                  startingTime: {
                    hour: 0,
                    minute: 0,
                  },
                  endingTime: {
                    hour: 0,
                    minute: 0,
                  },
                },
                textTime: '',
                content: '',
              },
            ],
            etc: '',
          },
        ];
      }
    },
    'form.frip.curriculum.sections': function (
      newCurriculumSections: CurriculumSectionParam[]
    ) {
      if (newCurriculumSections.length === 0) {
        this.form.frip.curriculum.style = CurriculumStyle.NONE;
      }
    },
  },
  mounted() {
    this.form.inquiryTemplateId =
      this.$route.query.sourceProductId &&
      this.$route.query.sourceProductId !== ''
        ? (this.form.inquiryTemplateId = '')
        : this.form.inquiryTemplateId;
  },
  methods: {
    changeCurriculums(curriculumSections: CurriculumSectionParam) {
      this.form.frip.curriculum.sections = curriculumSections;
    },
    changeRecommendedAge() {
      if (this.recommendedAgeOption === 'noRecommended') {
        this.form.frip.recommendedAge = 0;
      }
    },
    saveForm() {
      console.log('save');
      this.$emit('save', this.form);
    },
    showTemplateAddModal() {
      this.templateAddModal = true;
    },
    setInquiryTemplate(id: string) {
      this.form.inquiryTemplateId = id;
      this.showTemplateDetailModal = false;
      this.templateAddModal = false;
    },
    showTemplateDetail() {
      this.showTemplateDetailModal = true;
    },
    showTemplateUpdate(id: string) {
      this.idToBeUpdated = id;
      this.showTemplateDetail();
    },
    closeTemplateDetail() {
      this.showTemplateDetailModal = false;
    },
    async changeTemplateDetail() {
      this.closeTemplateDetail();
      this.idToBeUpdated = '';
      await this.$apollo.queries.selectedInquiry.refetch();
    },
    deleteInquiry(id: string) {
      this.form.inquiryTemplateId = '';
    },
  },
  apollo: {
    selectedInquiry: {
      query: PRODUCT_INQUIRY_TEMPLATE,
      variables(): InquiryTemplateQueryParam {
        return {
          id: this.form.inquiryTemplateId,
        };
      },
      update: data => data.product.inquiryTemplate,
      skip(): boolean {
        return this.form.inquiryTemplateId === '';
      },
    },
  },
});
