import { MutationService } from '@/common/service/MutationService';
import { InquiryTemplateParam } from '../model/product/param/inquiryTemplateParam';
import {
  CREATE_INQUIRY_TEMPLATE,
  DELETE_INQUIRY_TEMPLATE,
  UPDATE_INQUIRY_TEMPLATE,
} from '../queries/inquiryTemplate/mutation';

export class InquiryTemplateService extends MutationService {
  public async createInquiryTemplate(
    hostId: string,
    param: InquiryTemplateParam
  ): Promise<void> {
    console.log('param: ', param);
    const result = await this.apollo.mutate({
      mutation: CREATE_INQUIRY_TEMPLATE,
      variables: {
        hostId: hostId,
        param: param,
      },
    });
    console.log('create inquiry result: ', result);
    return result.data?.createInquiryTemplate.id;
  }
  // TODO: param
  public async updateInquiryTemplate(id: string, param: any): Promise<void> {
    console.log('param: ', param);
    await this.apollo.mutate({
      mutation: UPDATE_INQUIRY_TEMPLATE,
      variables: {
        id: id,
        param: param,
      },
    });
  }
  public async deleteInquiryTemplate(id: string): Promise<void> {
    // TODO: delete inquiry template
    await this.apollo.mutate({
      mutation: DELETE_INQUIRY_TEMPLATE,
      variables: {
        id: id,
      },
    });
  }
}
