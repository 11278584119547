import { MutationService } from '@/common/service/MutationService';
import { ManagingNoteParam } from '../model/managingNote/managingNote';
import { WRITE_PRODUCT_MANAGING_NOTE } from '../queries/managingNote/mutation';

export class ProductManagingNoteService extends MutationService {
  public async writeProductManagingNote(
    param: ManagingNoteParam,
    productId: string
  ): Promise<void> {
    await this.apollo.mutate({
      mutation: WRITE_PRODUCT_MANAGING_NOTE,
      variables: {
        param: param,
        productId: productId,
      },
    });
  }
}
