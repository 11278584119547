




















































































import Vue from 'vue';
import { CurriculumKind } from '../../model/product/enum';
import CurriculumTimeTermInput from './CurriculumTimeTermInput.vue';

export default Vue.extend({
  components: {
    CurriculumTimeTermInput,
  },
  props: {
    value: {
      type: Object,
    },
    kind: {
      type: String,
    },
    index: {
      type: Number,
    },
    curriculumStyle: {
      type: String,
    },
  },
  data() {
    return {
      // TODO: 직접 value를 넣으면 입력이 안된다.. 그래서 deep copy 함
      curriculum: JSON.parse(JSON.stringify(this.value)),
      hasEtc: this.value.etc !== '',
    };
  },
  computed: {
    displayTitle(): string {
      if (this.kind === CurriculumKind.CURRICULUM) {
        return '커리큘럼';
      } else if (this.kind === CurriculumKind.SCHEDULE) {
        return '스케줄';
      } else {
        return '';
      }
    },
    title(): string {
      return this.curriculum.title === ''
        ? `${this.displayTitle} 타이틀을 입력해주세요`
        : this.curriculum.title;
    },
  },
  methods: {
    onInput() {
      if (!this.hasEtc) this.curriculum.etc = '';
      this.$emit('change', {
        index: this.index,
        curriculum: this.curriculum,
      });
    },
    colsByIndex(index: number): number {
      return index === 0 ? 2 : 1;
    },
    addRow() {
      if (this.curriculum.items.length >= 10) {
        this.$modal.show({
          message: '최대 10개까지만 추가 가능합니다.',
        });
      } else {
        this.curriculum.items.push({
          duration: 0,
          term: {
            startingTime: {
              hour: 0,
              minute: 0,
            },
            endingTime: {
              hour: 0,
              minute: 0,
            },
          },
          textTime: '',
          content: '',
        });
      }
    },
    deleteRow(index: number) {
      this.curriculum.items.splice(index, 1);
    },
    changeOrder(type: string) {
      // this.$emit('onChange', { order: this.order, type: type });
    },
    deleteCurriculum() {
      console.log(this.index);
      this.$emit('delete', this.index);
    },
  },
});
