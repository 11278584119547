











































































import Vue, { VueConstructor } from 'vue';
import UserSearchInput from '@/components/User/UserSearchInput.vue';
import { Merchandiser, MerchandiserParam } from '../../model/merchandiser';
import { MerchandiserService } from '../../service/MerchandiserService';
import { apolloClient } from '@/apolloClient';
import { PRODUCT_MERCHANDISER } from '../../queries/merchandiser/query';
import { MessageType } from 'element-ui/types/message';
import { ApolloQueryResult } from 'apollo-client';

const merchandiserSerivce = new MerchandiserService(apolloClient);

interface merchandiserQueryParam {
  id: string;
}

interface MerchandiserDetailData {
  id: string;
  merchandiser: MerchandiserParam;
  user: User | null;
}

interface User {
  id: string;
  nickname: string;
}

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLElement } }>
).extend({
  name: 'MerchandiserDetail',
  components: {
    UserSearchInput,
  },
  data(): MerchandiserDetailData {
    return {
      id: this.$route.params.id ? this.$route.params.id : '',
      merchandiser: {
        id: '',
        name: '',
        departmentName: '',
        staffNo: '',
        userId: '',
      },
      user: null,
    };
  },
  computed: {
    formTitle(): string {
      return `MD ${this.appendText}`;
    },
    appendText(): string {
      return this.isCreateForm ? '등록' : '수정';
    },
    isCreateForm(): boolean {
      return this.id === '';
    },
  },
  methods: {
    async saveMerchandiser() {
      if (this.id !== '') {
        await this.updateMerchandiser(this.merchandiser);
      } else {
        await this.createMerchandiser(this.merchandiser);
      }
    },
    async createMerchandiser(merchandiser: MerchandiserParam) {
      try {
        const param = {
          name: merchandiser.name,
          departmentName: merchandiser.departmentName,
          staffNo: merchandiser.staffNo,
          userId: merchandiser.userId !== '' ? merchandiser.userId : null,
        };
        await merchandiserSerivce.createMerchandiser(param);
        this.showNotify('등록 성공', '성공적으로 등록되었습니다.', 'success');
        this.moveToListPage();
      } catch (error) {
        console.error(error);
      }
    },
    async updateMerchandiser(merchandiser: MerchandiserParam) {
      try {
        const param = {
          name: merchandiser.name,
          departmentName: merchandiser.departmentName,
          staffNo: merchandiser.staffNo,
          userId: merchandiser.userId !== '' ? merchandiser.userId : null,
        };
        await merchandiserSerivce.updateMerchandiser(this.id, param);
        this.showNotify('수정 성공', '성공적으로 수정되었습니다.', 'success');
      } catch (error) {
        console.error(error);
      }
    },
    async deleteMerchandiser() {
      try {
        await merchandiserSerivce.deleteMerchandiser(this.id);
        this.$modal.show(
          {
            title: '정말로 삭제하시겠습니까?',
            message: 'MD를 삭제합니다.',
            type: 'danger',
            showCancelButton: true,
          },
          () => {
            this.showNotify(
              '삭제 성공',
              '성공적으로 삭제되었습니다.',
              'success'
            );
            this.moveToListPage();
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    selectUser(id: string) {
      this.merchandiser.userId = id;
    },
    moveToListPage() {
      this.$router.push('/product/merchandiser/list');
    },
    showNotify(title: string, message: string, type: MessageType) {
      this.$notify({ title: title, message: message, type: type });
    },
    resetForm() {
      this.merchandiser.name = '';
      this.merchandiser.departmentName = '';
      this.merchandiser.staffNo = '';
      this.merchandiser.userId = '';
      (this.$refs.formValidator as any).reset();
    },
  },
  apollo: {
    merchandiser: {
      query: PRODUCT_MERCHANDISER,
      variables(): merchandiserQueryParam {
        return {
          id: this.id,
        };
      },
      skip(): boolean {
        return this.id === '';
      },
      update: data => {
        return {
          id: data.product.merchandiser.id,
          name: data.product.merchandiser.name,
          departmentName: data.product.merchandiser.departmentName,
          staffNo: data.product.merchandiser.staffNo,
          userId: data.product.merchandiser.user
            ? data.product.merchandiser.user.id
            : null,
        };
      },
      result(
        result: ApolloQueryResult<{ product: { merchandiser: Merchandiser } }>
      ) {
        this.user = result.data.product.merchandiser.user;
      },
    },
  },
});
