import gql from 'graphql-tag';

export const INSPECTION_FRAGMENT = gql`
  fragment insepctionField on Inspection {
    id
    completedAt
    createdAt
    flowVersion
    host {
      id
      name
    }
    items {
      id
      comment
      part
      reasons {
        id
        name
        description
        part
        requiredComment
        status
      }
      status
      worker {
        id
        nickname
      }
    }
    mode
    status
  }
`;
