





















import { ContainerMixin } from '@/common/mixin/containerMixin';
import { apolloClient } from '@/apolloClient';
import { ScheduleService } from '@/domain/booking/service/ScheduleService';

const scheduleService = new ScheduleService(apolloClient);

export default ContainerMixin.extend({
  name: 'SchedulePausePopup',
  data(): {
    opened: boolean;
    scheduleIds: string[];
  } {
    return {
      opened: false,
      scheduleIds: [],
    };
  },
  methods: {
    open(scheduleIds: string[]): void {
      this.scheduleIds = scheduleIds;
      this.opened = true;
    },
    async clickOk(): Promise<void> {
      try {
        const res = await scheduleService.pauseByIds(this.scheduleIds);

        if (res.error) {
          console.error(res.error);
          let message = '판매 일시 중지중 오류가 발생하였습니다.';
          if (res.error?.message) {
            message = res.error?.message.replace('GraphQL error:', '');
          }

          this.$modal.show({
            title: '판매 일시 중지 실패!',
            type: 'warning',
            message: message,
          });
        } else {
          this.$modal.show(
            {
              title: '판매 일시 중지 성공!',
              type: 'info',
              message: '선택하신 일정이 판매 일시 중지 되었습니다.',
            },
            () => {
              this.$emit('refetch');
            }
          );
          this.opened = false;
        }
      } catch (error) {
        console.error(error);

        const message = '판매 일시 중지중 오류가 발생하였습니다.';

        this.$modal.show({
          title: '판매 일시 중지 실패!',
          type: 'warning',
          message: message,
        });
      }
    },
  },
});
