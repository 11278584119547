

























import Vue from 'vue';
import { CurriculumKind } from '../../model/product/enum';
import CurriculumItemInput from './CurriculumItemInput.vue';

export default Vue.extend({
  name: 'CurriculumSectionInput',
  components: {
    CurriculumItemInput,
  },
  props: {
    curriculumStyle: {
      type: String,
    },
    kind: {
      type: String,
    },
    curriculums: {
      type: Array,
    },
  },
  data() {
    return {
      curriculumSections: this.curriculums,
    };
  },
  computed: {
    displayTitle(): string {
      // if (this.kind === CurriculumKind.CURRICULUM) {
      //   return '커리큘럼';
      // } else if (this.kind === CurriculumKind.SCHEDULE) {
      //   return '스케줄';
      // } else {
      //   return '';
      // }
      return '스케줄';
    },
  },
  methods: {
    addCurriculum() {
      if (this.curriculumSections.length > 5) {
        this.$modal.show({
          message: '최대 5개까지 추가 가능합니다.',
        });
      } else {
        this.curriculumSections.push({
          key: 'curriculum' + new Date().valueOf(), // index를 key로 하면 splice를 해도 마지막 요소만 제거되는 현상이 있어서 랜덤 key를 생성해주었음
          title: '',
          kind: this.kind,
          items: [
            {
              duration: 0,
              term: {
                startingTime: {
                  hour: 0,
                  minute: 0,
                },
                endingTime: {
                  hour: 0,
                  minute: 0,
                },
              },
              textTime: '',
              content: '',
            },
          ],
          etc: '',
        });
      }
    },
    changeCurriculum(result: any) {
      // TODO: 타입 지정
      const { index, curriculum } = result;
      this.curriculumSections.splice(index, 1, curriculum);
      this.$emit('change', this.curriculumSections);
    },
    deleteCurriculum(index: number) {
      this.$modal.show(
        {
          title: '정말로 삭제하시겠습니까?',
          showCancelButton: true,
          type: 'danger',
          confirmText: '네',
          cancelText: '아니오',
        },
        () => {
          this.curriculumSections.splice(index, 1);
        }
      );
    },
    onChange() {
      console.log('change');
    },
  },
});
