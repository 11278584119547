









import Vue from 'vue';
export default Vue.extend({
  name: 'Tag',
  props: {
    tag: {
      type: Object,
      required: true,
    },
  },
  computed: {
    name(): string {
      return this.tag.name === '' ? '태그 이름' : this.tag.name;
    },
  },
});
