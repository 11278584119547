var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('content-card',{attrs:{"title":("검색 결과 : " + (_vm.totalCount.toString()) + " 건")},scopedSlots:_vm._u([{key:"footer",fn:function(){return [(_vm.totalCount > 0)?_c('b-pagination-nav',{staticClass:"mt-3",attrs:{"align":"center","link-gen":_vm.linkGen,"number-of-pages":_vm.numberOfPages,"use-router":""}}):_vm._e()]},proxy:true}])},[(_vm.loading > 0)?_c('spinner'):(_vm.isHostAdmin)?_c('div',{staticClass:"m-2"},[_c('b-row',{attrs:{"no-gutters":""}},[_vm._l((_vm.hostProducts),function(product){return _c('b-col',{key:product.id,attrs:{"lg":"4"}},[_c('product-card',{attrs:{"product":product}})],1)}),(_vm.products.length === 0)?_c('b-container',{staticClass:"text-center text-muted p-5"},[_vm._v(" 검색결과가 없습니다. ")]):_vm._e()],2)],1):_c('el-table',{ref:"table",staticClass:"table table-responsive table-flush table-striped align-items-center center-head",attrs:{"empty-text":"검색 결과가 없습니다.","header-row-class-name":"thead-light","header-cell-class-name":"center-table","data":_vm.products}},[_c('el-table-column',{attrs:{"label":"상품ID","prop":"id","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('a',{attrs:{"href":""}},[_vm._v(_vm._s(row.id))])]}}])}),_c('el-table-column',{attrs:{"min-width":200,"label":"상품명","prop":"title","class-name":"text-left","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-link',{attrs:{"to":("./list/" + (row.id))}},[_vm._v(_vm._s(row.title))])]}}])}),_c('el-table-column',{attrs:{"label":"","prop":"id","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('a',{attrs:{"href":""}},[_vm._v(_vm._s(_vm.convertFripKind(row.frip)))])]}}])}),_c('el-table-column',{attrs:{"label":"상품상태","prop":"status","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.convertedStatus(row.status)))]}}])}),_c('el-table-column',{attrs:{"label":"표준 카테고리","prop":"category","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.standardCategories.length > 0 ? row.standardCategories[0].label.name : '')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"판매종료일","prop":"status","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.saleTerm)?_c('instant',{attrs:{"at":row.saleTerm.endedAt}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"호스트","prop":"host","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.host.name)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"담당자","prop":"merchandiser","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.manager ? row.manager.name : '없음')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"복제","prop":"removeAndPaste","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('frip-button',{staticClass:"my-1",attrs:{"outline":"","size":"sm"},on:{"click":function($event){return _vm.copyProduct(row.id)}}},[_vm._v("복제 ")])]}}])}),_c('el-table-column',{attrs:{"label":"더보기","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-dropdown',{staticClass:"dropdown",attrs:{"trigger":"click"}},[_c('frip-button',{staticClass:"my-1",attrs:{"size":"sm","outline":""}},[_vm._v("더보기")]),_c('el-dropdown-menu',{staticClass:"dropdown-menu dropdown-menu-arrow show",attrs:{"slot":"dropdown"},slot:"dropdown"},[(_vm.changeProductStatusButtonText(row.status) !== '')?_c('b-dropdown-item',{on:{"click":function($event){return _vm.changeProductStatus(row.id, row.title, row.status)}}},[_vm._v(_vm._s(_vm.changeProductStatusButtonText(row.status))+" ")]):_vm._e(),_c('b-dropdown-item',{attrs:{"to":_vm.$wlink.host(row.host.id)}},[_vm._v("호스트 관리 ")]),_c('b-dropdown-item',{attrs:{"to":_vm.$wlink.productByHost(row.host.id)}},[_vm._v("호스트 상품 모아보기 ")]),_c('b-dropdown-item',{attrs:{"to":_vm.$wlink.qnaByProduct(row.id)}},[_vm._v("문의 관리 ")]),_c('b-dropdown-item',{attrs:{"to":_vm.$wlink.reviewByProduct(row.id)}},[_vm._v("후기 관리 ")]),_c('b-dropdown-item',{attrs:{"to":_vm.$wlink.bookingByProduct(row.id)}},[_vm._v("예약 내역 보기 ")]),_c('b-dropdown-item',{attrs:{"to":("list/" + (row.id) + "/schedules")}},[_vm._v("모든 일정 확인하기 ")]),_c('b-dropdown-item',{attrs:{"to":_vm.$wlink.orderByProduct(row.id)}},[_vm._v("주문 내역 확인하기 ")]),_c('b-dropdown-item',{attrs:{"href":(_vm.fripUrl + "/products/" + (row.id)),"target":"_blank"}},[_vm._v("상품 상세 바로가기 ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }