import {
  deliveryNotice,
  notice,
  onScheduleNotice,
} from '../constants/transactionNotice';
import { ProductKind } from '../model/product/enum';
import { ProductParam } from '../model/product/param/productParam';

export const getNoticeByProductKindAndSchedule = (
  product: ProductParam
): string => {
  if (product.kind === ProductKind.GOODS) {
    return deliveryNotice;
  } else if (product.frip?.attachedToSchedule) {
    return onScheduleNotice;
  } else {
    return notice;
  }
};
