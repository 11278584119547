




















import { Option } from '@/common/Option';
import Vue from 'vue';
import { DayOfWeek } from '../model/FripInfo';
export default Vue.extend({
  name: 'DaySelect',
  model: {
    prop: 'value',
    event: 'select',
  },
  props: {
    invalidMessage: {
      type: String,
    },
    value: {
      type: Array,
    },
  },
  data() {
    return {
      days: [
        { text: '월', value: DayOfWeek.MONDAY },
        { text: '화', value: DayOfWeek.TUESDAY },
        { text: '수', value: DayOfWeek.WEDNESDAY },
        { text: '목', value: DayOfWeek.THURSDAY },
        { text: '금', value: DayOfWeek.FRIDAY },
        { text: '토', value: DayOfWeek.SATURDAY },
        { text: '일', value: DayOfWeek.SUNDAY },
      ],
      selected: this.value ? this.value : ([] as string[]),
    };
  },
  computed: {
    invalid(): boolean {
      return this.selected.length == 0;
    },
  },
  methods: {
    onSelect(day: Option): void {
      if (!this.selected.includes(day.value)) {
        this.selected.push(day.value);
      } else {
        const index = this.selected.findIndex(option => option === day.value);
        console.log(index);
        this.selected.splice(index, 1);
      }
      this.$emit('select', this.selected);
    },
    selectedButtonClass(value: string): string {
      if (this.selected.includes(value)) {
        return 'selected';
      } else {
        return '';
      }
    },
  },
});
