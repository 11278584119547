

























































































































import Vue from 'vue';
import ScheduleCardTypeDescription from './ScheduleCardTypeDescription.vue';
import ScheduleDay from './ScheduleDay.vue';
import ScheduleForm from './ScheduleForm.vue';
import ScheduleTemplateForm from './ScheduleTemplateForm.vue';
import PlanForm from '../Plan/PlanForm.vue';
import Instant from '@/components/Labels/Instant.vue';
import { ProductScheduleService } from '../../service/ProductScheduleService';
import { ProductScheduleStatusChangeService } from '../../service/ProductScheduleStatusChangeService';
import {
  PlanParam,
  ScheduleParam,
  ScheduleTemplate,
} from '../../model/product/param/productSaleInfoParam';
import { RepeatSchedule } from '../../model/product/enum';
import { deepCopy } from '@/common/util/deepCopy';
import { apolloClient } from '@/apolloClient';
import { ContainerMixin } from '@/common/mixin/containerMixin';

const productScheduleService = new ProductScheduleService();
const productScheduleStatusChangeService =
  new ProductScheduleStatusChangeService(apolloClient);

interface DayInfo {
  date: string;
  day: number;
  time: number;
  schedules: ScheduleParam[];
  isCurrentMonth: boolean;
}

interface FullDate {
  year: number;
  month: number;
  date: number;
  isCurrentMonth: boolean;
}

export default ContainerMixin.extend({
  name: 'ScheduleCalendar',
  mixins: [ContainerMixin],
  components: {
    ScheduleCardTypeDescription,
    ScheduleDay,
    ScheduleForm,
    ScheduleTemplateForm,
    Instant,
    PlanForm,
  },
  props: {
    productId: {
      type: String,
    },
    schedulingTerm: {
      type: Object,
    },
    list: {
      type: Array,
    },
    planList: {
      type: Array,
    },
    itemList: {
      type: Array,
    },
    inventoryTargetType: {
      type: String,
    },
    isSale: {
      type: Boolean,
      default: false,
    },
    bookingConfirmationEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      today: this.$moment(),
      selectedDate: new Date().getTime(),
      plans: this.planList as PlanParam[],
      items: deepCopy(this.itemList),
      selectedPlan: {
        appliedAllItems: true,
        minimumQuota: 0,
        name: '',
        overNight: false,
        paramId: '',
        preparatoryPeriod: 0,
        quota: 0,
        targetItemParamIds: [],
        term: {
          startedAt: 0,
          endedAt: 0,
        },
        times: [],
        weeks: [],
      } as PlanParam,
      // schedules: (this.list as ScheduleParam[]) || ([] as any),
      schedules: this.list as ScheduleParam[],
      schedule: {
        paramId: '',
        appliedAllItems: true,
        itemParamIds: [],
        maximumPurchasableCount: 0,
        minimumQuota: 0,
        quota: 0,
        saleTerm: {
          startedAt: 0,
          endedAt: 0,
        },
        term: {
          startedAt: 0,
          endedAt: 0,
          duration: 0,
        },
        title: '',
        type: 'primary',
      } as ScheduleParam,
      showScheduleUpdateForm: false,
      showScheduleTemplateForm: false,
      planFormModal: false,
    };
  },
  computed: {
    start(): number {
      return this.today.startOf('month').week();
    },
    end(): number {
      return this.today.endOf('month').week();
    },
    weeks(): number {
      if (this.start > this.end) {
        // TODO: 12월인 경우 end가 start보다 작으므로 따로 처리해줘야함 일단 임시로 처리함
        return this.end + 52 - this.start + 1;
      }
      return this.end - this.start + 1;
    },
    startDay(): number {
      return this.today.startOf('month').day();
    },
    year(): number {
      return this.today.year();
    },
    month(): number {
      return this.today.month() + 1;
    },
    days(): number {
      return this.today.daysInMonth();
    },
  },
  methods: {
    dayInfo(week: number, day: number): DayInfo {
      // TODO: 일단은 이렇게 구현했지만 코드 리팩토링 필요함
      let dayOfWeek = (week - 1) * 7 + day - this.startDay; // 날짜를 구함
      const { year, month, date, isCurrentMonth } = this.getFullDate(dayOfWeek);
      const time = this.$moment(`${year}/${month}/${date}`); // - 로 구분하면 safari에서 날짜가 InvalidDate가 되는 이슈가 있어 / 로 수정함
      const nextday = time.clone().add('1', 'day');
      return {
        date: time.format('YYYY-MM-DD').toString(),
        day: day,
        time: time.valueOf(),
        schedules: this.schedules.filter(
          (schedule: ScheduleParam) =>
            schedule.term.startedAt >= time.valueOf() &&
            schedule.term.startedAt <= nextday.valueOf() - 1
        ), // TODO: 일단은 당일 일정만 되는데 이것도 개선필요함
        isCurrentMonth: isCurrentMonth,
      };
    },
    getFullDate(dayLabel: number): FullDate {
      let month = this.month;
      let year = this.year;
      let date = dayLabel;
      let isCurrentMonth = true;
      if (date <= 0) {
        // 저번달
        month--;
        if (month === 0) {
          year--;
          month = 12;
        }
        isCurrentMonth = false;
        const lastMonthDays = this.today
          .clone()
          .subtract(1, 'month')
          .daysInMonth();
        date = lastMonthDays + date;
      } else if (date > this.days) {
        // 다음달
        date = date - this.days;
        month = month + 1;
        if (month === 13) {
          year++;
          month = 1;
        }
        isCurrentMonth = false;
      }
      return {
        month: month,
        year: year,
        date: date,
        isCurrentMonth: isCurrentMonth,
      };
    },
    setLastMonth(): void {
      this.today = this.today.clone().subtract(1, 'month');
    },
    setNextMonth(): void {
      this.today = this.today.clone().add(1, 'month');
    },
    addSchedule(scheduleTemplate: ScheduleTemplate) {
      console.log(scheduleTemplate);
      /**
       * 반복일정이면 plan, schedules생성
       * 반복일정이 아니면 schedule생성
       */

      if (scheduleTemplate.repeatSchedule === RepeatSchedule.ONCE) {
        const newSchedule =
          productScheduleService.generateScheduleByTemplate(scheduleTemplate);
        const isValid = productScheduleService.validateDuplicatedSchedule(
          this.schedules,
          [newSchedule]
        );
        console.log('isValid: ', isValid);
        if (isValid) {
          this.schedules.push(newSchedule);
        } else {
          this.$notify({
            title: '중복된 일정이 있습니다.',
            message: '중복된 일정이 존재하여 등록할 수 없습니다.',
            type: 'warning',
          });
        }
      } else {
        const newPlan =
          productScheduleService.generatePlanByTemplate(scheduleTemplate);
        const newSchedules =
          productScheduleService.generateSchedulesByPlan(newPlan);
        const isValid = productScheduleService.validateDuplicatedSchedule(
          this.schedules,
          newSchedules
        );
        console.log('isValid: ', isValid);
        if (isValid) {
          console.log(newSchedules);
          this.plans.push(newPlan);

          this.schedules.push(...newSchedules);
          this.$emit('change');
        } else {
          this.$notify({
            title: '중복된 일정이 있습니다.',
            message: '중복된 일정이 존재하여 등록할 수 없습니다.',
            type: 'warning',
          });
        }
      }
      this.closeScheduleTemplateFormModal();
    },
    showScheduleTemplateFormModal(date?: string) {
      console.log('date: ', date);
      this.selectedDate = date
        ? new Date(date).getTime()
        : new Date().getTime();
      console.log('selectedDate: ', this.selectedDate);
      this.showScheduleTemplateForm = true;
    },
    /**
     * 신청 마감일이 24시간 이전인 경우 item의 모집인원을 1명~1명으로 제한함
     */
    setItemQuota(itemParamIds: string[]) {
      console.log('setItemQuota!');
      this.$emit('setItemQuota', itemParamIds);
    },
    /**
     * paramId를 기준으로 update, delete를 한다.
     */
    showScheduleUpdateFormModal(schedule: ScheduleParam) {
      // TODO: 함수 이름 수정
      this.schedule = schedule;
      this.showScheduleUpdateForm = true;
    },
    changeSchedule(updatedSchedule: ScheduleParam) {
      const index = this.schedules.findIndex(
        schedule => schedule.paramId === updatedSchedule.paramId
      );
      console.log('updatedSchedule: ', updatedSchedule);

      const isValid = productScheduleService.validateDuplicatedSchedule(
        this.schedules,
        [updatedSchedule]
      );

      if (isValid) {
        this.schedules.splice(index, 1, updatedSchedule);
        this.$emit('change');
      } else {
        this.$notify({
          title: '중복된 일정이 있습니다.',
          message: '중복된 일정이 존재하여 등록할 수 없습니다.',
          type: 'warning',
        });
      }

      this.closeScheduleUpdateFormModal();
    },
    deleteSchedule(paramId: string) {
      this.$modal.show(
        {
          title: '정말로 삭제하시겠습니까?',
          message: '일정을 삭제합니다.',
          showCancelButton: true,
          type: 'danger',
        },
        () => {
          const index = this.schedules.findIndex(
            schedule => schedule.paramId === paramId
          );
          if (index >= 0) {
            this.schedules.splice(index, 1);
            this.$emit('change');
          }
        }
      );
      this.closeScheduleUpdateFormModal();
    },
    showUpdatePlanForm(planParamId: string) {
      console.log(planParamId);
      const index = this.plans.findIndex(plan => plan.paramId === planParamId);
      this.selectedPlan = this.plans[index];
      this.closeScheduleUpdateFormModal();
      this.showPlanFormModal();
    },
    updatePlan(newPlan: PlanParam) {
      // TODO: 일단 작동은 하도록 구현은 되었으나 개선이 필요함!
      console.log('change plan: ', newPlan);
      const index = this.plans.findIndex(
        plan => plan.paramId === newPlan.paramId
      );
      this.plans.splice(index, 1, newPlan);

      const newSchedules =
        productScheduleService.generateSchedulesByPlan(newPlan);
      const schedules = this.schedules.filter(
        schedule => schedule.planParamId !== newPlan.paramId
      );

      // 전체를 덮어씌움
      this.schedules.splice(0, this.schedules.length);

      this.schedules.push(...schedules);
      const isValid = productScheduleService.validateDuplicatedSchedule(
        this.schedules,
        newSchedules
      );
      if (isValid) {
        this.schedules.push(...newSchedules);
      } else {
        this.$notify({
          title: '중복된 일정이 있습니다.',
          message: '중복된 일정이 존재하여 등록할 수 없습니다.',
          type: 'warning',
        });
      }
      this.closePlanFormModal();
    },
    deletePlan(planParamId: string) {
      const newSchedules = this.schedules.filter(
        schedule => schedule.planParamId !== planParamId
      );
      this.schedules.splice(0);
      this.schedules.push(...newSchedules);
      const index = this.plans.findIndex(plan => plan.paramId === planParamId);
      if (index >= 0) {
        this.plans.splice(index, 1);
      }
      this.$emit('change');
      this.closePlanFormModal();
    },
    async pauseSchedule(id: string) {
      try {
        console.log('id: ', id);
        await productScheduleStatusChangeService.pauseSchedule(id);
        this.showSuccessMessage(
          '일정 판매 일시 중지',
          '일정 판매가 일시 중지 되었습니다.'
        );
        this.refetchSchedules();
      } catch (error) {
        console.error(error);
        this.showErrorMessage('일정 판매 일시 중지 실패', error);
      }
    },
    async openSchedule(id: string) {
      try {
        await productScheduleStatusChangeService.openSchedule(id);
        this.showSuccessMessage('일정 재오픈', '일정이 재오픈 되었습니다.');
        this.refetchSchedules();
      } catch (error) {
        console.error(error);
        this.showErrorMessage('', error);
      }
    },
    refetchSchedules() {
      this.$emit('refetch');
    },
    showPlanFormModal() {
      this.planFormModal = true;
    },
    closePlanFormModal() {
      this.planFormModal = false;
    },
    closeScheduleUpdateFormModal() {
      this.showScheduleUpdateForm = false;
    },
    closeScheduleTemplateFormModal() {
      this.showScheduleTemplateForm = false;
    },
  },
  watch: {
    list: {
      immediate: true,
      handler(newList) {
        this.schedules = newList;
      },
    },
    planList: {
      immediate: true,
      handler(newPlans) {
        this.plans = newPlans;
      },
    },
    itemList: {
      immediate: true,
      deep: true,
      handler(newList) {
        this.items = deepCopy(newList);
      },
    },
  },
});
