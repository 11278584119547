import { HourMinute } from '../model/product/hourMinute';
import { ScheduleTimeParam } from '../model/product/param/productSaleInfoParam';

export class HourMinuteService {
  private readonly DAY_MILLISECOND = 60 * 60 * 24 * 1000;
  private readonly HOUR_MILLISECOND = 60 * 60 * 1000;
  private readonly MILLISECOND = 1000;
  // 12:00 => hour: 12, minute: 00
  public convertTextToScheduleTime(textTime: string): HourMinute {
    const [hour, minute] = textTime.split(':');
    if (!hour || !minute) throw new Error('잘못된 시간 형식입니다.');
    return {
      hour: Number(hour),
      minute: Number(minute),
    };
  }

  // hour: 12, minute: 0 => 12:00
  public convertHourMinuteToTextTime(time: HourMinute): string {
    if (time.hour === 0 && time.minute === 0) return '';
    const hour =
      time.hour < 10 ? `0${time.hour.toString()}` : time.hour.toString();
    const minute =
      time.minute < 10 ? `0${time.minute.toString()}` : time.minute.toString();
    return `${hour}:${minute}`;
  }

  // 600000 => hour: 0, minute: 10
  private getHourMinuteByTimeStamp(timestamp: number): HourMinute {
    const day = new Date(timestamp);
    return {
      hour: day.getHours(),
      minute: day.getMinutes(),
    };
  }

  private getHourMinuteTime(hourMinute: HourMinute): number {
    const today = new Date();
    today.setHours(hourMinute.hour, hourMinute.minute);
    return today.getTime();
  }

  public getEndingTextTime(scheduleTime: ScheduleTimeParam): string {
    const { starting, duration } = scheduleTime;
    const startedAt = this.getHourMinuteTime(starting);
    const endedAt = this.getHourMinuteByTimeStamp(startedAt + duration);
    return this.convertHourMinuteToTextTime(endedAt);
  }

  public getDuration(
    endingTextTime: string,
    startingTextTime: string,
    day: number
  ): number {
    const endingTime = this.convertTextToScheduleTime(endingTextTime);
    const startingTime = this.convertTextToScheduleTime(startingTextTime);
    return (
      day * this.DAY_MILLISECOND +
      this.getHourMinuteTime(endingTime) -
      this.getHourMinuteTime(startingTime)
    );
  }
}

export const hourMinuteService = new HourMinuteService();
