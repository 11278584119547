













import Vue from 'vue';
export default Vue.extend({
  name: 'LocationGeoPoint',
  props: {
    latitude: {
      type: Number,
    },
    longitude: {
      type: Number,
    },
  },
});
