import { CommissionType } from '@frientrip/domain';
import { SalesType } from '../model/product/enum';

export const salesTypeOptions = [
  {
    text: '판매가의 일부 수취',
    value: SalesType.AGENCY,
  },
  {
    text: '판매가의 100% 수취',
    value: SalesType.PURCHASE,
  },
];

export const commissionTypeOptions = [
  {
    text: '%수수료',
    value: CommissionType.RATIO,
  },
  {
    text: '고정금액 수수료',
    value: CommissionType.FIXED,
  },
];
