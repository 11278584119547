




























import Vue from 'vue';
import TagList from '../../components/Tag/TagList.vue';

export default Vue.extend({
  name: 'ProductTag',
  components: {
    TagList,
  },
});
