import gql from 'graphql-tag';
import { RESULT_FRAGMENT } from '@/common/fragments/resultFragment';

export const INQUIRY_TEMPLATE_FRAGMENT = gql`
  fragment inquiryTemplateField on InquiryTemplate {
    id
    name
  }
`;

export const CREATE_INQUIRY_TEMPLATE = gql`
  mutation createInquiryTemplate(
    $hostId: String!
    $param: InquiryTemplateParamInput!
  ) {
    createInquiryTemplate(hostId: $hostId, param: $param) {
      ...inquiryTemplateField
    }
  }
  ${INQUIRY_TEMPLATE_FRAGMENT}
`;

export const UPDATE_INQUIRY_TEMPLATE = gql`
  mutation updateInquiryTemplate($id: ID!, $param: InquiryTemplateParamInput!) {
    updateInquiryTemplate(id: $id, param: $param) {
      ...inquiryTemplateField
    }
  }
  ${INQUIRY_TEMPLATE_FRAGMENT}
`;

export const DELETE_INQUIRY_TEMPLATE = gql`
  mutation deleteInquiryTemplate($id: ID!) {
    deleteInquiryTemplate(id: $id) {
      ...resultField
    }
  }
  ${RESULT_FRAGMENT}
`;
