












































































































import Vue from 'vue';
import Instant from '@/components/Labels/Instant.vue';
import { getProductStatusLabel } from '../util/getProductStatusLabel';
import { getInspectionStatusLabel } from '../util/getInspectionStatusLabel';
import { Dropdown, DropdownItem, DropdownMenu } from 'element-ui';
import { getDomainName } from '@/env';
import { ProductState } from '@/domain/host/models/host';

export default Vue.extend({
  name: 'ProductCard',
  components: {
    Instant,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    productStatus(): string {
      return getProductStatusLabel(this.product.status);
    },
    inspectStatus(): string {
      const inspectStatus = [
        ProductState.INSPECTING,
        ProductState.APPLIED,
        ProductState.REJECTED,
      ];
      if (!inspectStatus.includes(this.product.status)) {
        return '';
      }
      return getInspectionStatusLabel(
        this.product?.lastInspection?.status || ''
      );
    },
    headerContent(): string {
      return this.product.headerContents[0]?.content.thumbnail || '';
    },
    fripUrl(): string {
      return getDomainName();
    },
  },
  methods: {
    copyProduct(productId: string) {
      console.log('productId: ', productId);
      this.$modal.show(
        {
          title: '상품을 복제하시겠습니까?',
          html: '일정있는 상품의 경우 3개월 이내의 일정만 복사가 가능합니다.',
          type: 'primary',
          showCancelButton: true,
          confirmText: '네',
          cancelText: '아니오',
        },
        () => {
          this.$router.push({
            path: '/product/create',
            query: { sourceProductId: productId },
          });
        }
      );
    },
    convertProductStatusLabelToColor(label: string): string {
      const labelToColor: { [key: string]: string | undefined } = {
        판매중: 'blue',
        등록중: 'green',
        검수중: 'green',
        검수신청: 'green',
        검수반려: 'orange',
        판매대기: 'blue',
        품절: 'black',
        일시중지: 'orange',
        판매종료: 'gray',
        판매중지: 'orange',
        운영종료: 'gray',
        삭제: 'red',
      };
      return labelToColor[label] || 'primary';
    },
    convertInspectionStatusLabelToColor(label: string): string {
      const labelToColor: { [key: string]: string | undefined } = {
        검수신청: 'green',
        검수승인: 'blue',
        검수취소: 'orange',
        검수중: 'green',
        검수중지: 'orange',
        검수반려: 'orange',
      };
      return labelToColor[label] || 'primary';
    },
    onClickEditingButton() {
      console.log(this.productStatus);
      if (this.product.status === ProductState.CLOSED) {
        this.$modal.show(
          {
            html: `해당 상품은 판매 종료된 상품으로 내용 수정이 불가합니다. <br/> 다시 판매를 원하시면 호스트지원센터(카카오톡 '@프립호스트')으로 문의 부탁드립니다.`,
            confirmButtonType: 'primary',
          },
          () => {
            this.$router.push(`./list/${this.product.id}`);
          }
        );
      } else {
        this.$router.push(`./list/${this.product.id}`);
      }
    },
  },
});
