import gql from 'graphql-tag';

export const CLOSE_ITEM = gql`
  mutation closeItem($id: ID!) {
    closeItem(id: $id) {
      id
      name
      status
    }
  }
`;

export const OPEN_ITEM = gql`
  mutation openItem($id: ID!) {
    openItem(id: $id) {
      id
      name
      status
    }
  }
`;

export const TERMINATE_ITEM = gql`
  mutation terminateItem($id: ID!) {
    terminateItem(id: $id) {
      id
      name
      status
    }
  }
`;
