export const onScheduleNotice = `[신청 시 유의사항]
· 최소 인원 미달로 인해 진행이 취소될 경우, 신청 마감 일시에 진행 취소 안내를 드리며 참가비는 전액 환불해 드립니다.
`;

export const notice = `[신청 시 유의사항]
· 구매시 호스트 연락처를 카톡 혹은 문자로 보내드립니다.
· 호스트 연락처로 진행 가능한 날짜 예약 바랍니다.
· 예약 확정 시 환불이 불가합니다.
· 예약 시간에 맞추어 늦지 않게 도착해주시기 바랍니다.
`;

export const deliveryNotice = `[신청 시 유의사항]
· 상품이 배송되면 이후, 신청 취소 및 환불이 불가합니다.
· 반품 시 먼저 호스트와 연락하셔서 반품 사유, 택배사, 배송비, 반품지 주소 등을 협의하신 후 반품 상품을 발송해 주시기 바랍니다.
· 구매자 단순 변심에 의한 환불은 불가합니다.
· 표시/광고와 상이, 상품하자의 경우 상품 수령 후 7일 이내인 경우 교환/반품이 가능합니다.
· 배송 지연 등의 문의는 프립 상품 페이지 하단 [문의하기] 게시판을 이용해주시기 바랍니다.
· DIY 키트는 설명서 및 동영상이 첨부되어 발송 후 환불이 불가능한 점 참고 부탁 드립니다.
※ 교환이 필요할 경우, 먼저 호스트와 연락하셔서 반품을 진행해주시기 바랍니다.

[배송]
· 상황에 따라 1~3일 내 배송됩니다.
· 구매 후, 3일 이내에 발송해드립니다.
· 주문하신 상품 결제 확인 후, 배송해드립니다. 다만, 상품종류에 따라서 상품의 배송이 다소 지연 될 수 있습니다.`;
