



































import Vue from 'vue';
export default Vue.extend({
  name: 'CardCheckboxInput',
  inheritAttrs: false,
  props: {
    value: {
      type: Array,
    },
    options: {
      type: Array,
    },
    name: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedOption: this.value,
      checked: false,
    };
  },
  computed: {
    checkedClass() {
      return (value: string): string =>
        this.selectedOption.includes(value) ? 'checked' : '';
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.selectedOption = newValue;
      },
    },
  },
  methods: {
    onChange(selectedOption: string) {
      this.$emit('input', selectedOption);
    },
  },
});
