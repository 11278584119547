

































































































import Vue from 'vue';
import StackTable from '@/components/Table/StackTable.vue';
import { Select, Option } from 'element-ui';
import { isHostAdmin } from '@/env';
import { PRODUCT_ATTRIBUTES } from '../../../queries/product/query';
import { REFUND_POLICIES_BY_FILTER } from '../../../queries/order/query';
import { getProductKindLabel } from '../../../util/getProductKindLabel';
import { RefundPolicy } from '../../../model/product/response/refundPolicy';
import { ProductKind } from '@/domain/product/model/product/enum';
import { RefundPolicyFilter } from '@/domain/product/model/product/param/productTransactionParam';
import { ApolloQueryResult } from 'apollo-client';
import { FripLocationParam } from '@/domain/product/model/product/param/productBasicInfoParam';
import { ProductAttribute } from '../../../model/product/response/product';
import { getNoticeByProductKindAndSchedule } from '../../../util/getNoticeByProductKindAndSchedule';

interface ProductInfo {
  type: string;
  attribute: string;
  attachedToSchedule: string;
}

interface RefundPolicyOption {
  value: string;
  text: string;
  description: string;
}

export default Vue.extend({
  name: 'ProductTransactionInfoForm',
  components: {
    StackTable,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    product: {
      type: Object,
    },
    isUpdateForm: {
      type: Boolean,
    },
    refundPolicyId: {
      type: String,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    extraNotice: {
      type: String,
    },
    description: {
      type: String,
    },
  },
  data() {
    return {
      form: this.product,
      refundPolicy: this.refundPolicyId,
      policyDescription: this.description,
      refundPolicies: [] as RefundPolicyOption[],
      isHostAdmin: isHostAdmin(),
      nonCancelOption: false,
      columns: [
        { text: '기본 정보/프립 유형', value: 'type' },
        { text: '기본 정보/프립 속성', value: 'attribute' },
        { text: '판매 정보/판매 유형', value: 'attachedToSchedule' },
      ],
      attributes: [] as ProductAttribute[],
    };
  },
  computed: {
    isOverseas(): boolean {
      let overseas = this.form.frip.locationsOfVenue.some(
        (location: FripLocationParam) => location.overseas
      );
      if (
        this.form.frip.locationOfGathering &&
        this.form.frip.locationOfGathering.overseas
      ) {
        overseas = true;
      }
      return overseas;
    },
    onSchedule(): boolean {
      return this.isHostAdmin && this.form.frip.attachedToSchedule;
    },
    notice(): string {
      return getNoticeByProductKindAndSchedule(this.form);
    },
    productAttributes(): string {
      return this.attributes
        .filter((attribute: ProductAttribute) =>
          this.form.attributeIds.includes(attribute.id)
        )
        .map((attribute: ProductAttribute) => attribute.name)
        .join(',');
    },
    saleInfo(): ProductInfo {
      return {
        type: getProductKindLabel(this.form.kind),
        attribute: this.productAttributes,
        attachedToSchedule: this.form.frip.attachedToSchedule
          ? '날짜 지정형'
          : '날짜 조율형',
      };
    },
    filter(): RefundPolicyFilter {
      return {
        attachedSchedule: this.form.frip.attachedToSchedule,
        cancelingRestricted: this.form.cancelingRestricted,
        overseas: this.isOverseas,
        productKind: this.form.kind,
      };
    },
  },
  watch: {
    'form.frip.attachedToSchedule': {
      handler() {
        this.form.notice = this.notice;
      },
    },
    'form.kind': {
      handler() {
        this.form.notice = this.notice;
      },
    },
  },
  created() {
    if (this.form.notice === '') {
      this.form.notice = this.notice;
    }
  },
  methods: {
    saveRefundPolicy() {
      this.$emit('update:extraNotice', this.policyDescription);
      this.$emit('update:refundPolicyId', this.refundPolicy);
    },
    saveForm() {
      this.$emit('save', this.form);
    },
  },
  apollo: {
    refundPolicies: {
      query: REFUND_POLICIES_BY_FILTER,
      variables(): { filter: RefundPolicyFilter } {
        return {
          filter: this.filter,
        };
      },
      update: data =>
        data.order.refundPolicesByFilter.map((policy: RefundPolicy) => {
          return {
            value: policy.id,
            text: policy.name,
            description: policy.description,
          };
        }),
      result(
        result: ApolloQueryResult<{
          order: { refundPolicesByFilter: RefundPolicy[] };
        }>
      ) {
        const refundPolicies = result.data.order.refundPolicesByFilter;
        const policy = refundPolicies.filter(
          policy => policy.id === this.refundPolicy
        );

        if (policy.length === 0) {
          const representativePolicy = refundPolicies.filter(
            policy => policy.representative
          );
          if (representativePolicy.length > 0) {
            this.refundPolicy = representativePolicy[0].id;
            if (
              !this.policyDescription ||
              !this.form.cancelingRestricted ||
              !this.extraNotice
            ) {
              this.policyDescription = representativePolicy[0].description;
            } else {
              this.policyDescription = this.extraNotice;
            }
            this.$emit('update:refundPolicyId', this.refundPolicy);
          }
        }
      },

      skip(): boolean {
        return this.filter.productKind === ProductKind.NONE;
      },
    },
    attributes: {
      query: PRODUCT_ATTRIBUTES,
      variables(): { kind: ProductKind } {
        return {
          kind: this.form.kind,
        };
      },
      update: data => data.product.attributes,
      skip(): boolean {
        return this.form.kind === ProductKind.NONE;
      },
    },
  },
});
