import gql from 'graphql-tag';

const MERCHANDISER_FRAGMENT = gql`
  fragment merchandiserField on Merchandiser {
    id
    name
    departmentName
    staffNo
    user {
      id
      nickname
    }
  }
`;

export const PRODUCT_MERCHANDISERS = gql`
  query GetMerchandisers(
    $filter: MerchandiserFilterInput
    $page: Int
    $size: Int
  ) {
    product {
      merchandisers(filter: $filter, page: $page, size: $size) {
        edges {
          node {
            ...merchandiserField
          }
        }
        totalCount
      }
    }
  }
  ${MERCHANDISER_FRAGMENT}
`;

export const PRODUCT_MERCHANDISER = gql`
  query GetMerchandiser($id: ID!) {
    product {
      merchandiser(id: $id) {
        ...merchandiserField
      }
    }
  }
  ${MERCHANDISER_FRAGMENT}
`;
