
















































import Vue from 'vue';
import { Select, Option } from 'element-ui';
import { hourMinuteService } from '../../service/HourMinuteService';
import { HourMinute } from '../../model/product/hourMinute';
import { ScheduleTimeParam } from '../../model/product/param/productSaleInfoParam';
import { DAY_MILLISECOND } from '@/common/util/day/getConstants';

export default Vue.extend({
  name: 'PlanTimeInput',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    value: {
      type: Object,
    },
    order: {
      type: Number,
    },
    showAddBtn: {
      type: Boolean,
    },
    showRemoveBtn: {
      type: Boolean,
    },
  },
  data() {
    return {
      startingTime:
        hourMinuteService.convertHourMinuteToTextTime(this.value.starting) ||
        '',
      endingTime: hourMinuteService.getEndingTextTime(this.value) || '',
      day: Math.floor(this.value.duration / DAY_MILLISECOND),
      dayOption: this.value.duration === 0 ? '0' : 'other',
      showDayInput: this.value.duration === 0 ? false : true,
      isOverNightOptions: [
        {
          label: '당일',
          value: '0',
        },
        {
          label: '다음날',
          value: '1',
        },
        {
          label: '2일 뒤',
          value: '2',
        },
        {
          label: '3일 뒤',
          value: '3',
        },
        {
          label: '4일 뒤',
          value: '4',
        },
        {
          label: '5일 뒤',
          value: '5',
        },
        {
          label: '6일 뒤',
          value: '6',
        },
        {
          label: '7일 뒤',
          value: '7',
        },
        {
          label: '14일 뒤',
          value: '14',
        },
        {
          label: '30일 뒤',
          value: '30',
        },
        {
          label: '직접 입력',
          value: 'other',
        },
      ],
    };
  },
  computed: {
    // day: {
    //   get(): number {
    //     if this.dayOption;
    //   },
    //   set(newValue) {

    //   }
    // },
    time(): ScheduleTimeParam {
      return {
        paramId: this.value.paramId,
        starting:
          this.startingTime !== ''
            ? hourMinuteService.convertTextToScheduleTime(this.startingTime)
            : {
                hour: 0,
                minute: 0,
              },
        duration:
          this.endingTime !== '' && this.day >= 0
            ? hourMinuteService.getDuration(
                this.endingTime,
                this.startingTime,
                this.day
              )
            : 0,
      };
    },
    starting(): HourMinute {
      return this.startingTime !== ''
        ? hourMinuteService.convertTextToScheduleTime(this.startingTime)
        : {
            hour: 0,
            minute: 0,
          };
    },
    duration(): number {
      return this.endingTime !== '' && this.startingTime !== ''
        ? hourMinuteService.getDuration(
            this.endingTime,
            this.startingTime,
            this.day
          )
        : 0;
    },
    isValid(): boolean {
      return this.duration >= 0;
    },
  },
  watch: {
    dayOption(newValue: string) {
      if (newValue === 'other') {
        this.showDayInput = true;
      } else {
        this.showDayInput = false;
        this.day = Number(newValue);
      }
    },
  },
  methods: {
    addTime() {
      this.$emit('addTime');
    },
    removeTime() {
      this.$emit('removeTime', this.order);
    },
    onChange() {
      this.$emit('change', this.time);
    },
  },
});
