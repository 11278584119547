























import Vue from 'vue';
import draggable from 'vuedraggable';
import ImageUploadBox from './ImageUploadBox.vue';

interface Image {
  contentId: string;
  url: string;
}
export default Vue.extend({
  name: 'MultipleImageUploadBox',
  components: {
    ImageUploadBox,
    draggable,
  },
  props: {
    max: {
      type: Number,
      default: 5,
    },
    images: {
      type: Array,
    },
  },
  data() {
    return {
      uploadedImages: this.images as Image[],
    };
  },
  created() {
    // for (let i = 0; i < this.max; ++i) {
    //   if (this.images.length - 1 <= i) {
    //     this.uploadedImages.push({
    //       contentId: this.images[i].contentId,
    //       url: this.images[i].url,
    //     });
    //   } else {
    //     this.uploadedImages.push({
    //       contentId: '',
    //       url: '',
    //     });
    //   }
    // }
    for (let i = this.uploadedImages.length; i < this.max; ++i) {
      this.uploadedImages.push({
        contentId: '',
        url: '',
      });
    }
  },
  methods: {
    saveImages() {
      console.log('multiple uploadedImages: ', this.uploadedImages);
      const param = this.uploadedImages.filter(image => image.contentId !== '');
      this.$emit('change', param);
    },
    moveImages() {
      this.$emit('change', this.uploadedImages);
    },
    getRandomKey() {
      return Math.random();
    },
  },
});
