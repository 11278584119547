import { InspectionState } from '@/domain/inspection/model/enum';

export const getInspectionStatusLabel = (state: InspectionState): string => {
  switch (state) {
    case InspectionState.APPLIED:
      return '검수신청';
    case InspectionState.APPROVED:
      return '검수승인';
    case InspectionState.CANCELED:
      return '검수취소';
    case InspectionState.INSPECTING:
      return '검수중';
    case InspectionState.PAUSED:
      return '검수중지';
    case InspectionState.REJECTED:
      return '검수반려';
    default:
      return '';
  }
};
