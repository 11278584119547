import { render, staticRenderFns } from "./CurriculumTimeTermInput.vue?vue&type=template&id=5b6f7803&scoped=true&"
import script from "./CurriculumTimeTermInput.vue?vue&type=script&lang=ts&"
export * from "./CurriculumTimeTermInput.vue?vue&type=script&lang=ts&"
import style0 from "./CurriculumTimeTermInput.vue?vue&type=style&index=0&id=5b6f7803&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b6f7803",
  null
  
)

export default component.exports