import gql from 'graphql-tag';

export const PLAN_FRAGMENT = gql`
  fragment planField on FripPlan {
    id
    appliedAllItems
    name
    minimumQuota
    preparatoryPeriod
    quota
    overNight
    term {
      startedAt
      endedAt
    }
    targetItems {
      id
    }
    times {
      starting {
        hour
        minute
      }
      duration
    }
    weeks
  }
`;
