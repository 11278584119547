














































import Vue from 'vue';
import gql from 'graphql-tag';
import { Select, Option } from 'element-ui';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { FripKind } from '@/domain/product/model/product/enum';

interface Category {
  id: string;
  depth: number;
  status: string;
  label: Label;
  children: Category[];
}

interface Label {
  name: string;
}

interface ChildrenCategory {
  depth: number;
  children: CategoryOption[];
}

interface ChildrenCategoryFilter {
  fripKind: FripKind;
}

interface CategoryOption {
  value: string;
  text: string;
}

interface CategoryContainer {
  depth: number;
  disabled: boolean;
  selected: string | null;
  options: CategoryOption[];
}

const STANDARD_CATEGORY = gql`
  query standard($id: ID!, $filter: ChildrenCategoryFilter) {
    category {
      standard(id: $id) {
        id
        depth
        status
        label {
          id
          name
        }
        children(filter: $filter) {
          id
          depth
          status
          label {
            id
            name
          }
        }
      }
    }
  }
`;

export default Vue.extend({
  name: 'StandardCategorySelect',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    root: {
      type: String,
      default: '0001',
    },
    value: {
      type: String,
    },
    maxDepth: {
      type: Number,
      default: 4,
    },
    fripKind: {
      type: String,
    },
  },
  data() {
    return {
      categories: [] as CategoryContainer[],
      childrenCategoryFilter: this.fripKind
        ? { fripKind: this.fripKind as FripKind }
        : null,
      rootCategoryId: this.root,
      rootCategory: {
        id: '',
        label: {
          id: '',
          name: '',
        },
        children: [],
      },
      selected: { id: this.value || '', name: '', paths: [] as string[] },
    };
  },
  computed: {
    selectedCategoriesPath(): string {
      return this.selected.paths.join(' > ');
    },
  },
  watch: {
    root: {
      handler(newValue) {
        this.rootCategoryId = newValue;
        if (this.value === '') {
          this.deleteSelectedCategory();
        }
      },
    },
    fripKind: {
      handler(newValue) {
        this.childrenCategoryFilter = { fripKind: newValue };
        if (this.value === '') {
          this.deleteSelectedCategory();
        }
      },
    },
  },
  created() {
    for (let i = 0; i < this.maxDepth; ++i) {
      this.categories.push({
        depth: i + 1,
        disabled: i === 0 ? false : true,
        selected: null,
        options: [],
      });
    }
  },
  methods: {
    async selectCategory(categoryId: string) {
      try {
        const { depth, children } = await this.findChildrenCategories(
          categoryId
        );
        const selectedCategoryName = this.categories[depth - 1].options.filter(
          option => option.value === categoryId
        );
        if (selectedCategoryName.length > 0) {
          this.selected.paths.splice(depth - 1);
          this.selected.paths.push(selectedCategoryName[0].text);
        }
        this.resetHighDepthCategory(depth);
        if (depth < this.maxDepth) {
          this.categories[depth].options = children;
        }
        if (children.length > 0) {
          this.categories[depth].disabled = false;
        } else {
          this.selected.id = categoryId;
          this.selected.name = this.categories[depth - 1].options.filter(
            option => option.value === categoryId
          )[0].text;
          this.$emit('input', this.selected.id);
          this.$emit('select', this.selected);
        }
      } catch (error) {
        console.error(error);
      }
    },
    resetHighDepthCategory(currentDepth: number) {
      const nextDepthIndex = currentDepth;
      for (let i = nextDepthIndex; i < this.maxDepth; ++i) {
        this.categories[i].selected = null;
        this.categories[i].options = [];
        this.categories[i].disabled = true;
      }
    },
    async findChildrenCategories(id: string): Promise<ChildrenCategory> {
      // TODO: 에러 처리 필요
      const categories = await this.$apollo.query({
        query: STANDARD_CATEGORY,
        variables: {
          id: id,
          filter: this.childrenCategoryFilter,
        },
      });
      const depth = categories.data.category.standard?.depth;
      const children = categories.data.category.standard?.children;
      if (children) {
        return {
          depth: depth,
          children: children
            .filter((child: Category) => {
              return child.status === 'ACTIVE';
            })
            .map((child: Category) => {
              return {
                value: child.id,
                text: child.label.name,
              };
            }),
        };
      } else {
        return {
          depth: 0,
          children: [],
        };
      }
    },
    deleteSelectedCategory() {
      this.selected.id = '';
      this.selected.name = '';
      this.selected.paths.splice(0);
      this.resetHighDepthCategory(1);
      this.categories[0].selected = '';
      this.$emit('select', this.selected);
      this.$emit('input', this.selected.id);
    },
  },
  apollo: {
    rootCategory: {
      query: STANDARD_CATEGORY,
      variables(): { id: string; filter: ChildrenCategoryFilter | null } {
        return {
          id: this.rootCategoryId,
          filter: this.childrenCategoryFilter,
        };
      },
      update: data => data.category.standard,
      result(
        result: ApolloQueryResult<{
          category: { standard: Category };
        }>
      ) {
        this.categories[0].selected = null;
        this.categories[0].options = result.data.category.standard.children
          ?.filter((child: Category) => {
            return child.status === 'ACTIVE';
          })
          .map((child: Category) => {
            return {
              value: child.id,
              text: child.label.name,
            };
          });
        this.categories[0].disabled = false;
      },
      error(e: ApolloError) {
        this.$notify({
          title: '카테고리 불러오기 실패',
          message: e.message,
          type: 'warning',
        });
      },
      skip(): boolean {
        return !this.root;
      },
    },
  },
});
