















































































import { CommissionType } from '@frientrip/domain';
import Vue from 'vue';
import PriceInput from '@/components/Forms/PriceInput.vue';
import PercentInput from '@/components/Forms/PercentInput.vue';
import {
  salesTypeOptions,
  commissionTypeOptions,
} from '../constants/commisionOptions';
import { SalesType } from '../model/product/enum';
import { deepCopy } from '@/common/util/deepCopy';
import { HostCommission } from '../model/product/response/product';

export default Vue.extend({
  name: 'ProductCommissionInput',
  components: { PriceInput, PercentInput },
  props: {
    commission: {
      type: Object,
    },
  },
  data() {
    return {
      form: {
        salesType: this.commission.salesType,
        commission: {
          bias: this.commission ? Number(this.commission.default.bias) : 0,
          type: this.commission
            ? this.commission.default.type
            : CommissionType.RATIO,
          charge: this.commission
            ? Number(this.commission.default.charge)
            : 19.8,
        },
        absentFee: {
          bias: this.commission ? Number(this.commission.absent.bias) : 0,
          charge: this.commission
            ? Number(this.commission.absent.charge)
            : 19.8,
          type: this.commission
            ? this.commission.absent.type
            : CommissionType.RATIO,
        },
      },
      info: '상품의 기본 수수료를 입력해주세요. 옵션별 수수료는 하단 옵션정보 영역의 상세설정에서 조정 가능합니다.',
      productCommission: {
        salesType: SalesType.AGENCY,
        commission: {
          type: CommissionType.RATIO,
          bias: 0,
          charge: 19.8,
        },
        absentFee: {
          bias: 0,
          charge: 19.8,
          type: CommissionType.RATIO,
        },
      },
      salesTypeOptions: salesTypeOptions,
      commissionTypeOptions: commissionTypeOptions,
    };
  },
  computed: {
    textClass(): string {
      return 'text-sm mx-3 mt-3';
    },
    priceInputStyle(): string {
      return 'width: 10rem;';
    },
    percentInputStyle(): string {
      return 'width: 8rem;';
    },
  },
  watch: {
    'form.commission.type': function (newValue) {
      if (newValue === CommissionType.FIXED) {
        this.form.commission.bias = 0;
        this.form.commission.charge = 0;
      }
    },
    commission: {
      immediate: true,
      deep: true,
      handler(newValue: HostCommission) {
        // console.log('new Value: ', newValue);
        this.form.salesType = newValue.salesType;
        this.form.commission.type = newValue.default.type;
        this.form.commission.bias = Number(newValue.default.bias);
        this.form.commission.charge = Number(newValue.default.charge);
        this.form.absentFee.charge = Number(newValue.absent.charge);
      },
    },
  },
  methods: {
    // commissionType이 변경되었을때 처리. 라디오 변경시 처리.
    changeCommission() {
      const alertOption = {
        title: '상품 옵션의 수수료들을 일괄변경합니다.',
        message: '일괄변경 하시겠습니까?',
        showCancelButton: true,
        type: 'warning',
      };

      if (Number(this.form.commission.charge) < 5.5) {
        console.log(Number(this.form.commission.charge));
        alertOption.title =
          '수수료가 PG사 결제수수료인 5.5% 이하입니다. 해당 수수료가 맞으시면 확인을 선택하세요';
      }

      this.$modal.show(
        alertOption,
        () => {
          this.productCommission = deepCopy(this.form);
          // chage event를 발생.
          // 부모에게 데이터를 보냄.
          this.$emit('change', this.productCommission);
        },
        () => {
          this.form = deepCopy(this.productCommission);
        }
      );
    },
  },
});
