






























































































import FripFooter from '@/components/FripFooter.vue';
import ManagingNoteItem from '../../ManagingNoteItem.vue';
import Spinner from '@/components/Spinner.vue';
import { NotificationMixin } from '@/common/mixin/notificationMixin';
import { ProductManagingNoteService } from '../../../service/ProductManagingNoteService';
import { apolloClient } from '@/apolloClient';
import { MANAGING_NOTES } from '../../../queries/managingNote/query';
import { Edge } from '@frientrip/domain';
import { ManagingNote } from '@/domain/product/model/managingNote/managingNote';
import { isHostAdmin, getDomainName } from '@/env';
const productManagingNoteService = new ProductManagingNoteService(apolloClient);

interface MangingNotesParam {
  page?: number;
  size?: number;
  productId: string;
}

export default NotificationMixin.extend({
  name: 'ProductFooter',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: String,
      required: true,
    },
  },
  mixins: [NotificationMixin],
  components: {
    FripFooter,
    Spinner,
    ManagingNoteItem,
  },
  data() {
    return {
      isAdmin: !isHostAdmin(),
      showModal: false,
      managingNoteLoading: 0,
      managingNoteWriteLoading: false,
      note: '',
      notes: [],
      noteMaxLength: 250,
      baseUrl: getDomainName(),
    };
  },
  computed: {
    previewUrl(): string {
      return `${this.baseUrl}/products/${this.productId}?is_preview_mode=true&content_only=true`;
    },
    noteLength(): string {
      return `${this.note.length}/250`;
    },
  },
  methods: {
    toTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    toBottom() {
      // TODO: 구현 필요함
      window.scrollTo({ top: 5000, behavior: 'smooth' });
    },
    openPreview() {
      const width = 500;
      const height = 800;
      const left = (window.innerWidth - width) / 2 + screenX;
      const top = (window.innerHeight - height) / 2 + screenY;
      if (this.productId === '') {
        this.$modal.show({
          title: '',
          message: '상품 입력 및 임시저장 후 미리보기를 할 수 있습니다.',
        });
      } else {
        window.open(
          this.previewUrl,
          '_blank',
          `left=${left},top=${top},width=${width},height=${height}`
        );
      }
    },
    async writeManagingNote() {
      try {
        this.managingNoteWriteLoading = true;
        await productManagingNoteService.writeProductManagingNote(
          {
            note: this.note,
          },
          this.productId
        );
        await this.$apollo.queries.notes.refetch();
        this.showSuccessMessage(
          '관리자 메모 등록',
          '관리자 메모가 등록되었습니다.'
        );
        this.note = '';
        this.managingNoteWriteLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    showManagingNoteModal() {
      this.showModal = true;
    },
    closeManagingNoteModal() {
      this.showModal = false;
    },
    saveProduct() {
      this.$emit('saveProduct');
    },
  },
  apollo: {
    notes: {
      query: MANAGING_NOTES,
      variables(): MangingNotesParam {
        return {
          page: 1,
          size: 10, // TODO: 일단은 10으로 고정 -> 페이지네이션 적용 필요
          productId: this.productId,
        };
      },
      update: data =>
        data.product.managingNotes.edges.map(
          (edge: Edge<ManagingNote>) => edge.node
        ),
      skip(): boolean {
        return this.productId === '';
      },
      loadingKey: 'managingNoteLoading',
    },
  },
});
