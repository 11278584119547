



















import Vue from 'vue';

declare global {
  interface Window {
    naver: any; // TODO: 타입 지정 필요
  }
}

export default Vue.extend({
  name: 'NaverMap',
  props: {
    geoPoint: {
      type: Object,
      required: true,
      validator: function (value) {
        if (!value.latitude && value.latitude !== 0) return false;
        if (!value.longitude && value.longitude !== 0) return false;
        return true;
      },
    },
    markerContent: {
      type: String,
    },
  },
  data() {
    return {
      displayGeoPoint: {
        latitude: this.geoPoint.latitude,
        longitude: this.geoPoint.longitude,
      },
      zoomLevel: 18,
    };
  },
  mounted() {
    // console.log('mounted');
    this.initialize();
  },
  methods: {
    initialize() {
      const position = new window.naver.maps.LatLng(
        this.geoPoint.latitude,
        this.geoPoint.longitude
      );
      console.log('position', position);

      const map = new window.naver.maps.Map('map-domestic', {
        useStyleMap: true,
        center: new window.naver.maps.LatLng(
          this.geoPoint.latitude,
          this.geoPoint.longitude
        ),
        zoom: this.zoomLevel,
      });
      const marker = new window.naver.maps.Marker({
        position,
        map,
        draggable: true,
      });
      marker.addListener('dragend', (event: any) => {
        console.log(event);
        this.displayGeoPoint.latitude = event.coord.y;
        this.displayGeoPoint.longitude = event.coord.x;
      });
    },
    convertSimpleGeoPoint(geoPoint: number) {
      return Math.floor(geoPoint * 1000000) / 1000000;
    },
    resetGeoPoint() {
      console.log('reset');
      this.displayGeoPoint.latitude = this.geoPoint.latitude;
      this.displayGeoPoint.longitude = this.geoPoint.longitude;
      // this.initialize();
    },
    updateGeoPoint() {
      // confirm 띄워야함
      this.$emit('change', this.displayGeoPoint);
    },
  },
});
