



































import Vue from 'vue';
// import draggable from 'vuedraggable';
import { deepCopy } from '@/common/util/deepCopy';

interface Item {
  id?: string;
  label: string;
  paramId: string;
}

export default Vue.extend({
  name: 'AddInfoTableForm',
  // components: {
  //   draggable,
  // },
  props: {
    items: {
      type: Array,
    },
  },
  data(): { list: Item[] } {
    return {
      list: this.items
        ? deepCopy(this.items)
        : [{ label: '', paramId: `inquiryItem${Math.random()}` }],
    };
  },
  methods: {
    addInfo() {
      this.list.push({ label: '', paramId: `inquiryItem${Math.random()}` });
      this.$emit('update:items', this.list);
    },
    // moveItems() {
    //   this.$emit('update:items', this.list);
    // },
    removeInfo(paramId: string) {
      console.log('remove: ', paramId);
      const index = this.list.findIndex(
        (item: Item) => item.paramId === paramId
      );
      console.log(index);
      if (index >= 0) {
        this.list.splice(index, 1);
      }
      this.$emit('update:items', this.list);
    },
  },
  // watch: {
  //   value: {
  //     immediate: true,
  //     handler(val) {
  //       this.items = val;
  //     },
  //   },
  // },
});
