import gql from 'graphql-tag';

export const FRIP_LOCATION_FRAGMENT = gql`
  fragment fripLocationField on FripLocation {
    id
    label
    kind
    address
    geoPoint {
      latitude
      longitude
    }
    overseas
    location {
      id
      name
    }
  }
`;
