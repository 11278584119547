import { render, staticRenderFns } from "./InlineTagInput.vue?vue&type=template&id=260b6a70&scoped=true&"
import script from "./InlineTagInput.vue?vue&type=script&lang=ts&"
export * from "./InlineTagInput.vue?vue&type=script&lang=ts&"
import style0 from "./InlineTagInput.vue?vue&type=style&index=0&id=260b6a70&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "260b6a70",
  null
  
)

export default component.exports