import gql from 'graphql-tag';

export const PRODUCT_INQUIRY_TEMPLATES = gql`
  query inquiryTemplates(
    $hostId: String!
    $filter: InquiryTemplateFilterInput
    $page: Int
    $size: Int
  ) {
    product {
      inquiryTemplates(
        hostId: $hostId
        filter: $filter
        page: $page
        size: $size
      ) {
        edges {
          node {
            id
            name
            status
          }
        }
        totalCount
      }
    }
  }
`;

export const PRODUCT_INQUIRY_TEMPLATE = gql`
  query inquiryTemplate($id: ID!) {
    product {
      inquiryTemplate(id: $id) {
        id
        name
        status
        inquiries {
          id
          title
          inputKind
          inputPattern
          required
          status
          items {
            id
            label
          }
        }
      }
    }
  }
`;
