var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticClass:"p-3 clearfix"},[_c('div',{staticClass:"text-sm float-left"},[_vm._v(" 등록된 장소 "+_vm._s(_vm.locations.length)+"개 ")]),_c('div',{staticClass:"float-right"},[_vm._t("actions")],2)]),_c('div',{staticClass:"mx-3"},[(_vm.loading > 0)?_c('spinner',{staticClass:"py-5"}):_c('el-table',{ref:"table",staticClass:"table",attrs:{"border":"","header-row-class-name":"thead-light","header-cell-class-name":"center-table","cell-class-name":"text-center","empty-text":_vm.emptyText,"data":_vm.locations}},[(_vm.isAdmin)?_c('el-table-column',{attrs:{"label":"장소ID","prop":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.id))]}}],null,false,3493295186)}):_vm._e(),_c('el-table-column',{attrs:{"min-width":200,"label":"장소 명","prop":"name","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-frip-primary",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.addLocation(row)}}},[_vm._v(" "+_vm._s(row.name)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"수정","prop":"control","class-name":"p-0","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('frip-button',{staticClass:"m-3 p-1",attrs:{"outline":"","size":"sm"},on:{"click":function($event){return _vm.updateLocation(row)}}},[_c('icon',{attrs:{"name":"iconEdit"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"삭제","prop":"remove","class-name":"p-0","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('frip-button',{staticClass:"m-3 p-1",attrs:{"outline":"","size":"sm"},on:{"click":function($event){return _vm.deleteLocation(row.id)}}},[_c('icon',{attrs:{"name":"iconDeleteBin"}})],1)]}}])})],1)],1),_c('frip-modal',{attrs:{"show":_vm.locationUpdateModal,"size":"lg","no-padding":""},on:{"update:show":function($event){_vm.locationUpdateModal=$event}}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h2',{staticClass:"modal-title"},[_vm._v("장소 수정")])]),_c('location-add-form',{attrs:{"location":_vm.location,"hostId":_vm.hostId,"kind":_vm.kind},on:{"change":_vm.closeLocationUpdateModal}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }