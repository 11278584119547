import gql from 'graphql-tag';

export const REFUND_POLICY_FRAGMENT = gql`
  fragment refundPolicyField on RefundPolicy {
    id
    name
    extraNotice
    description
    representative
    status
    type
  }
`;
