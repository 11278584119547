


































































import Vue, { VueConstructor } from 'vue';
import AddItemForm from './AddItemForm.vue';
import {
  ItemInquiryInputKind,
  ItemInquiryInputPattern,
} from '../../model/product/response/inquiryTemplate';
import { InquiryParam } from '../../model/product/param/inquiryTemplateParam';
import { isHostAdmin } from '@/env';

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLElement } }>
).extend({
  name: 'InquiryTemplateForm',
  components: {
    AddItemForm,
  },
  props: {
    // TODO: validation 필요
    inquiry: {
      type: Object,
    },
    index: {
      type: Number,
    },
    submitType: {
      type: String,
      validator: value => ['update', 'create'].indexOf(value) !== -1,
    },
  },
  data() {
    return {
      form: {
        id: this.inquiry ? this.inquiry.id : '',
        paramId: this.inquiry
          ? this.inquiry.paramId
          : `inquiry${Math.random()}`,
        title: this.inquiry ? this.inquiry.title : '',
        inputKind: this.inquiry
          ? this.inquiry.inputKind
          : ItemInquiryInputKind.TEXT,
        inputPattern: this.inquiry
          ? this.inquiry.inputPattern
          : ItemInquiryInputPattern.PER_ITEM_COUNT,
        required: this.inquiry ? this.inquiry.required : false,
        items: this.inquiry
          ? this.inquiry.items
          : [{ label: '', paramId: `inquiryItem${Math.random()}` }],
        activated: this.inquiry ? this.inquiry.activated : true,
      },
      inputKindOptions: [
        { text: '주관식', value: ItemInquiryInputKind.TEXT },
        {
          text: '객관식(단일 선택)',
          value: ItemInquiryInputKind.SINGLE_CHOICE,
        },
        { text: '객관식(다중 선택)', value: ItemInquiryInputKind.MULTI_CHOICE },
      ],
      isAdmin: !isHostAdmin(),
      hide: this.inquiry ? !this.inquiry.activated : false,
    };
  },
  computed: {
    title(): string {
      return `항목 ${this.buttonText}`;
    },
    buttonText(): string {
      if (this.submitType === 'update') {
        return '수정';
      } else {
        return '등록';
      }
    },
  },
  watch: {
    inquiry: {
      deep: true,
      handler(newValue: InquiryParam) {
        this.form.id = newValue.id;
        this.form.paramId = newValue.paramId;
        this.form.title = newValue.title;
        this.form.inputKind = newValue.inputKind;
        this.form.inputPattern = newValue.inputPattern;
        this.form.required = newValue.required;
        this.form.items = this.inquiry.items;
        this.form.activated = this.inquiry.activated;
      },
    },
    'form.inputKind': {
      // immediate: false,
      handler(newValue: ItemInquiryInputKind, oldValue: ItemInquiryInputKind) {
        // if (newValue === ItemInquiryInputKind.TEXT) {
        //   this.form.items.splice(0);
        // }
        // if (
        //   oldValue === ItemInquiryInputKind.TEXT &&
        //   newValue !== ItemInquiryInputKind.TEXT
        // ) {
        //   this.form.items.push({
        //     label: '',
        //     paramId: `inquiryItem${Math.random()}`,
        //   });
        // }
        console.log(newValue);
      },
    },
  },
  methods: {
    saveChanges() {
      console.log('change');
      const inquiry = {
        paramId: this.form.paramId,
        id: this.form.id,
        title: this.form.title,
        inputKind: this.form.inputKind,
        inputPattern: this.form.inputPattern,
        required: this.form.required,
        items: this.form.items,
        activated: !this.hide,
      };
      this.$emit('change', inquiry);
      this.resetInquiryForm();
    },
    closeForm() {
      this.$emit('close');
    },
    resetInquiryForm() {
      this.form.id = '';
      this.form.paramId = `inquiry${Math.random()}`;
      this.form.title = '';
      this.form.inputKind = ItemInquiryInputKind.TEXT;
      this.form.inputPattern = ItemInquiryInputPattern.PER_ITEM_COUNT;
      this.form.activated = true;
      this.form.required = false;
      this.form.items = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // (this.$refs.formValidator as any).reset();
    },
  },
});
