





























































import Vue from 'vue';
export default Vue.extend({
  props: {
    value: {
      type: Number,
    },
    showOption: {
      type: Boolean,
      default: false,
    },
    isStartedAt: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: this.$moment(new Date(this.value)).clone().format('YYYY-MM-DD'),
      regularSale: this.value === 0 ? 1 : 0,
    };
  },
  computed: {
    instant(): number {
      const date = this.$moment(this.date);
      if (this.isStartedAt) {
        return date.clone().valueOf() || new Date().getTime();
      } else {
        return (
          date.clone().endOf('day').subtract('1', 'second').valueOf() ||
          new Date().getTime()
        );
      }
    },
    disabled(): boolean {
      return this.regularSale > 0;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.date = this.$moment(new Date(newValue))
          .clone()
          .format('YYYY-MM-DD');
      },
    },
  },
  created() {
    if (this.value === 0) {
      this.changeDate();
    }
  },
  methods: {
    onInput() {
      this.$emit('input', this.instant);
    },
    changeDate() {
      console.log('change date!');
      if (this.regularSale > 0) {
        const today = new Date();
        today.setFullYear(today.getFullYear() + this.regularSale);
        this.date = this.$moment(today).clone().format('YYYY-MM-DD');
      } else {
        this.date = '';
      }
      this.onInput();
    },
  },
});
