import gql from 'graphql-tag';

export const ADD_CHANNEL_MEMBERS = gql`
  mutation addChannelMembers($channelId: String!, $ids: [String!]!) {
    addChannelMembers(channelId: $channelId, ids: $ids)
  }
`;

export const REMOVE_CHANNEL_MEMBERS = gql`
  mutation removeChannelMembers($channelId: String!, $ids: [String!]!) {
    removeChannelMembers(channelId: $channelId, ids: $ids)
  }
`;
