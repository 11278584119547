














































































import Vue from 'vue';
import MultipleImageUploadBox from '@/components/ImageUpload/MultipleImageUploadBox.vue';
import FroalaEditor from '@/components/FroalaEditor.vue';
import { ProductHeaderContentStyle } from '@/domain/product/model/product/enum';

interface Image {
  contentId: string;
  url: string;
}

export default Vue.extend({
  name: 'ProductDescriptionForm',
  components: {
    MultipleImageUploadBox,
    FroalaEditor,
  },
  props: {
    product: {
      type: Object,
    },
    isUpdateForm: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: this.product,
      style: ProductHeaderContentStyle.SQUARE,
      descriptions: [
        '권장 사이즈: 가로 1000px * 세로 1000px',
        '최소 사이즈: 가로 600px * 세로 600px',
        '용량: 10MB 이하, 파일유형: JPG, PNG, 최소 1장 - 최대 5장 까지 등록가능',
      ],
      contentIds: [] as string[],
      wordsOfGuidance:
        '텍스트를 사용한 대표 이미지는 노출이 불가합니다. 우측 가이드를 반드시 준수하시어 대표 이미지를 등록해주세요.',
    };
  },
  methods: {
    clearContent() {
      this.form.htmlDetailContent = '';
    },
    saveImages(images: Image[]) {
      Vue.set(
        this.form,
        'headerContents',
        images.map(image => {
          return {
            contentId: image.contentId,
            url: image.url,
            style: this.style,
          };
        })
      );
      this.saveForm();
    },
    addContentId(contentId: string) {
      this.form.productContentIds.push(contentId);
      this.contentIds.push(contentId);
    },
    saveForm() {
      this.$emit('save', this.form);
    },
  },
});
