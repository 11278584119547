










import Vue from 'vue';
import Instant from '@/components/Labels/Instant.vue';

export default Vue.extend({
  name: 'ManagingNoteItem',
  components: {
    Instant,
  },
  props: {
    managingNote: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      note: this.managingNote.note,
    };
  },
});
