



























import Vue from 'vue';

interface Option {
  value: string;
  text: string;
  description: string;
}

export default Vue.extend({
  model: {
    event: 'select',
  },
  props: {
    value: {
      type: [Number, String, Boolean],
    },
    options: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invalidMessage: {
      type: String,
      default: '검수 요청을 한 경우, 변경할 수 없습니다.',
    },
    size: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selected: this.value,
    };
  },
  computed: {
    height(): number {
      switch (this.size) {
        case 'sm':
          return 6;
        case 'lg':
          return 8;
        default:
          return 7;
      }
    },
    style(): string {
      return `min-height: ${this.height}rem`;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.selected = newValue;
      },
    },
  },
  methods: {
    onSelect(option: Option) {
      if (this.disabled) {
        this.$modal.show({
          title: '',
          message: this.invalidMessage,
        });
      } else {
        this.selected = option.value;
        this.$emit('select', this.selected);
      }
    },
    selectedOptionStyle(option: Option): string {
      if (this.selected === option.value) {
        return 'selected';
      } else {
        return '';
      }
    },
  },
});
