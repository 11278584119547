




































































import Vue from 'vue';
import { ProductOptionParam } from '../../model/product/param/productSaleInfoParam';

interface OptionInput {
  id?: string;
  title: string;
  names: string;
}

export default Vue.extend({
  name: 'OptionInput',
  props: {
    productOptions: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: this.productOptions as OptionInput[],
    };
  },
  computed: {
    optionParams(): ProductOptionParam[] {
      return this.options.map((option: OptionInput) => {
        return {
          id: option.id,
          title: option.title,
          names: option.names.split(',').filter(name => name !== ''),
        };
      });
    },
  },
  methods: {
    addOption() {
      this.options.push({
        title: '',
        names: '',
      });
    },
    changeOptions() {
      this.$emit('change', this.optionParams);
    },
    removeOption(index: number) {
      this.options.splice(index, 1);
      this.$emit('change', this.optionParams);
    },
    onInput() {
      this.$emit('input', this.options);
    },
  },
});
