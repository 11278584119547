import { Term } from '@/common/Term';
import { Dictionary } from 'vue-router/types/router';
import { FripKind, ProductKind, ProductState } from '../enum';

export interface ProductFilter {
  attributeIds?: string[] | null;
  exposedChannelIds?: string[] | null;
  hostId?: string | null;
  ids?: string[] | null;
  itemStatusIn?: string[] | null; // TODO: ItemState 로 변경 필요
  kind?: ProductKind | null;
  fripKind?: FripKind | null;
  managerId?: string | null;
  searchType?: string | null;
  staffId?: string | null;
  standardCategoryIds?: string[] | null;
  statusIn?: ProductState[] | null;
  termWithType?: ProductTermWithTypeInput | null;
  searchKeyword?: string | null;
  titleLike?: string | null;
}

export interface ProductFilterQuery
  extends Dictionary<string | string[] | undefined> {
  attributeIds?: string[];
  exposedChannelIds?: string[];
  hostId?: string;
  ids?: string[];
  itemStatusIn?: string[];
  kind?: string;
  managerId?: string;
  staffId?: string;
  standardCategoryIds?: string[];
  statusIn?: string[];
  termType?: string;
  startedAt?: string;
  endedAt?: string;
  titleLike?: string;
  fripKind?: string;
}

export interface ProductTermWithTypeInput {
  type: ProductTermSearchType;
  term: Term;
}

export enum ProductTermSearchType {
  PRODUCT_CREATED = 'PRODUCT_CREATED',
  PRODUCT_UPDATED = 'PRODUCT_UPDATED',
  SALE_END = 'SALE_END',
  SALE_START = 'SALE_START',
}
