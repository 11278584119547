import gql from 'graphql-tag';
import { RESULT_FRAGMENT } from '@/common/fragments/resultFragment';

export const LOCATION_FRAGMENT = gql`
  fragment locationField on Location {
    id
    name
    address
    geoPoint {
      latitude
      longitude
    }
    overseas
  }
`;

export const CREATE_LOCATION = gql`
  mutation createLocation($hostId: String!, $param: LocationParamInput!) {
    createLocation(hostId: $hostId, param: $param) {
      ...locationField
    }
  }
  ${LOCATION_FRAGMENT}
`;

export const UPDATE_LOCATION = gql`
  mutation updateLocation($id: ID!, $param: LocationParamInput!) {
    updateLocation(id: $id, param: $param) {
      ...locationField
    }
  }
  ${LOCATION_FRAGMENT}
`;

export const DELETE_LOCATION = gql`
  mutation deleteLocation($id: ID!) {
    deleteLocation(id: $id) {
      ...resultField
    }
  }
  ${RESULT_FRAGMENT}
`;
