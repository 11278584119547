























import Vue from 'vue';
import { ScheduleParam } from '../../model/product/param/productSaleInfoParam';
import ScheduleCard from './SchduleCard.vue';

export default Vue.extend({
  name: 'ScheduleDay',
  components: {
    ScheduleCard,
  },
  props: {
    dayInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      today: this.$moment(),
    };
  },
  computed: {
    textColor(): string {
      if (!this.dayInfo.isCurrentMonth) {
        return 'text-gray';
      } else if (this.dayInfo.day === 1) {
        return 'text-danger';
      } else if (this.dayInfo.day === 7) {
        return 'text-frip-primary';
      } else {
        return '';
      }
    },
    day(): number {
      return Number(this.dayInfo.date.split('-')[2]);
    },
    isToday(): boolean {
      return this.today.format('YYYY-MM-DD').toString() === this.dayInfo.date;
    },
  },
  methods: {
    updateSchedule(schedule: ScheduleParam) {
      this.$emit('updateSchedule', schedule);
    },
    createSchedule() {
      console.log('show schedule!');
      this.$emit('createSchedule', this.dayInfo.date);
    },
  },
});
