import gql from 'graphql-tag';

export const MANAGING_NOTES = gql`
  query managingNotes($productId: ID!, $page: Int, $size: Int) {
    product {
      managingNotes(productId: $productId, page: $page, size: $size) {
        edges {
          node {
            id
            note
            status
            createdAt
            writer {
              id
              nickname
            }
          }
        }
      }
    }
  }
`;
