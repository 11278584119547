








































































































import Vue, { VueConstructor } from 'vue';
import draggable from 'vuedraggable';
import InquiryTemplateForm from './InquiryTemplateForm.vue';
import {
  InquiryTemplateChoosingItem,
  InquiryTemplateItem,
  ItemInquiryInputKind,
  ItemInquiryInputPattern,
} from '../../model/product/response/inquiryTemplate';
import { InquiryTemplateService } from '../../service/ProductInquiryTemplateService';
import { apolloClient } from '@/apolloClient';
import {
  InquiryParam,
  InquiryTemplateParam,
  InquiryTemplateQueryParam,
} from '../../model/product/param/inquiryTemplateParam';
import { PRODUCT_INQUIRY_TEMPLATE } from '../../queries/inquiryTemplate/query';
import { CommonState } from '@frientrip/domain';
import { OptionType } from '@/common/model/Option';

const inquiryTemplateService = new InquiryTemplateService(apolloClient);

interface OptionWithCaptionType extends OptionType {
  caption: string;
}

interface InquiryTemplateDetailData {
  loading: boolean;
  isUpdateItemForm: boolean;
  inputPatternText: string;
  inquiryTemplate: InquiryTemplateParam;
  inquiry: InquiryParam;
  visible: boolean;
  showUpdateForm: boolean;
  inputKindOptions: OptionType[];
  inputPatternOptions: OptionWithCaptionType[];
}

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLElement } }>
).extend({
  name: 'InquiryRegisterForm',
  components: {
    InquiryTemplateForm,
    draggable,
  },
  props: {
    id: {
      type: String,
    },
    hostId: {
      type: String,
    },
  },
  data(): InquiryTemplateDetailData {
    return {
      loading: false,
      isUpdateItemForm: false,
      inputPatternText:
        '선택지에 따라 각각 다른 방식으로 답변을 받을 수 있습니다.',
      inquiryTemplate: {
        name: '',
        activated: true,
        inputPattern: ItemInquiryInputPattern.PER_ITEM_COUNT,
        inquiries: [] as InquiryParam[],
      },
      inquiry: {
        id: '',
        paramId: '',
        title: '',
        inputKind: ItemInquiryInputKind.TEXT,
        inputPattern: ItemInquiryInputPattern.PER_ITEM_COUNT,
        required: false,
        items: [],
        activated: true,
      },
      visible: false,
      showUpdateForm: false,
      inputKindOptions: [
        { text: '문자열 입력', value: ItemInquiryInputKind.TEXT },
        { text: '다중 선택', value: ItemInquiryInputKind.MULTI_CHOICE },
        { text: '단일 선택', value: ItemInquiryInputKind.SINGLE_CHOICE },
      ],
      inputPatternOptions: [
        {
          text: '구매 옵션별',
          value: ItemInquiryInputPattern.PER_ITEM,
          caption:
            '같은 옵션을 여러 개 구매해도, 옵션당 1개의 답변을 받습니다.',
        },
        {
          text: '구매 옵션 수량별',
          value: ItemInquiryInputPattern.PER_ITEM_COUNT,
          caption: '여러 개 구매할 경우, 구매한 개수만큼 답변을 받습니다.',
        },
        {
          text: '구매 상품별',
          value: ItemInquiryInputPattern.PER_PRODUCT,
          caption: '옵션 종류나 수량 관계없이, 결제건당 1개의 답변을 받습니다.',
        },
      ],
    };
  },
  computed: {
    buttonText(): string {
      if (this.id) return '변경사항 저장';
      else return '등록';
    },
    inputPatternDescription(): string {
      switch (this.inquiryTemplate.inputPattern) {
        case ItemInquiryInputPattern.PER_ITEM:
          return '같은 옵션을 여러 개 구매해도, 옵션당 1개의 답변을 받습니다.';
        case ItemInquiryInputPattern.PER_ITEM_COUNT:
          return '여러 개 구매할 경우, 구매한 개수만큼 답변을 받습니다.';
        case ItemInquiryInputPattern.PER_PRODUCT:
          return '옵션 종류나 수량 관계없이, 결제건당 1개의 답변을 받습니다.';
        default:
          return '';
      }
    },
    inquiryItemFormText(): string {
      if (this.isUpdateItemForm) return '수정';
      else return '추가';
    },
    inquiryAddFormToggle(): string {
      if (this.visible) {
        return '▲';
      } else {
        return '▼';
      }
    },
  },
  methods: {
    saveChanges(newInquiry: InquiryParam) {
      console.log(newInquiry);
      if (newInquiry.id !== '') {
        /**
         * id 같은거 찾아서 업데이트 해준다.
         */
        const index = this.inquiryTemplate.inquiries.findIndex(
          inquiry => inquiry.id === newInquiry.id
        );
        if (index >= 0) {
          this.updateInquiry(index, newInquiry);
        }
      } else {
        const index = this.inquiryTemplate.inquiries.findIndex(
          inquiry => inquiry.paramId === newInquiry.paramId
        );
        if (index >= 0) {
          this.updateInquiry(index, newInquiry);
        } else {
          this.inquiryTemplate.inquiries.push(newInquiry);
        }
      }
      this.visible = false;
      this.showUpdateForm = false;
    },
    updateInquiry(index: number, newInquiry: InquiryParam) {
      this.inquiryTemplate.inquiries[index].title = newInquiry.title;
      this.inquiryTemplate.inquiries[index].inputKind = newInquiry.inputKind;
      this.inquiryTemplate.inquiries[index].required = newInquiry.required;
      this.inquiryTemplate.inquiries[index].items = newInquiry.items;
      this.inquiryTemplate.inquiries[index].activated = newInquiry.activated;
    },
    deleteInquiryItem(paramId: string) {
      const index = this.inquiryTemplate.inquiries.findIndex(
        inquiry => inquiry.paramId === paramId
      );
      if (index >= 0) {
        this.inquiryTemplate.inquiries.splice(index, 1);
      }
    },
    async saveInquiryTemplate() {
      if (this.inquiryTemplate.inquiries.length === 0) {
        this.$modal.show({
          message: '질문을 1개 이상 추가해주세요.',
          type: 'warning',
        });
      } else if (this.id) {
        await this.updateInquiryTemplate();
      } else {
        await this.createInquiryTemplate();
      }
    },
    async createInquiryTemplate() {
      this.loading = true;
      const param: InquiryTemplateParam = {
        name: this.inquiryTemplate.name,
        activated: this.inquiryTemplate.activated,
        inquiries: this.inquiryTemplate.inquiries.map(inquiry => {
          return {
            title: inquiry.title,
            inputKind: inquiry.inputKind,
            inputPattern: this.inquiryTemplate.inputPattern,
            required: inquiry.required,
            activated: inquiry.activated,
            items: inquiry.items
              .map(item => {
                return {
                  label: item.label,
                };
              })
              .filter(item => item.label !== ''),
          };
        }),
      };
      try {
        const id = await inquiryTemplateService.createInquiryTemplate(
          this.hostId,
          param
        );
        this.$notify({
          title: '등록 완료',
          message: '템플릿이 등록되었습니다.',
          type: 'success',
        });
        this.$emit('add', id);
        this.$emit('change');
      } catch (error) {
        // TODO: 수정
        this.$notify({
          title: '등록 실패',
          message: '템플릿 등록 실패',
          type: 'warning',
        });
      }
      this.loading = false;
    },
    async updateInquiryTemplate() {
      this.loading = true;
      const param = {
        name: this.inquiryTemplate.name,
        activated: this.inquiryTemplate.activated,
        inquiries: this.inquiryTemplate.inquiries.map(inquiry => {
          return {
            id: inquiry.id !== '' ? inquiry.id : null,
            title: inquiry.title,
            inputKind: inquiry.inputKind,
            inputPattern: this.inquiryTemplate.inputPattern,
            required: inquiry.required,
            activated: inquiry.activated,
            items: inquiry.items.map(item => {
              return {
                label: item.label,
              };
            }),
          };
        }),
      };
      try {
        await inquiryTemplateService.updateInquiryTemplate(
          this.id.toString(),
          param
        );
        this.$notify({
          title: '수정 완료',
          message: '템플릿이 수정되었습니다.',
          type: 'success',
        });
        this.loading = false;
        this.$emit('change');
      } catch (error) {
        // TODO: 수정
        this.$modal.show({
          title: '추가정보 템플릿 수정 실패',
          message: '추가정보 템플릿 등록하는데 실패했습니다.',
        });
        this.loading = false;
      }
    },
    setInquiryFormToUpdate(paramId: string) {
      console.log('set inquiry paramId: ', paramId);
      const index = this.inquiryTemplate.inquiries.findIndex(
        inquiry => inquiry.paramId === paramId
      );
      const inquiry = this.inquiryTemplate.inquiries[index];
      this.inquiry.id = inquiry.id;
      this.inquiry.paramId = inquiry.paramId;
      this.inquiry.title = inquiry.title;
      this.inquiry.inputKind = inquiry.inputKind;
      this.inquiry.inputPattern = inquiry.inputPattern;
      this.inquiry.required = inquiry.required;
      this.inquiry.items = inquiry.items;
      this.inquiry.activated = inquiry.activated;
      // this.inquiryTemplate.inquiries[index].update =
      //   !this.inquiryTemplate.inquiries[index].update;
      this.showUpdateForm = true;
    },
    showInquiryItemForm() {
      this.showUpdateForm = false;
      this.visible = !this.visible;
    },
    closeUpdateForm() {
      this.showUpdateForm = false;
    },
  },
  apollo: {
    inquiryTemplate: {
      query: PRODUCT_INQUIRY_TEMPLATE,
      variables(): InquiryTemplateQueryParam {
        return {
          id: this.id,
        };
      },
      skip(): boolean {
        if (this.id) return false;
        return true;
      },
      // TODO: 에러 안나는지 확인해봐야함
      update: ({ product }) => {
        return {
          id: product.inquiryTemplate.id,

          name: product.inquiryTemplate.name,
          inputPattern:
            product.inquiryTemplate.inquiries.length > 0
              ? product.inquiryTemplate.inquiries[0].inputPattern
              : 'PER_ITEM_COUNT',
          activated: product.inquiryTemplate.status === CommonState.ACTIVE,
          inquiries: product.inquiryTemplate.inquiries.map(
            (inquiry: InquiryTemplateItem) => {
              return {
                id: inquiry.id,
                paramId: inquiry.id,
                title: inquiry.title,
                inputKind: inquiry.inputKind,
                inputPattern: inquiry.inputPattern,
                required: inquiry.required,
                activated: inquiry.status === CommonState.ACTIVE,
                items: inquiry.items.map(
                  (item: InquiryTemplateChoosingItem) => {
                    return {
                      id: item.id,
                      paramId: item.id,
                      label: item.label,
                    };
                  }
                ),
              };
            }
          ),
        };
      },
    },
  },
});
