
















import Vue from 'vue';
export default Vue.extend({
  name: 'GoogleMap',
  props: {
    geoPoint: {
      type: Object,
      required: true,
      validator: function (value) {
        if (!value.latitude && value.latitude !== 0) return false;
        if (!value.longitude && value.longitude !== 0) return false;
        return true;
      },
    },
    markerContent: {
      type: String,
    },
  },
  data() {
    return {
      displayGeoPoint: {
        latitude: this.geoPoint.latitude,
        longitude: this.geoPoint.longitude,
      },
      zoomLevel: 18,
    };
  },
  mounted() {
    console.log(this.geoPoint);
    this.initialize();
  },
  methods: {
    initialize() {
      const mapLocation = new window.google.maps.LatLng(
        this.geoPoint.latitude,
        this.geoPoint.longitude
      );
      const markLocation = new window.google.maps.LatLng(
        this.geoPoint.latitude,
        this.geoPoint.longitude
      );

      const mapOptions = {
        center: mapLocation,
        zoom: this.zoomLevel,
        // mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      };

      const element = document.getElementById('map-oversea');

      const map = new window.google.maps.Map(element, mapOptions);

      const marker = new window.google.maps.Marker({
        position: markLocation,
        map: map,
        draggable: true,
        title: '장소',
      });

      marker.setMap(map);

      const content = this.markerContent;

      const infowindow = new window.google.maps.InfoWindow({
        content: content,
      });

      window.google.maps.event.addListener(marker, 'click', function () {
        infowindow.open(map, marker);
      });

      map.addListener('click', (mapsMouseEvent: MouseEvent) => {
        console.log(mapsMouseEvent);
      });

      marker.addListener('dragend', (event: any) => {
        console.log('좌표 수정!');
        console.log(event.latLng.lat(), event.latLng.lng());
        this.displayGeoPoint.latitude = this.convertGeoPoint(
          event.latLng.lat()
        );
        this.displayGeoPoint.longitude = this.convertGeoPoint(
          event.latLng.lng()
        );
      });
    },
    convertGeoPoint(geoPoint: number) {
      return Math.floor(geoPoint * 1000000) / 1000000;
    },
    resetGeoPoint() {
      console.log('reset');
      this.displayGeoPoint.latitude = this.geoPoint.latitude;
      this.displayGeoPoint.longitude = this.geoPoint.longitude;
      this.initialize();
    },
    updateGeoPoint() {
      // TODO: confirm 띄워야함
      this.$emit('change', this.displayGeoPoint);
    },
  },
});
