import { Curriculum } from './curriculum';
import { CommonState } from '@frientrip/domain';
import { DayOfWeek, FripKind, LevelOfDifficulty, LocationKind } from '../enum';
import { HourMinute } from '../hourMinute';
import { Item } from './item';

export enum InteractionType {
  VIDEO_CHAT = 'VIDEO_CHAT',
  PHONE_CALL = 'PHONE_CALL',
  VIDEO = 'VIDEO',
  CHATTING_GUIDE = 'CHATTING_GUIDE',
}

export enum CompanionType {
  ALONE = 'ALONE',
  WITH_LOVER = 'WITH_LOVER',
  WITH_FRIENDS = 'WITH_FRIENDS',
  WITH_PARENTS = 'WITH_PARENTS',
  WITH_CHILDREN = 'WITH_CHILDREN',
  WITH_DOG = 'WITH_DOG',
}

// 프립 관련 정보
export interface Frip {
  kind: FripKind;
  interactionType: InteractionType;
  companions: CompanionType[];
  attachedToSchedule: boolean;
  bookingConfirmationEnabled: boolean;
  countRestrictionBySchedule: boolean;
  curriculum: Curriculum;
  daysOfExpiration: number;
  difficulty: LevelOfDifficulty;
  exclusions: string[];
  inclusions: string[];
  locationOfGathering?: FripLocation | null;
  locationsOfVenue: FripLocation[];
  plans: FripPlan[]; // 반복 일정 정보(시간대)
  recommendedAge: number;
  stuffsToPrepare: string[];
  isIndoor?: boolean | null;
  isOutdoor?: boolean | null;
}

export interface FripLocation {
  address: string;
  geoPoint: GeoPoint;
  id?: string;
  kind: LocationKind;
  label: string;
  overseas: boolean;
  location: Location;
}

export interface GeoPoint {
  latitude: number;
  longitude: number;
}

export interface Location {
  id?: string;
  address: string;
  conveniences: Convenience[];
  geoPoint: GeoPoint;
  name: string;
  overseas: boolean;
  parking: LocationParkingInfo;
  status: CommonState;
  useByDefault: boolean;
}

export interface LocationParkingInfo {
  available: boolean;
  note: string;
}

export interface Convenience {
  id: string;
  name: string;
}

export interface FripPlan {
  appliedAllItems: boolean;
  id: string;
  minimumQuota: number;
  name: string;
  overNight: boolean;
  preparatoryPeriod: number;
  quota: number;
  status: CommonState;
  term: Term;
  targetItems: Item[];
  times: ScheduleTime[];
  weeks: DayOfWeek[];
}

// 반복 기간
interface Term {
  duration: number;
  startedAt: number;
  endedAt: number;
}

interface ScheduleTime {
  starting: HourMinute;
  duration: number;
}
