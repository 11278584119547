import { Curriculum } from '../product/response/curriculum';
import { CommonState } from '@frientrip/domain';
import { FripKind, LevelOfDifficulty, LocationKind } from '../product/enum';

// 프립 관련 정보
export interface FripInfo {
  kind: FripKind;
  attachedToSchedule: boolean;
  countRestrictionBySchedule: boolean;
  curriculum: Curriculum;
  difficulty: LevelOfDifficulty;
  exclusions: string[];
  inclusions: string[];
  inventoryTargetOptionsLevel: number;
  inventoryTargetType: InventoryTargetType;
  locationOfGathering?: FripLocation | null;
  locationsOfVenue: FripLocation[];
  plans: FripPlan[]; // 반복 일정 정보(시간대)
  recommendedAge: number;
  stuffsToPrepare: string[];
}

export interface FripLocation {
  address: string;
  geoPoint: GeoPoint;
  id: string;
  kind: LocationKind;
  label: string;
  location: Location;
}

export interface GeoPoint {
  latitude: number;
  longitude: number;
}

export interface Location {
  address: string;
  conveniences: Convenience[];
  geoPoint: GeoPoint;
  id?: string;
  name: string;
  overseas: boolean;
  parking: LocationParkingInfo;
  status: CommonState;
  useByDefault: boolean;
}

export interface LocationParkingInfo {
  available: boolean;
  note: string;
}

export interface Convenience {
  id: string;
  name: string;
}

interface FripPlan {
  appliedAllItems: boolean;
  id: string;
  minimumQuota: number;
  name: string;
  overnight: boolean;
  quota: number;
  status: CommonState;
  term: Term;
  times: ScheduleTerm[];
  weeks: DayOfWeek[];
}

// 반복 기간
interface Term {
  duration: number;
  startedAt: number;
  endedAt: number;
}

interface ScheduleTerm {
  duration: number;
  startedAt: number;
}

export enum DayOfWeek {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}

export enum InventoryTargetType {
  BY_ITEM = 'BY_ITEM', // 아이템별 제한이지만 텍스트는 옵션별 모집인원 제한
  BY_OPTION = 'BY_OPTION',
  BY_SCHEDULE = 'BY_SCEDULE', // 일정별 모집인원 제한
}
