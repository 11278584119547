
























import Vue from 'vue';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import { StandardCategory } from '@/common/model/Category';
import { CommonState, Edge } from '@frientrip/domain';
import { DISPLAY_LEAVES } from './queries/query';

interface CategorySearchResult {
  id: string;
  path: string;
}

interface StandardLeavesParam {
  page: number;
  size: number;
  filter: LeafCategoryFilter;
}

interface LeafCategoryFilter {
  likeLabelNameIncludePath?: string;
  rootCategoryId?: string | null;
  statusIn?: CommonState[] | null;
}

export default Vue.extend({
  name: 'DisplayCategorySearch',
  components: {
    VueBootstrapTypeahead,
  },
  props: {
    root: {
      type: String,
    },
    standardCategory: {
      type: Object,
    },
  },
  data() {
    return {
      text: '',
      rootCategoryId: this.root,
      categories: [],
      categoryPath: [],
      selected: { id: '', path: '' },
      page: 1,
      size: 10,
    };
  },
  watch: {
    root: {
      immediate: true,
      handler(newValue) {
        this.rootCategoryId = newValue;
      },
    },
  },
  methods: {
    selectCategory(category: CategorySearchResult) {
      this.selected = category;
      this.text = '';
      this.categories = [];
      this.$emit('select', category);
    },
  },
  apollo: {
    categories: {
      query: DISPLAY_LEAVES,
      variables(): StandardLeavesParam {
        return {
          page: this.page,
          size: this.size,
          filter: {
            likeLabelNameIncludePath: this.text,
            statusIn: [CommonState.ACTIVE],
          },
        };
      },
      debounce: 200,
      update: data =>
        data.category.displayLeaves.edges.map(
          (edge: Edge<StandardCategory>) => {
            let category: StandardCategory | undefined = edge.node;
            let path = [];
            while (category) {
              path.unshift(category.label.name);
              category = category.parent;
            }
            // path.shift();
            return {
              id: edge.node.id,
              path: path.join(' > '),
              paths: path,
            };
          }
        ),
      skip(): boolean {
        return this.text === '';
      },
    },
  },
});
