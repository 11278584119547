import gql from 'graphql-tag';

// refundPoliciesByFilter;
// refundPolicesByFilter;

export const REFUND_POLICIES_BY_FILTER = gql`
  query refundPoliciesByFilter($filter: RefundPolicyFilterInput!) {
    order {
      refundPolicesByFilter(filter: $filter) {
        id
        description
        name
        representative
        status
        type
      }
    }
  }
`;
