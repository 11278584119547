import { FixedTerm } from '@/common/model/FixedTerm';
import { Term } from '@/common/Term';
import { DAY_MILLISECOND } from '@/common/util/day/getConstants';
import {
  DayOfWeek,
  RepeatSchedule,
  ScheduleState,
} from '../model/product/enum';
import { HourMinute } from '../model/product/hourMinute';
import {
  PlanParam,
  ScheduleParam,
  ScheduleTemplate,
} from '../model/product/param/productSaleInfoParam';
import { getDayValue } from '../util/convertWeek';
import moment from 'moment';

export class ProductScheduleService {
  public getPlanInitData(): PlanParam {
    return {
      appliedAllItems: true,
      name: '',
      weeks: [],
      overNight: false,
      minimumQuota: 0,
      quota: 0,
      preparatoryPeriod: 0,
      targetItemParamIds: [],
      paramId: `plan${Math.random()}`,
      term: {
        startedAt: 0,
        endedAt: 0,
      },
      times: [],
    };
  }
  public getScheduleInitData(): ScheduleParam {
    return {
      appliedAllItems: true,
      itemParamIds: [],
      maximumPurchasableCount: 0,
      minimumQuota: 0,
      paramId: `schedule${Math.random()}`,
      quota: 0,
      saleTerm: {
        startedAt: 0,
        endedAt: 0,
      },
      term: {
        startedAt: 0,
        duration: 0,
      },
      title: '',
      edited: false,
    };
  }
  public generatePlanByTemplate(scheduleTemplate: ScheduleTemplate): PlanParam {
    const planParam: PlanParam = {
      appliedAllItems: scheduleTemplate.appliedAllItems,
      minimumQuota: scheduleTemplate.minimumQuota,
      quota: scheduleTemplate.quota,
      term: scheduleTemplate.term,
      name: '시간대',
      overNight: false, // TODO: 수정
      weeks: scheduleTemplate.weeks,
      preparatoryPeriod: scheduleTemplate.preparatoryPeriod,
      paramId: `plan${Math.random()}`,
      targetItemParamIds: scheduleTemplate.targetItemParamIds,
      times: scheduleTemplate.times.map(time => {
        return {
          starting: time.starting,
          duration: time.duration,
        };
      }),
    };
    return planParam;
  }
  // TODO: Term 설정해야함
  public generateScheduleByTemplate(
    scheduleTemplate: ScheduleTemplate
  ): ScheduleParam {
    const scheduleStartedAt = this.getDetailInstant(
      // TODO: 수정
      scheduleTemplate.term.startedAt,
      scheduleTemplate.times[0].starting // 개별일정인 경우엔 하나의 운영시간만 등록가능
    );

    const saleEndedAt = scheduleStartedAt - scheduleTemplate.preparatoryPeriod;

    const saleStartedAt = new Date(saleEndedAt);
    saleStartedAt.setMonth(saleStartedAt.getMonth() - 3);

    const schedule: ScheduleParam = {
      appliedAllItems: scheduleTemplate.appliedAllItems,
      itemParamIds: scheduleTemplate.targetItemParamIds,
      maximumPurchasableCount: 0,
      minimumQuota: scheduleTemplate.minimumQuota,
      quota: scheduleTemplate.quota,
      paramId: `schedule${Math.random()}`,
      saleTerm: {
        startedAt: saleStartedAt.getTime(), // TODO: 3개월 전으로 수정
        endedAt: saleEndedAt,
      },
      term: {
        startedAt: scheduleStartedAt,
        duration: scheduleTemplate.times[0].duration,
      },
      title: moment(scheduleStartedAt).format('YYYY년 MM월 DD일 HH:mm'),
      edited: false,
    };
    // console.log(
    //   new Date(
    //     this.getDetailInstant(
    //       scheduleTemplate.term.startedAt,
    //       scheduleTemplate.times[0].starting
    //     )
    //   ).toISOString()
    // );
    return schedule;
  }
  /**
   * 시간대 운영 기간과 운영 요일을 조합해서 일정 시작일을 구함
   * @param term
   * @param weeks
   * @returns scheduledStartedAt
   */
  private getScheduleStartedAt(term: FixedTerm, weeks: DayOfWeek[]): number[] {
    const planStartedAt = term.startedAt;
    const planEndedAt = term.endedAt;

    const availableDays = weeks.map(getDayValue);

    const scheduledStartedAt = [];
    for (
      let start = planStartedAt;
      start <= planEndedAt;
      start += DAY_MILLISECOND
    ) {
      const day = new Date(start);
      if (availableDays.includes(day.getDay())) {
        scheduledStartedAt.push(day.getTime());
      }
    }
    return scheduledStartedAt;
  }
  /**
   * 판매 시작일: 현재
   * 판매 종료일: 일정 시작일 - 준비 시간
   * @param scheduleStartedAt
   * @param preparatoryPeriod
   * @returns saleTerms
   */
  private getSaleTerm(
    scheduleStartedAt: number[],
    preparatoryPeriod: number
  ): Term[] {
    const saleTerms: FixedTerm[] = [];
    scheduleStartedAt.forEach(scheduleStart => {
      saleTerms.push({
        startedAt: new Date().getTime(),
        endedAt: scheduleStart - preparatoryPeriod,
      });
    });
    return saleTerms.filter(term => term.endedAt > term.startedAt);
  }
  /**
   * 날짜 + 시간 + 분
   */
  private getDetailInstant(at: number, time: HourMinute) {
    const day = new Date(at);
    day.setHours(time.hour, time.minute);
    return day.getTime();
  }

  private getSaleStartedAt(scheduleStartedAt: number) {
    const scheduleStartDay = new Date(scheduleStartedAt);
    const month = scheduleStartDay.getMonth();
    scheduleStartDay.setMonth(month - 3);
    return scheduleStartDay.getTime();
  }

  public generateSchedulesByPlan(plan: PlanParam): ScheduleParam[] {
    /**
     * 1. 시간대 운영기간(term) + 시간대 운영 요일 조합으로 시작일을 만든다.
     * 시작일이지 시작시간이 아님!
     */
    const scheduleStartedAts = this.getScheduleStartedAt(plan.term, plan.weeks);
    /**
     * 2. saleTerm은 일정 판매 기간으로 판매 시작: 현재 ~ 판매 종료: 일정 시작 시간 - 준비 시간
     */
    const saleTerms = this.getSaleTerm(
      scheduleStartedAts,
      plan.preparatoryPeriod
    );

    const scheduleTermInfo = scheduleStartedAts.map((startedAt, index) => {
      return {
        startedAt: startedAt,
        saleTerm: saleTerms[index],
      };
    });
    /**
     * 3. term은 일정 진행 시간으로 일정 시작 일의 일정 시작 시간 ~ 일정 종료 시간
     */
    const scheduleParams: ScheduleParam[] = [];
    plan.times.forEach((time, index) => {
      scheduleTermInfo.forEach(info => {
        const scheduleStartedAt = this.getDetailInstant(
          info.startedAt,
          time.starting
        );
        scheduleParams.push({
          paramId: `schedule${Math.random()}`, // 등록할때 필요해서 임시로 생성 create할때는 없애줘야한다!!
          itemParamIds: plan.targetItemParamIds,
          maximumPurchasableCount: 0,
          minimumQuota: plan.minimumQuota,
          quota: plan.quota,
          appliedAllItems: plan.appliedAllItems,
          planParamId: plan.paramId,
          saleTerm: {
            startedAt: this.getSaleStartedAt(scheduleStartedAt),
            endedAt: scheduleStartedAt - plan.preparatoryPeriod,
          },
          term: {
            // TODO: 수정!!
            startedAt: scheduleStartedAt,
            duration: time.duration,
          },
          title: moment(scheduleStartedAt).format('YYYY년 MM월 DD일 HH:mm'),
          edited: false,
        });
      });
    });
    return scheduleParams;
  }

  /**
   * 최초 등록, 등록중, 판매중인 상태만 중복 생성 불가
   */
  private filterScheduleByStatus(schedule: ScheduleParam): boolean {
    return (
      !schedule.status ||
      [ScheduleState.EDITING, ScheduleState.OPENED].includes(schedule.status)
    );
  }
  public validateDuplicatedSchedule(
    schedules: ScheduleParam[],
    newSchedules: ScheduleParam[]
  ): boolean {
    let isValid = true;
    schedules.filter(this.filterScheduleByStatus).forEach(schedule => {
      newSchedules.forEach(newSchedule => {
        console.log('paramId: ', newSchedule.paramId);
        if (
          schedule.term.startedAt === newSchedule.term.startedAt &&
          schedule.paramId !== newSchedule.paramId
        ) {
          isValid = false;
        }
      });
    });
    return isValid;
  }
}
