import { FripParam, ProductParam } from '../model/product/param/productParam';
import {
  CurriculumParam,
  CurriculumSectionParam,
} from '../model/product/param/productAdditionalInfoParam';
import { ProductHeaderContentParam } from '../model/product/param/productDescriptionParam';
import {
  CurriculumStyle,
  InventoryTargetType,
  ItemState,
  ProductHeaderContentStyle,
  ProductKind,
  ScheduleState,
} from '../model/product/enum';
import {
  ItemParam,
  PlanParam,
  ProductOptionParam,
  ScheduleParam,
} from '../model/product/param/productSaleInfoParam';
import { ConvertService } from '@/common/service/ConvertService';
import { FixedTerm } from '@/common/model/FixedTerm';

/**
 * 생성된 form을 ProductParam으로 바꿈
 */
export class ProductFormConvertService extends ConvertService {
  public convertFormToProductParam(form: ProductParam): ProductParam {
    if (
      form.saleTerm &&
      form.saleTerm.startedAt &&
      form.saleTerm.endedAt &&
      form.saleTerm.startedAt > form.saleTerm.endedAt
    ) {
      throw new Error('판매 종료일은 판매 시작일보다 빠를 수 없습니다.');
    }

    const itemNames = form.items
      .filter(item => {
        return item.status != ItemState.CLOSED;
      })
      .map(item => item.name);

    const isDuplicate = itemNames.some(
      name => itemNames.indexOf(name) !== itemNames.lastIndexOf(name)
    );

    if (isDuplicate) {
      throw new Error('동일한 이름의 옵션이 존재합니다.');
    }

    const param: ProductParam = {
      hostId: form.hostId,
      managerId: form.managerId,
      kind: form.kind,
      attributeIds: form.attributeIds,
      catchphrase: form.catchphrase,
      categories: form.categories,
      title: form.title,
      // frip: form.frip,
      inventoryTargetOptionLevel: 0,
      inventoryTargetType: form.inventoryTargetType,
      standardCategoryId:
        form.standardCategoryId === '' ? null : form.standardCategoryId,
      exposedChannelIds: form.exposedChannelIds,
      saleTerm: form.saleTerm ? this.convertTerm(form.saleTerm) : form.saleTerm,
      items: form.items
        .map(
          item =>
            this.convertItem(
              item,
              form.frip?.attachedToSchedule || false,
              form.frip?.daysOfExpiration || 90,
              form.kind,
              (form.frip?.bookingConfirmationEnabled &&
                form.inventoryTargetType === InventoryTargetType.BY_ITEM) ||
                false,
              form.inquiryTemplateId
            ) // TODO: 배송 상품일경우 사용기간 무조건 90일인지 확인 필요
        )
        .filter(item => item.name !== ''),
      options: form.options,
      headerContents: this.convertHeaderContents(form.headerContents),
      productContentIds: form.productContentIds,
      htmlDetailContent: form.htmlDetailContent,
      maximumPurchasableCount: form.maximumPurchasableCount,
      grossPurchaseLimitation: form.grossPurchaseLimitation,
      unverifiedUserRestricted: form.unverifiedUserRestricted,
      keywords: this.deduplicateKeys(form.keywords),
      underageRestricted: form.underageRestricted,
      notice: form.notice,
      cancelingRestricted: form.cancelingRestricted,
      externalProvisionIds: form.externalProvisionIds,
      tagIds: form.tagIds,
    };
    if (form.inquiryTemplateId !== '') {
      param.inquiryTemplateId = form.inquiryTemplateId;
    }
    if (form.frip) {
      const {
        curriculum,
        schedules,
        plans,
        locationOfGathering,
        locationsOfVenue,
        inclusions,
        exclusions,
        stuffsToPrepare,
        ...fripParam
      } = form.frip;
      const itemParamIds = form.items.map(item => item.paramId);
      const frip: FripParam = {
        curriculum: this.convertCurriculum(curriculum),
        // schedules 상태를 변경해야한다. 삭제로 변경이 필요.
        // schedule.status = ScheduleState.DELETE;
        schedules: form.frip.attachedToSchedule
          ? schedules.map(schedule =>
              this.convertSchedule(
                schedule,
                itemParamIds,
                form.inventoryTargetType
              )
            )
          : schedules
              .map(schedule =>
                schedule.status !== ScheduleState.DELETE
                  ? { ...schedule, status: ScheduleState.DELETE }
                  : schedule
              )
              .map(schedule =>
                schedule.edited !== true
                  ? { ...schedule, edited: true }
                  : schedule
              )
              .map(schedule =>
                this.convertSchedule(
                  schedule,
                  itemParamIds,
                  form.inventoryTargetType
                )
              ),
        // schedules: form.frip.attachedToSchedule
        //   ? schedules.map(schedule =>
        //       this.convertSchedule(
        //         schedule,
        //         itemParamIds,
        //         form.inventoryTargetType
        //       )
        //     )
        //   : [], // 날짜 조율형 프립은 일정이 없음
        plans: form.frip.attachedToSchedule
          ? plans.map(plan => this.convertPlan(plan, itemParamIds))
          : [],
        locationOfGathering:
          form.kind === ProductKind.OFFLINE ? locationOfGathering : null,
        locationsOfVenue:
          form.kind === ProductKind.OFFLINE ? locationsOfVenue : [],
        inclusions: this.deduplicateKeys(inclusions),
        exclusions: this.deduplicateKeys(exclusions),
        stuffsToPrepare: this.deduplicateKeys(stuffsToPrepare),
        ...fripParam,
      };
      // 일정 있는 프립이라면 schedulingTerm을 정해줘야함
      if (form.frip.attachedToSchedule && !form.frip.schedulingTerm) {
        frip.schedulingTerm = this.getSchedulingTerm(form.frip.schedules);
      }
      param.frip = frip;
    }
    param.options = this.convertOptions(form.options);

    return param;
  }

  private getSchedulingTerm(schedules: ScheduleParam[]): FixedTerm {
    // TODO: 수정 필요
    const sortedSchedule = schedules.sort(
      (a: ScheduleParam, b: ScheduleParam) => {
        return a.term.startedAt - b.term.startedAt;
      }
    );
    if (sortedSchedule.length > 0) {
      return {
        startedAt: sortedSchedule[0].term.startedAt,
        endedAt: sortedSchedule[sortedSchedule.length - 1].term.startedAt,
      };
    } else {
      return {
        startedAt: new Date().getTime(),
        endedAt: new Date().getTime(),
      };
    }
  }

  private convertCurriculum(curriculum: CurriculumParam) {
    const { sections, style, ...curriculumParam } = curriculum;
    return {
      style: style,
      sections:
        style === CurriculumStyle.NONE
          ? []
          : this.convertCurriculumSection(sections),
      ...curriculumParam,
    };
  }

  private convertCurriculumSection(sections: CurriculumSectionParam[]) {
    return sections.map(section => {
      return {
        id: section.id,
        title: section.title,
        items: section.items,
        etc: section.etc,
      };
    });
  }

  private convertHeaderContents(headerContents: ProductHeaderContentParam[]) {
    return headerContents
      .filter(content => content.contentId !== '')
      .map(content => {
        return {
          contentId: content.contentId,
          style: ProductHeaderContentStyle.SQUARE,
        };
      });
  }

  /**
   * 옵션명이 없는건 제외해야함
   * 옵션값을 추가했을때 추가한것 반영
   */
  private convertOptions(options: ProductOptionParam[]): ProductOptionParam[] {
    return options
      .filter(option => !(option.title === '' && option.names.length === 0))
      .map((option, index) => {
        return {
          id: option.id,
          title: option.title,
          names: option.names,
        };
      });
  }

  private convertItem(
    item: ItemParam,
    attachedToSchedule: boolean,
    daysOfExpiration: number,
    kind: ProductKind,
    hasMinimumQuotaLimit: boolean,
    inquiryTemplateId?: string
  ): ItemParam {
    /**
     * 옵션의 차수는 같아야 하므로 무조건 placeholder를 넣어서라도 채워준다.
     */
    const itemOptions = item.options.map(option => {
      return {
        id: option.id,
        name: option.name === '' ? '선택없음' : option.name,
        title: option.title,
      };
    });

    const { checked, status, ...param } = item;
    param.options = itemOptions;
    /**
     * 상품별로 관리하는 추가정보 템플릿 아이디를 아이템에 복사함
     */
    if (inquiryTemplateId !== '') {
      param.inquiryTemplateId = inquiryTemplateId;
    }

    if (
      !attachedToSchedule &&
      param.saleTerm &&
      param.saleTerm.startedAt &&
      param.saleTerm.endedAt &&
      param.saleTerm.startedAt > param.saleTerm.endedAt
    ) {
      throw new Error(
        `옵션의 한정판매 종료일은 한정판매 시작일보다 빠를 수 없습니다. [옵션 명: ${param.name}]`
      );
    }
    param.saleTerm =
      param.saleTerm && !attachedToSchedule
        ? this.convertTerm(param.saleTerm)
        : null;

    if (hasMinimumQuotaLimit && param.minimumQuota > 1) {
      throw new Error(
        '개별 예약 확정 프립은 최소모집인원을 1명으로 설정해주세요.'
      );
    }
    /**
     * 만약 정가가 0이면 할인율을 계산할 수 없으므로 판매가를 정가로 정함
     * 정가는 판매가보다 작을 수 없음
     */
    param.price.retail =
      param.price.retail === 0 || param.price.sale > param.price.retail
        ? param.price.sale
        : param.price.retail;

    param.minimumQuota = attachedToSchedule ? param.minimumQuota : 0; // 일정형 프립이 아니면 minimumQuota는 무조건 0
    param.name = itemOptions.map(option => option.name.trim()).join('|');
    param.daysOfExpiration = daysOfExpiration;

    if (kind === ProductKind.GOODS) {
      param.minimumQuota = 0;
    }

    return param;
  }

  private convertPlan(plan: PlanParam, itemParamIds: string[]) {
    const { targetItemParamIds, ...planParam } = plan;
    return {
      targetItemParamIds: this.getAvailableItemIds(
        targetItemParamIds,
        itemParamIds
      ),
      ...planParam,
    };
  }

  /**
   * 일정 등록할때 편의를 위해 추가한 데이터들을 제거한다.
   */
  private convertSchedule(
    schedule: ScheduleParam,
    targetItemParamIds: string[],
    inventoryTargetType: InventoryTargetType
  ) {
    // TODO: saleTerm 유효성 체크 필요함
    const {
      edited,
      paramId,
      status,
      term,
      itemParamIds,
      quota,
      minimumQuota,
      ...scheduleParam
    } = schedule;
    return {
      term: {
        duration: term.duration,
        startedAt: term.startedAt,
      },
      quota: inventoryTargetType == InventoryTargetType.BY_SCHEDULE ? quota : 0,
      minimumQuota:
        inventoryTargetType == InventoryTargetType.BY_SCHEDULE
          ? minimumQuota
          : 0,
      itemParamIds: this.getAvailableItemIds(itemParamIds, targetItemParamIds),
      ...scheduleParam,
    };
  }

  private getAvailableItemIds(targetItemIds: string[], itemParamIds: string[]) {
    return itemParamIds.filter(id => targetItemIds.includes(id));
  }

  private deduplicateKeys(keys: string[]): string[] {
    return keys.filter((key, index) => keys.indexOf(key) === index);
  }
}
