import { ValidationResult } from '@/common/model/ValidationResult';
import { CurrencyCode, QuotaState, SalesType } from '../model/product/enum';
import {
  ItemParam,
  ProductOptionParam,
} from '../model/product/param/productSaleInfoParam';
import { HostCommission } from '../model/product/response/product';

export class ProductItemService {
  public generateItemParam(
    index: number,
    quotaState: QuotaState,
    hostCommission: HostCommission
  ): ItemParam {
    const param: ItemParam = {
      checked: true,
      absentFee: {
        type: hostCommission.absent.type,
        charge: hostCommission.absent.charge,
        bias: Number(hostCommission.absent.bias),
      },
      commission: {
        type: hostCommission.default.type,
        charge: hostCommission.default.charge,
        bias: Number(hostCommission.default.bias),
      },
      daysOfExpiration: 0,
      description: '',
      // inquiryTemplateId: '',
      maximumPurchasableCount: 0,
      minimumQuota: quotaState === QuotaState.RECRUITMENT_BY_ITEM ? 1 : 0,
      name: '',
      options: [],
      paramId: this.generateParamId(),
      price: {
        currencyCode: CurrencyCode.KRW,
        discountRate: 0,
        retail: 0,
        sale: 0,
      },
      quota: this.getDefaultQuotaByState(quotaState),
      representative: index === 0, // 일단 첫번째 옵션을 대표옵션으로 정함
      salesType: hostCommission.salesType
        ? hostCommission.salesType
        : SalesType.AGENCY,
      scheduledBySale: false,
      ticketCount: 1, // 1회권
      digitalContent: {
        uri: '',
      },
    };
    return param;
  }

  // TODO: 리팩토링 필요함
  /**
   * 옵션을 조합하여 아이템을 만든다.
   * 아이템의 개수 = 1차 옵션 개수 * 2차 옵션 개수 * 3차 옵션 개수
   * @param options
   * @param quotaState
   * @param hostCommission
   * @returns itemParams
   */
  public generateItemsByOptions(
    options: ProductOptionParam[],
    quotaState: QuotaState,
    hostCommission: HostCommission
  ): ItemParam[] {
    const columnCount = options.length;
    const rowCount = options.reduce(
      (acc: number, option: ProductOptionParam) => {
        return acc * option.names.length;
      },
      1
    );
    const items = [];
    for (let row = 0; row < rowCount; ++row) {
      // console.log('hostCommission :: ' + hostCommission.salesType);
      items.push(this.generateItemParam(row, quotaState, hostCommission));
    }
    for (let column = 0; column < columnCount; ++column) {
      const names = options[column].names;
      let size = 1;
      for (let i = column + 1; i < columnCount; ++i) {
        size *= options[i].names.length;
      }
      for (let row = 0; row < rowCount; ++row) {
        const index = Math.floor((row / size) % names.length);
        items[row].options.push({
          title: options[column].title,
          name: names[index],
        });
      }
    }
    for (let row = 0; row < rowCount; ++row) {
      items[row].name = this.getItemName(items[row]);
    }
    return items;
  }

  public validateOptions(options: ProductOptionParam[]): ValidationResult {
    let isValid = true;
    const alert = {
      message: '',
    };

    const itemCount = options.reduce(
      (acc: number, option: ProductOptionParam) => {
        return acc * option.names.length;
      },
      1
    );

    if (itemCount > 100) {
      isValid = false;
      alert.message = '옵션은 100개까지만 생성 가능합니다.';
    }
    // 옵션 값 20자 이내로 제한함
    options.forEach(option => {
      if (
        option.names.filter(name => name !== '' && name.length <= 20).length ===
          0 ||
        option.title === ''
      ) {
        isValid = false;
        alert.message =
          '옵션명, 옵션값을 입력해주세요. 옵션값을 20자 이내로 입력해주세요.';
      }
    });
    return {
      isValid: isValid,
      alert: alert,
    };
  }

  private getItemName(item: ItemParam): string {
    const names = item.options.map(option => option.name);
    return names.join('|');
  }

  private generateParamId(): string {
    return `item${Math.random()}`;
  }

  private getDefaultQuotaByState(quotaState: QuotaState): number {
    let quota = 0;
    switch (quotaState) {
      case QuotaState.OFFLINE:
      case QuotaState.ONLINE:
        quota = 0;
        break;
      case QuotaState.RECRUITMENT_BY_ITEM:
        quota = 10;
        break;
      case QuotaState.SHIPPING:
        quota = 100;
        break;
    }
    return quota;
  }
}
