import { StatusLabel } from '@/common/model/statusLabel';
import { ScheduleCancellationReason } from '../model/product/enum';

export const ScheduleCancellationReasonLabels = [
  {
    value: ScheduleCancellationReason.ETC,
    label: '기타 사유',
    variant: 'default',
  },
  {
    value: ScheduleCancellationReason.LACK_OF_QUOTA,
    label: '최소 모집인원 미달',
    variant: 'default',
  },
  {
    value: ScheduleCancellationReason.LACK_OF_SUPPLIES,
    label: '',
    variant: 'default',
  },
  {
    value: ScheduleCancellationReason.PERSONAL,
    label: '개인 사유',
    variant: 'default',
  },
  {
    value: ScheduleCancellationReason.WEATHER,
    label: '기상 악화',
    variant: 'default',
  },
];

export const getScheduleCancellationLabel = (
  reason: ScheduleCancellationReason
): StatusLabel => {
  const label = ScheduleCancellationReasonLabels.find(i => i.value == reason);
  if (label) {
    return {
      text: label.label,
      color: label.variant,
      extraText: '',
    };
  }
  return {
    text: '-',
    color: 'default',
    extraText: '',
  };
};
