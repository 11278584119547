





























































































































































import Vue from 'vue';
import LocationGeoPoint from './LocationGeoPoint.vue';
import TableSelect from '@/components/Forms/TableSelect.vue';
import GoogleMap from '../GoogleMap.vue';
import NaverMap from '../NaverMap.vue';
import { convertSimpleGeoPoint } from '../../util/convertSimpleGeoPoint';
import {
  CREATE_LOCATION,
  UPDATE_LOCATION,
} from '../../queries/location/mutation';
import { CONVENIENCES } from '../../queries/location/query';
import { Convenience, GeoPoint } from '../../model/FripInfo';
import { ValidationResult } from '@/common/model/ValidationResult';
import {
  LocationAppParam,
  LocationParam,
} from '../../model/product/param/locationParam';
import { FripLocationParam } from '../../model/product/param/productBasicInfoParam';

declare global {
  interface Window {
    daum: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Postcode: any; // TODO:일단 any로 해놓음 수정 필요
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    google: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    naver: any;
  }
}

interface LocationData {
  address: string;
  apartment: string;
  bname: string;
  bname1: string;
  bname2: string;
  buildingCode: string;
  buildingName: string;
  roadAddress: string;
  roadname: string;
  sido: string;
  sigungu: string;
  sigunguCode: string;
  zonecode: string;
}

interface geoCoderResult {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geometry: any;
}

export default Vue.extend({
  components: {
    TableSelect,
    LocationGeoPoint,
    GoogleMap,
    NaverMap,
  },
  props: {
    location: {
      type: Object,
    },
    hostId: {
      type: String,
    },
    kind: {
      type: String,
    },
  },
  data() {
    return {
      form: {
        name: this.location ? this.location.name : '',
        overseas: this.location ? this.location.overseas : false,
        address: this.location ? this.location.address : '',
        geoPoint: {
          latitude: this.location ? this.location.geoPoint.latitude : 0,
          longitude: this.location ? this.location.geoPoint.longitude : 0,
        },
        parking: {
          available: this.location ? this.location.parking.available : false,
          note: this.location ? this.location.parking.note : '',
        },
        conveniences: this.location ? this.location.conveniences : [],
        useByDefault: this.location ? this.location.useByDefault : false,
      } as LocationAppParam,
      loading: false,
      mapModal: false,
      isLocationOverseasOptions: [
        {
          value: 'overseas',
          text: '등록 장소가 해외입니다.',
        },
      ],
      isParkingAvailableOptions: [
        {
          value: true,
          text: '가능',
        },
        {
          value: false,
          text: '불가능',
        },
      ],
      conveniences: [
        { text: '와이파이', value: '1' },
        { text: '휴대폰 충전', value: '2' },
        { text: '샤워 시설', value: '3' },
        { text: '반려 동물', value: '4' },
        { text: '포토존', value: '5' },
        { text: '프립 캡틴', value: '6' },
        { text: '노키즈 존', value: '7' },
        { text: '취사 가능', value: '8' },
      ],
    };
  },
  computed: {
    isUpdateForm(): boolean {
      if (this.location) {
        return true;
      }
      return false;
    },
    isGeoPointAvailable(): boolean {
      if (
        this.form.geoPoint.latitude === 0 &&
        this.form.geoPoint.longitude === 0
      ) {
        return false;
      }
      return true;
    },
    saveButtonText(): string {
      if (this.location) {
        return '수정';
      } else {
        return '등록';
      }
    },
  },
  watch: {
    'form.parking.available': function (newValue: boolean) {
      if (!newValue) {
        this.form.parking.note = '';
      }
    },
    'form.address': function (newAddress: string) {
      if (newAddress === '') {
        this.form.geoPoint = {
          latitude: 0,
          longitude: 0,
        };
      }
    },
  },
  methods: {
    showModal() {
      this.mapModal = true;
    },
    showAddressApiPopUp() {
      new window.daum.Postcode({
        oncomplete: async (data: LocationData) => {
          try {
            console.log(data);
            this.form.address = data.roadAddress;
            const result = await this.getGeoPoint(this.form.address);
            this.form.geoPoint.latitude = result.latitude;
            this.form.geoPoint.longitude = result.longitude;
          } catch (error) {
            this.$modal.show({
              title: '해당 주소지의 좌표를 찾을 수 없습니다.',
              message:
                '구글 지도에서 검색되는 도시, 건물명, 주소지를 입력해주세요.',
            });
          }
        },
      }).open();
    },
    async getGeoPointResult(): Promise<void> {
      const address = this.form.address;
      if (address === '') {
        this.$notify({
          title: '주소를 입력해주세요.',
          message: '주소를 입력해야 좌표를 불러올 수 있습니다.',
          type: 'warning',
        });
      } else {
        try {
          const result = await this.getGeoPoint(address);
          this.form.geoPoint.latitude = result.latitude;
          this.form.geoPoint.longitude = result.longitude;
          console.log(result);
        } catch (error) {
          console.error(error);
          this.$modal.show({
            title: '해당 주소지의 좌표를 찾을 수 없습니다.',
            message:
              '구글 지도에서 검색되는 도시, 건물명, 주소지를 입력해주세요.',
            type: 'primary',
          });
        }
      }
    },
    // TODO: 일단 구현은 되도록 해놨는데 좀 더 개선할 수 있다면 개선하는것이 좋을듯
    getGeoPoint(address: string): Promise<GeoPoint> {
      // const GOOGLE_MAP_API_KEY_DEV = 'AIzaSyCzSyopq0__ErDzrnOC5D9Jn39yut1GsFc'; // TODO: 주의. 사용 가능 도메인 제한이 없는 API key. 가급적 사용을 지양해야 합니다.
      const geoCoder = new window.google.maps.Geocoder();

      return new Promise((resolve, reject) => {
        geoCoder.geocode(
          { address },
          (results: geoCoderResult[], status: string) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
              resolve({
                latitude: convertSimpleGeoPoint(
                  results[0].geometry.location.lat()
                ),
                longitude: convertSimpleGeoPoint(
                  results[0].geometry.location.lng()
                ),
              });
            } else {
              reject();
            }
          }
        );
      });
    },
    updateGeoPoint(geoPoint: GeoPoint) {
      this.form.geoPoint.latitude = geoPoint.latitude;
      this.form.geoPoint.longitude = geoPoint.longitude;
      this.mapModal = false;
    },
    reset() {
      this.form.name = '';
      this.form.address = '';
      this.form.geoPoint = {
        latitude: 0,
        longitude: 0,
      };
      this.form.parking = {
        available: false,
        note: '',
      };
      this.form.conveniences = [];
      this.form.overseas = false;
      this.form.useByDefault = false;
    },
    validateLocation(): ValidationResult {
      if (this.form.name === '') {
        return {
          isValid: false,
          alert: {
            title: '장소의 이름을 입력해주세요.',
            message: '장소의 이름을 입력해주세요.',
          },
        };
      } else if (
        this.form.geoPoint.latitude === 0 &&
        this.form.geoPoint.longitude === 0
      ) {
        return {
          isValid: false,
          alert: {
            title: '장소의 좌표 정보를 입력해주세요.',
            message: '좌표 정보가 없습니다.',
          },
        };
      } else {
        return {
          isValid: true,
          alert: {
            title: '',
            message: '',
          },
        };
      }
    },
    async saveLocation() {
      try {
        const { isValid, alert } = this.validateLocation();
        if (isValid) {
          const location = {
            name: this.form.name,
            address: this.form.address,
            geoPoint: this.form.geoPoint,
            parking: this.form.parking,
            convenienceIds: this.form.conveniences,
            overseas: this.form.overseas,
            useByDefault: this.form.useByDefault,
          };

          console.log('location: ', location);
          this.loading = true;
          if (this.isUpdateForm) {
            await this.updateLocation(location);
          } else {
            // const result = await this.$apollo.mutate({
            //   mutation: CREATE_LOCATION,
            //   variables: {
            //     hostId: this.hostId,
            //     param: location,
            //   },
            // });

            // const newLocation = result.data?.createLocation;
            // if (newLocation) {
            //   const locationParam = {
            //     locationId: newLocation.id,
            //     address: newLocation.address,
            //     geoPoint: {
            //       latitude: newLocation.geoPoint.latitude,
            //       longitude: newLocation.geoPoint.longitude,
            //     },
            //     label: newLocation.name,
            //     overseas: newLocation.overseas,
            //   };
            const locationParam = await this.createLocation(location);
            if (locationParam) {
              this.$emit('add', {
                kind: this.kind,
                location: locationParam,
              });
            }
          }
          this.loading = false;
          this.$notify({
            title: '장소 등록 성공',
            message: '장소가 등록되었습니다.',
            type: 'success',
          });
          this.$emit('change');
        } else if (alert) {
          this.$modal.show(alert);
        }
      } catch (error) {
        // TODO: 수정
        console.error(error);
        this.$notify({
          title: '장소 등록 실패',
          message: '장소 등록에 실패하였습니다.',
          type: 'error',
        });
        this.$emit('change');
      }
    },
    async updateLocation(location: LocationParam) {
      await this.$apollo.mutate({
        mutation: UPDATE_LOCATION,
        variables: {
          id: this.location.id,
          param: location,
        },
      });
    },
    async createLocation(
      location: LocationParam
    ): Promise<FripLocationParam | null> {
      const result = await this.$apollo.mutate({
        mutation: CREATE_LOCATION,
        variables: {
          hostId: this.hostId,
          param: location,
        },
      });
      const newLocation: LocationParam = result.data?.createLocation;
      if (newLocation) {
        const locationParam = {
          locationId: newLocation.id,
          address: newLocation.address,
          geoPoint: {
            latitude: newLocation.geoPoint.latitude,
            longitude: newLocation.geoPoint.longitude,
          },
          label: newLocation.name,
          overseas: newLocation.overseas,
        };
        return locationParam;
      } else {
        return null;
      }
    },
    closeModal() {
      this.$emit('close');
    },
  },
  apollo: {
    conveniences: {
      query: CONVENIENCES,
      update: data =>
        data.product.conveniences.map((convenience: Convenience) => {
          return {
            text: convenience.name,
            value: convenience.id,
          };
        }),
    },
  },
});
