import gql from 'graphql-tag';

export const SAVE_TAGS = gql`
  mutation saveTags($kind: TagKind!, $params: [TagParamInput!]!) {
    saveTags(kind: $kind, params: $params) {
      id
      name
      textColor
      backgroundColor
      status
    }
  }
`;
