import { MutationService } from '@/common/service/MutationService';
import {
  CLOSE_ITEM,
  OPEN_ITEM,
  TERMINATE_ITEM,
} from '../queries/item/mutation';

export class ProductItemStatusChangeService extends MutationService {
  public async closeItem(id: string): Promise<void> {
    await this.apollo.mutate({
      mutation: CLOSE_ITEM,
      variables: {
        id: id,
      },
    });
  }
  public async openItem(id: string): Promise<void> {
    await this.apollo.mutate({
      mutation: OPEN_ITEM,
      variables: {
        id: id,
      },
    });
  }

  public async terminateItem(id: string): Promise<void> {
    await this.apollo.mutate({
      mutation: TERMINATE_ITEM,
      variables: {
        id: id,
      },
    });
  }
}
