



































































import Vue from 'vue';
import { extend } from 'vee-validate';

export default Vue.extend({
  name: 'InlineCheckboxInput',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    label: {
      type: String,
    },
    value: {
      type: Array,
    },
    required: {
      type: Boolean,
    },
    question: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    caption: {
      type: String,
    },
    block: {
      type: Boolean,
      default: false,
    },
    maxTags: {
      type: Number,
    },
    maxLength: {
      type: Number,
    },
  },
  data() {
    return {
      tagStr: '',
      tags: this.value,
      invalid: false,
      message: '',
    };
  },
  computed: {
    labelCols(): string {
      if (this.label) {
        return '2';
      } else {
        return '0';
      }
    },
    paddingClass(): string {
      if (this.block) {
        return 'pl-3';
      } else {
        return '';
      }
    },
    buttonTextClass(): string {
      if (this.tagStr === '') {
        return '';
      } else {
        return 'font-weight-bold';
      }
    },
  },
  created() {
    extend('tag', {
      validate: function (value: string) {
        return value === '';
      },
      message: '등록 버튼을 선택해주세요.',
      lazy: true,
    });
  },
  methods: {
    addTag() {
      this.message = '';
      if (this.tagStr !== '') {
        // TODO: 중복시 에러 메시지 추가 필요함
        const newTags = this.tagStr.split(',').filter((tag, index, newTags) => {
          return (
            !this.tags.includes(tag) &&
            tag !== '' &&
            newTags.indexOf(tag) === index
          );
        });

        if (this.maxTags && this.tags.length + newTags.length > this.maxTags) {
          this.invalid = true;
          this.message = '등록 가능한 태그 개수를 초과하였습니다.';
        } else {
          this.tags = [...this.tags, ...newTags];
          this.tagStr = '';
          this.$emit('change', this.tags);
        }
      }
    },
    blur() {
      if (this.tagStr !== '') {
        this.message = '등록 버튼을 선택해주세요.';
      } else {
        this.message = '';
      }
    },
    deleteTag(index: number) {
      this.tags.splice(index, 1);
      if (this.maxTags && this.tags.length <= this.maxTags) {
        this.invalid = false;
      }
      this.$emit('change', this.tags);
    },
  },
});
