import { ConvertService } from '@/common/service/ConvertService';
import { LocationAppParam } from '../model/product/param/locationParam';
import {
  CurriculumParam,
  CurriculumSectionItemParam,
} from '../model/product/param/productAdditionalInfoParam';
import { FripLocationParam } from '../model/product/param/productBasicInfoParam';
import { FripParam, ProductParam } from '../model/product/param/productParam';
import {
  ItemParam,
  PlanParam,
  ProductOptionParam,
  ScheduleParam,
} from '../model/product/param/productSaleInfoParam';

export class ProductCopyService extends ConvertService {
  public copyProduct(param: ProductParam): ProductParam {
    console.log(param.standardCategoryId);
    const { id, title, catchphrase, items, options, status, frip, ...product } =
      param;
    return {
      ...product,
      options: options.map(this.removeOptionId),
      items: items.map(this.removeItemIdAndStatus),
      title: `${title}_복사본`,
      catchphrase: '', // catchphrase 사용안함
      frip: frip ? this.copyFrip(frip) : frip,
    };
  }

  private removeOptionId(option: ProductOptionParam): ProductOptionParam {
    const { id, ...param } = option;
    return param;
  }

  private removeItemIdAndStatus(item: ItemParam): ItemParam {
    const { id, options, paramId, status, ...rest } = item;
    return {
      paramId: `item_copied${id}`, // paramId도 갱신해줘야함
      options: options.map(option => {
        return {
          name: option.name,
          title: option.title,
        };
      }),
      ...rest,
    };
  }
  private copyPlan(plan: PlanParam): PlanParam {
    const { id, paramId, targetItemParamIds, ...rest } = plan;
    return {
      paramId: `plan_copied${id}`,
      targetItemParamIds: targetItemParamIds.map(id => `item_copied${id}`),
      ...rest,
    };
  }
  public copySchedule(schedule: ScheduleParam): ScheduleParam {
    const { id, itemParamIds, planParamId, status, ...rest } = schedule;
    return {
      itemParamIds: itemParamIds.map(id => `item_copied${id}`),
      planParamId: planParamId ? `plan_copied${planParamId}` : null,
      ...rest,
    };
  }
  private removeLocationId(location: FripLocationParam): FripLocationParam {
    const { id, ...param } = location;
    return param;
  }

  private copyFrip(frip: FripParam): FripParam {
    const {
      curriculum,
      locationOfGathering,
      locationsOfVenue,
      plans,
      schedules,
      ...rest
    } = frip;

    return {
      curriculum: this.copyCurriculum(curriculum),
      locationOfGathering: locationOfGathering
        ? this.removeLocationId(locationOfGathering)
        : null,
      locationsOfVenue: locationsOfVenue.map(this.removeLocationId),
      plans: plans.map(this.copyPlan),
      // schedules: schedules.map(this.copySchedule),
      schedules: [],
      ...rest,
    };
  }

  private removeCurriculumSectionItemId(
    item: CurriculumSectionItemParam
  ): CurriculumSectionItemParam {
    const { id, ...rest } = item;
    return rest;
  }

  private copyCurriculum(curriculum: CurriculumParam): CurriculumParam {
    const { sections, ...rest } = curriculum;
    return {
      sections: sections.map(section => {
        return {
          title: section.title,
          items: section.items.map(this.removeCurriculumSectionItemId),
          etc: section.etc,
        };
      }),
      ...rest,
    };
  }
}
