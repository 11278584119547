



















import { ContainerMixin } from '@/common/mixin/containerMixin';
import { apolloClient } from '@/apolloClient';
import { ScheduleService } from '@/domain/booking/service/ScheduleService';

const scheduleService = new ScheduleService(apolloClient);

export default ContainerMixin.extend({
  name: 'ScheduleDeletePopup',
  data(): {
    opened: boolean;
    scheduleIds: string[];
  } {
    return {
      opened: false,
      scheduleIds: [],
    };
  },
  methods: {
    open(scheduleIds: string[]) {
      this.scheduleIds = scheduleIds;
      this.opened = true;
    },
    async clickOk(): Promise<void> {
      try {
        const res = await scheduleService.deleteByIds(this.scheduleIds);
        console.log('res: ', res);
        if (res.error) {
          this.$modal.show({
            title: '일정 삭제 실패!',
            type: 'warning',
            html: '판매 중인 상품의 일정은 삭제하실 수 없습니다. <br />선택 해제 후 다시 시도해주세요',
          });
        } else {
          this.$modal.show(
            {
              title: '일정 삭제 성공!',
              type: 'info',
              message: '선택하신 일정이 삭제 되었습니다.',
            },
            () => {
              this.$emit('refetch');
              this.opened = false;
            }
          );
        }
      } catch (error) {
        console.error(error);

        this.$modal.show({
          title: '일정 삭제 실패!',
          type: 'warning',
          message:
            '판매 중인 상품의 일정은 삭제하실 수 없습니다. 선택 해제 후 다시 시도해주세요',
        });
      }
    },
  },
});
