





































































import Vue from 'vue';
import draggable from 'vuedraggable';
import TagItem from './Tag.vue';
import TagForm from './TagForm.vue';
import Spinner from '@/components/Spinner.vue';
import {
  TagParamInput,
  TagKindParam,
} from '../../model/product/param/productTagParam';
import { PRODUCT_TAGS } from '../../queries/tag/query';
import { Tag, TagKind } from '../../model/product/response/tag';
import { CommonState } from '@frientrip/domain';
import { ProductTagService } from '../../service/ProductTagService';
import { apolloClient } from '@/apolloClient';

const productTagService = new ProductTagService(apolloClient);

export default Vue.extend({
  name: 'TagList',
  components: {
    TagItem,
    TagForm,
    Spinner,
    draggable,
  },
  props: {
    kind: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tags: [] as TagParamInput[],
      loading: 0,
      isDirty: false,
      showModal: false,
      kindParam: this.kind,
      indexToUpdated: 0,
    };
  },
  methods: {
    showUpdateModal(index: number) {
      this.indexToUpdated = index;
      this.showModal = true;
    },
    changeStatus() {
      this.isDirty = true;
    },
    addTagParam(newTag: TagParamInput) {
      const index = this.tags.findIndex(
        tag =>
          tag.name === newTag.name &&
          tag.kind === newTag.kind &&
          tag.backgroundColor === newTag.backgroundColor &&
          tag.textColor === newTag.textColor
      );
      this.isDirty = true;
      if (index >= 0) {
        this.$notify({
          title: '생성 불가',
          message: '이미 존재하는 태그입니다.',
          type: 'warning',
        });
      } else {
        this.tags.push(newTag);
      }
    },
    updateTagList() {
      this.isDirty = true;
    },
    updateTag(updatedTag: TagParamInput) {
      const index = this.tags.findIndex(
        originalTag => originalTag.id === updatedTag.id
      );
      if (index >= 0) {
        this.tags.splice(index, 1, updatedTag);
      }
      this.isDirty = true;
      this.showModal = false;
    },
    deleteTag(deletedTag: TagParamInput) {
      // this.$emit('deleteTag', tag);
      if (!deletedTag.id) {
        const index = this.tags.findIndex(
          tag =>
            tag.name === deletedTag.name &&
            tag.kind === deletedTag.kind &&
            tag.backgroundColor === deletedTag.backgroundColor &&
            tag.textColor === deletedTag.textColor &&
            tag.activated === deletedTag.activated
        );
        this.isDirty = true;
        this.tags.splice(index, 1);
      } else {
        const index = this.tags.findIndex(tag => tag.id === deletedTag.id);
        this.$modal.show(
          {
            title: '정말로 삭제하시겠습니까?',
            type: 'danger',
            showCancelButton: true,
            confirmText: '네',
            cancelText: '아니오',
          },
          () => {
            this.tags.splice(index, 1);
            this.isDirty = true;
          }
        );
      }
    },
    async saveChanges() {
      console.log(this.tags);
      try {
        await productTagService.saveTags(this.kind as TagKind, this.tags);
        this.tags = [];
        this.$apollo.queries.tags.refetch();
      } catch (error) {
        this.$notify({
          title: '저장 실패',
          message: error.message,
          type: 'warning',
        });
      }
    },
  },
  apollo: {
    tags: {
      query: PRODUCT_TAGS,
      variables(): TagKindParam {
        return {
          kind: this.kind as TagKind,
        };
      },
      update: data =>
        data.product.tags.map((tag: Tag) => {
          return {
            id: tag.id,
            name: tag.name,
            kind: TagKind.NORMAL,
            backgroundColor: tag.backgroundColor,
            textColor: tag.textColor,
            activated: tag.status === CommonState.ACTIVE,
          };
        }),
    },
  },
});
